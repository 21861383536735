import { checkboxAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'

/**
 * Permet de surcharger le style de "Checkbox"
 */
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys)

const baseStyle = definePartsStyle({
  control: defineStyle({
    borderRadius: "lg",
    mr: 1,
    border: "1px",
    boxSize: "24px",
    borderColor: "border.input.default",
    bg: "background.input.default",
    transition: "all .2s ease",
    _checked: {
      bg: "background.checked",
      borderColor: "background.checked",
    },
  }),
  icon: defineStyle({
    color: "text.default",
    _checked: {
      color: "white"
    }
  })
})

export const Checkbox = defineMultiStyleConfig({
  baseStyle,
})
