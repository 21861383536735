import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

export const Spinner = defineStyleConfig({
  variants: {
    default: defineStyle({
      color: "primary",

    })
  },
  defaultProps: {
    variant: "default"
  }
})