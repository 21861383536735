import Select, { SelectProps } from "@/components/Select";
import { availableLanguages } from "@/i18n";
import { Text } from "@chakra-ui/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

type LanguageSelectProps<T> = {} & SelectProps<T>;

export default function LanguageSelect<T>(props: LanguageSelectProps<T>) {
	const { control, name, value, isRequired, defaultValue, ...other } = props;
	const { t } = useTranslation();

	const options = useMemo(
		() =>
			availableLanguages.map((lang) => ({
				label: t(lang),
				value: lang,
			})),
		[availableLanguages]
	);

	return (
		<Select
			placeholder={t("Select language")}
			name={name}
			defaultValue={value}
			control={control}
			isRequired={isRequired}
			noOptionsMessage={() => <Text>No language is currently available</Text>}
			options={options}
			{...other}
		/>
	);
}
