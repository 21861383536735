const colors = {
	transparent: "transparent",
	current: "currentColor",
	black: "#000000",
	white: "#FFFFFF",

	whiteAlpha: {
		50: "rgba(255, 255, 255, 0.04)",
		100: "rgba(255, 255, 255, 0.06)",
		200: "rgba(255, 255, 255, 0.08)",
		300: "rgba(255, 255, 255, 0.16)",
		400: "rgba(255, 255, 255, 0.24)",
		500: "rgba(255, 255, 255, 0.36)",
		600: "rgba(255, 255, 255, 0.48)",
		700: "rgba(255, 255, 255, 0.64)",
		800: "rgba(255, 255, 255, 0.80)",
		900: "rgba(255, 255, 255, 0.92)",
	},

	blackAlpha: {
		50: "rgba(0, 0, 0, 0.04)",
		100: "rgba(0, 0, 0, 0.06)",
		200: "rgba(0, 0, 0, 0.08)",
		300: "rgba(0, 0, 0, 0.16)",
		400: "rgba(0, 0, 0, 0.24)",
		500: "rgba(0, 0, 0, 0.36)",
		600: "rgba(0, 0, 0, 0.48)",
		700: "rgba(0, 0, 0, 0.64)",
		800: "rgba(0, 0, 0, 0.80)",
		900: "rgba(0, 0, 0, 0.92)",
	},

	darkGrey: {
		50: "#738296",
		100: "#677689",
		200: "#5C697A",
		300: "#515D6C",
		400: "#46505D",
		500: "#3B444F",
		600: "#303740",
		700: "#252B32",
		800: "#1A1E23",
		900: "#0F1114",
		1000: "#090A0C",
	},

	lightGrey: {
		50: "#F6F7F9",
		100: "#E8EAEE",
		200: "#D9DCE3",
		300: "#CAD0D8",
		400: "#BCC3CD",
		500: "#ADB6C2",
		600: "#9FA9B7",
		700: "#919CAC",
		800: "#828FA1",
		900: "#738296"
	},

	gray: {
		50: "#f2f2f2",
		100: "#d9d9d9",
		200: "#bfbfbf",
		300: "#a6a6a6",
		400: "#8c8c8c",
		500: "#737373",
		600: "#595959",
		700: "#404040",
		800: "#262626",
		900: "#0d0d0d",
	},

	red: {
		50: "#FFEBE9",
		100: "#FFCECB",
		200: "#FFABA8",
		300: "#FF8182",
		400: "#FA4549",
		500: "#CF222E",
		600: "#A40E26",
		700: "#82071E",
		800: "#660018",
		900: "#4C0014",
		1000: "#29000B"
	},

	orange: {
		50: "#FFFAF0",
		100: "#FEEBC8",
		200: "#FBD38D",
		300: "#F6AD55",
		400: "#ED8936",
		500: "#DD6B20",
		600: "#C05621",
		700: "#9C4221",
		800: "#7B341E",
		900: "#652B19",
	},

	yellow: {
		50: "#FCF9EE",
		100: "#EFDFAB",
		200: "#E7CF80",
		300: "#DFBF56",
		400: "#D7AF2C",
		500: "#CF9F02",
		600: "#AC8402",
		700: "#8A6A01",
		800: "#675001",
		900: "#453501",
		1000: "#292000",
	},

	green: {
		50: "#DFFCF0",
		100: "#BAF3DB",
		200: "#7EE2B8",
		300: "#4BCE97",
		400: "#2ABB7F",
		500: "#22A06B",
		600: "#2F855A",
		700: "#216E4E",
		800: "#164B35",
		900: "#133527",
		1000: "#0B1E16"
	},

	teal: {
		50: "#E6FFFA",
		100: "#B2F5EA",
		200: "#81E6D9",
		300: "#4FD1C5",
		400: "#38B2AC",
		500: "#319795",
		600: "#2C7A7B",
		700: "#285E61",
		800: "#234E52",
		900: "#1D4044",
	},

	blue: {
		50: "#EBF2FF",
		100: "#C2D3FF",
		200: "#91B0FF",
		300: "#6D96FF",
		400: "#497CFF",
		500: "#2462FF",
		600: "#1E52D5",
		700: "#1841AA",
		800: "#1841AA",
		900: "#0C2155",
		1000: "#071433"
	},

	cyan: {
		50: "#EDFDFD",
		100: "#C4F1F9",
		200: "#9DECF9",
		300: "#76E4F7",
		400: "#0BC5EA",
		500: "#00B5D8",
		600: "#00A3C4",
		700: "#0987A0",
		800: "#086F83",
		900: "#065666",
	},

	purple: {
		50: "#FAF5FF",
		100: "#E9D8FD",
		200: "#D6BCFA",
		300: "#B794F4",
		400: "#9F7AEA",
		500: "#805AD5",
		600: "#6B46C1",
		700: "#553C9A",
		800: "#44337A",
		900: "#322659",
	},

	pink: {
		50: "#FFF5F7",
		100: "#FED7E2",
		200: "#FBB6CE",
		300: "#F687B3",
		400: "#ED64A6",
		500: "#D53F8C",
		600: "#B83280",
		700: "#97266D",
		800: "#702459",
		900: "#521B41",
	},

	free: {
		50: "#ffe2e6",
		100: "#ffb3b8",
		200: "#fd838a",
		300: "#fa525c",
		400: "#f7212e",
		500: "#de0814",
		600: "#ad030f",
		700: "#7c0009",
		800: "#4d0004",
		900: "#200000",
	},
};

export default colors;
