import UserSession from "@/Classes/UserSession";

export default function Logout() {

    UserSession.clearSession()
    window.location.href = '/login'

    return (
        <>
            { }
        </>
    );
}