import { Sidenav } from "@/components";
import BrandLogo from "@/components/Brand";
import SidenavUserCard from "@/components/SidenavUserCard";
import { Button, Grid, GridItem, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, useToast } from "@chakra-ui/react";
import { useCallback, useMemo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation } from "react-router-dom";
import UserSession from "@/Classes/UserSession";
import { useDisclosure } from "@chakra-ui/react";
import { Modal } from "@chakra-ui/react";
import { askForCard } from "@/api";
import { set } from "date-fns";
import { ba } from "@fullcalendar/core/internal-common";
import { errorMessage } from "@/utils/errorMessage";
import Footer from "@/components/Footer";


export default function MainScreen() {
	//const location = useLocation()
	const toast = useToast()
	const { isOpen, onOpen, onClose } = useDisclosure()
	const { t } = useTranslation();
	const [role, setRole] = useState("")
	const [haveCard, setHaveCard] = useState<boolean>(false)
	const [haveAsked, setHaveAsked] = useState<boolean>(false)
	const [balance, setBalance] = useState<number>(0)
	const [session, setSession] = useState<UserSession | null>(null)
	const [loading, setLoading] = useState(false)


	useEffect(() => {
		setRole(UserSession?.getSession()?.getRole() as string)
		setSession(UserSession?.getSession())
		setHaveCard(UserSession?.getSession()?.getHaveCard() as boolean)
		setHaveAsked(UserSession?.getSession()?.getAskedCard() as boolean)
		setBalance(UserSession?.getSession()?.getCredits() as number)
	}, [])

	const chooseCard = async () => {
		setLoading(true)
		session?.setHaveCard(false)
		const res = await askForCard(session?.getToken() as string)
		if (res.success) {
			session?.setAskedCard(true)
			setLoading(false)
			onClose()
			toast({
				title: "Demande envoyée",
				description: "L'équipe RH prendra contact avec vous quand votre carte d'accès sera prête.",
				status: "success",
				duration: 3000,
				isClosable: true,
			})
			setHaveCard(false)
		} else {
			setLoading(false)
			onClose()
			toast({
				title: "Demande impossible",
				description: errorMessage(res.data.error_code),
				status: "error",
				duration: 3000,
				isClosable: true,
			})
			setHaveCard(false)
		}
	}

	useEffect(() => {
		if (session !== null) {
			if (!haveCard && !haveAsked && balance >= 0 && role === "collaborateur") {
				onOpen()
			}
		}
	}, [haveCard, haveAsked, session, role])

	/**
	 * @note Permet d'avoir un menu totalement dynamique, cela deviendra pratique
	 * quand il y aura la gestion des permissions utilisateur
	 */
	const items = useMemo(
		() => [
			{
				label: t(""),
				links: [
					{
						icon: "home",
						label: t("Tableau de bord"),
						to: "",
						visible: true
					},

					{
						icon: "check",
						label: t("Mes réservations"),
						to: "reservations",
						visible: (role === "collaborateur")
					},
					{
						icon: "list",
						label: t("Collaborateurs"),
						to: "collabs",
						visible: (role !== "collaborateur") && (role !== "coach")

					},
					{
						icon: "users",
						label: t("Coachs"),
						to: "coachs",
						visible: (role !== "collaborateur") && (role !== "coach")

					},
					{
						icon: "creditCard",
						label: t("Assigner les cartes"),
						to: "assign-cards",
						visible: (role !== "collaborateur") && (role !== "coach")

					},
					{
						icon: "creditCard",
						label: t("Mes cartes"),
						to: "cards",
						visible: role === "collaborateur"

					},
					{
						icon: "mail",
						label: t("Contact"),
						to: "contact",
						visible: role === "collaborateur"

					},
					{
						icon: "pieChart",
						label: t("Statistiques"),
						to: "stats",
						visible: role !== "collaborateur"

					},
					{
						icon: "sliders",
						label: t("Gestion"),
						to: "gestion",
						visible: role !== "collaborateur"

					},

				],
			},
		],
		[t, role]
	);

	const bottomContainer = useCallback(
		(isCollapsed: boolean) => <SidenavUserCard collapsed={isCollapsed} />,
		[]
	);

	const brandContainer = useCallback(
		(isCollapsed: boolean) => (isCollapsed ? null : <BrandLogo size={150} />),
		[]
	);

	return (
		<>

			<Grid
				templateAreas={
					`"sidebar content"`
				}
				gridTemplateColumns="auto minmax(320px, 1fr)"
				height="100dvh"
				bg="background.default"
				width={{ base: "100vw" }}
				maxWidth="100vw"
				overflow="hidden"
			>
				<GridItem gridArea="sidebar">
					<Sidenav
						items={items}
						brandContainer={brandContainer}
						bottomContainer={bottomContainer}
					/>
				</GridItem>
				<GridItem gridArea="content" minW="300" overflowY="auto">
					<Outlet />
					<Footer />
				</GridItem>
			</Grid >
			<Modal onClose={onClose} isOpen={isOpen} isCentered closeOnOverlayClick={false}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Demandez vorte carte</ModalHeader>
					<ModalBody>
						Cette carte sera dorénavant votre sésame pour accéder à la salle et valider votre participation aux cours auxquels vous vous serez inscrits.
					</ModalBody>
					<Stack direction="row" spacing={4} align="center" justify="center" marginBlock={"2rem"}>
						<Button isLoading={loading} onClick={() => chooseCard()} variant={'primary'}>Je veux ma carte physique</Button>
					</Stack>
					{/* <ModalFooter>
						<em style={{ fontSize: "0.8rem", color: 'rgb(255, 120, 120)' }}>
							(NB: Des cartes virtuelles à ajouter dans votre wallet seront bientôt disponibles)
						</em>
					</ModalFooter> */}
				</ModalContent>
			</Modal>
		</>
	);
}
