import { DataTable } from "@/components/datatable";
import Search from "../../components/Search";
import {
    Box,
    Stack,
    Text,
    Input,
    Button,
    Badge,
    useDisclosure,
    Grid,
    InputGroup,
    InputLeftElement,
    Menu,
    MenuButton,
    MenuList,
    MenuOptionGroup,
    MenuItemOption,
    MenuDivider,
} from "@chakra-ui/react";
import { AlertCircle, ChevronLeft, ChevronRight, Clock, DollarSign, ExternalLink, Search as SearchIcon, Table, Trash, Users, X } from "react-feather";
import { Filter } from "react-feather";
import { createColumnHelper } from "@tanstack/react-table";
import AddUser from "./AddUser";
import AssociateCard from "./AssociateCard";
import Modify from './Modify'
import Widget from "./Widget";
import Popup from "@/components/Popup"
import { useEffect, useState } from "react";
import UserSession from "@/Classes/UserSession";
import { exportDataRh, removeUser, userList, refillOfTheMonth, futureRenewals, banUser, unbanUser } from "@/api";
import DeleteUser from "./DeleteUser";
import { useToast } from '@chakra-ui/react'
import { set } from "date-fns";
import { errorMessage } from "@/utils/errorMessage";
import BanUser from "./BanUser";
import CancelCard from "@/components/icons/CancelCard";
import DisableCard from "./DisableCard";
import MainContainer from "@/components/MainContainer";


type Person = {
    Uuid: string
    Prénom: string
    Nom: string
    Email: string
    Crédits: number
    Solde: number
    Réservations: number
    Carte: string[]
    Actions: string
    Phone: string
    Irm: string
    Status: string
    Accès: string[]
    Renouvellement: string
}

export default function CollabList() {
    const toast = useToast()
    const [pageIndex, setPageIndex] = useState(0)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [session, setSession] = useState<UserSession | null>(null)
    const currentDate = new Date();
    const previousMonth = currentDate.getMonth() - 1;
    const formattedDate = new Date(currentDate.getFullYear(), previousMonth, currentDate.getDate(), currentDate.getHours() + 2, currentDate.getMinutes()).toISOString().slice(0, 16);

    const [exportDateStart, setExportDateStart] = useState<string>(formattedDate)
    // add 2 hours
    const [exportDateEnd, setExportDateEnd] = useState<string>(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), currentDate.getHours() + 2, currentDate.getMinutes()).toISOString().slice(0, 16))
    const [users, setUsers] = useState<Person[]>([])

    const [refill, setRefill] = useState<number>(0)
    const [renewals, setRenewals] = useState<number>(0)

    const [search, setSearch] = useState<string>("")
    const [cardFilter, setCardFilter] = useState<string>("all")
    const [accessFilter, setAccessFilter] = useState<string>("all")



    useEffect(() => {
        setSession(UserSession.getSession() as UserSession | null)
    }, [])

    const fetchUsers = async () => {
        if (UserSession.getSession() !== null) {
            const session_token = UserSession?.getSession()?.session_token as string
            const response = await userList(session_token)
            setUsers(response?.data?.filter((user: any) => user.role === "collaborateur").map((user: any, index: number) => {
                return {
                    Uuid: user.uuid as string,
                    Prénom: user.firstname as string,
                    Nom: user.lastname as string,
                    Email: user.email as string,
                    Crédits: user.credits as number,
                    Solde: user.solde as number,
                    Réservations: user.reservation_active as number,
                    Carte: (user.hasCardPhysique && user.hasCardNumerique) ? ["Physique", "Numerique"] : (user.hasCardPhysique) ? ["Physique"] : (user.hasCardNumerique) ? ["Numerique"] : [],
                    Modifier: true,
                    Phone: user.phone as string,
                    Irm: user.irm as string,
                    Status: user.status as string,
                    Accès: (user.have_access_salle && user.have_access_cours) ? ["Salle", "Cours"] : (user.have_access_salle) ? ["Salle"] : (user.have_access_cours) ? ["Cours"] : [],
                    Renouvellement: (!user.have_access_salle && user.have_access_cours) ? "Pas d'abonnement" : (user.have_resiliation && user.have_access_salle) ? "Annulé" : "Actif",
                }
            }))
        }
    }

    useEffect(() => {
        if (session !== null) {
            if (session?.getRole() !== "collaborateur") {
                fetchUsers()

                Promise.all([refillOfTheMonth(session.getToken() as string)]).then((values) => {
                    setRefill(values[0]?.data?.length as number)
                    // setRenewals(values[1]?.data?.length as number)
                })
            }
        }
    }, [session])

    const exportData = async () => {
        if (UserSession.getSession() !== null) {
            if (session?.getRole() as string !== "collaborateur" || session?.getRole() as string !== "coach") {
                const session_token = UserSession?.getSession()?.getToken() as string

                const res = await exportDataRh(session_token, exportDateStart as string, exportDateEnd as string)

                const link = document.createElement('a');
                link.href = res?.dl_url as string;
                link.download = 'export.csv';
                link.click();
            }

        }
    }

    const addUserCallback = (user: Person) => {
        console.log(user)
        setUsers([...users, user])
    }

    const addCardCallback = (bool: boolean, uuid: string) => {
        if (bool) {
            setUsers((users) => {
                users.find(user => user.Uuid === uuid).Carte.push(bool ? "Physique" : "Virtuelle");
                return [...users];
            });
        }
    }

    const disableCardCallback = (bool: boolean, uuid: string) => {
        if (bool) {
            setUsers((users) => {
                users.find(user => user.Uuid === uuid).Carte = users.find(user => user.Uuid === uuid).Carte.filter(carte => carte !== "Physique");
                return [...users];
            });
        }
    }


    const updateUserCallback = (user: Person) => {
        setUsers((users) => {
            users.find(u => u.Uuid === user.Uuid).Prénom = user.Prénom;
            users.find(u => u.Uuid === user.Uuid).Nom = user.Nom;
            users.find(u => u.Uuid === user.Uuid).Email = user.Email;
            users.find(u => u.Uuid === user.Uuid).Phone = user.Phone;
            users.find(u => u.Uuid === user.Uuid).Irm = user.Irm;
            return [...users];
        })
    }

    const deleteUser = async (user_uuid: string) => {
        const response = await removeUser(UserSession.getSession()?.getToken() as string, user_uuid)
        if (response?.success) {
            onClose()
            fetchUsers()
            toast({
                title: "Utilisateur supprimé",
                description: "L'utilisateur a bien été supprimé",
                status: "success",
                duration: 3000,
                isClosable: true,
            })

        } else {
            toast({
                title: "Erreur",
                description: errorMessage(response.data.error_code),
                status: "error",
                duration: 3000,
                isClosable: true,
            })
        }
    }

    const banUserSelect = async (user_uuid: string) => {
        const response = await banUser(UserSession.getSession()?.getToken() as string, user_uuid)
        if (response?.success) {
            onClose()
            fetchUsers()
            toast({
                title: "Utilisateur ban",
                description: "L'utilisateur a bien été banni",
                status: "success",
                duration: 3000,
                isClosable: true,
            })

        } else {
            toast({
                title: "Erreur",
                description: errorMessage(response.data.error_code),
                status: "error",
                duration: 3000,
                isClosable: true,
            })
        }
    }

    const unbanUserSelect = async (user_uuid: string) => {
        const response = await unbanUser(UserSession.getSession()?.getToken() as string, user_uuid)
        if (response?.success) {
            onClose()
            fetchUsers()
            toast({
                title: "Utilisateur débanni",
                description: "L'utilisateur a bien été débanni",
                status: "success",
                duration: 3000,
                isClosable: true,
            })

        } else {
            toast({
                title: "Erreur",
                description: errorMessage(response.data.error_code),
                status: "error",
                duration: 3000,
                isClosable: true,
            })
        }
    }

    const withoutCard = users.filter(user => user.Carte.length === 0).length

    const bagdeColor = (value: string) => {
        let color: string;
        if (value === "Physique") {
            color = "green"
        } else if (value === "Salle") {
            color = "yellow"
        }
        else if (value === "Cours") {
            color = "purple"
        }
        else {
            color = "blue"
        }
        return color
    }

    const columnHelper = createColumnHelper<Person>()

    const columns = [
        columnHelper.accessor('Prénom', {
            cell: info => info.getValue(),
            footer: info => info.column.id,
        }),
        columnHelper.accessor('Nom', {
            cell: info => info.getValue(),
            footer: info => info.column.id,
        }),
        columnHelper.accessor('Email', {
            cell: info => info.getValue(),
            footer: info => info.column.id,
        }),
        columnHelper.accessor('Crédits', {
            cell: info => info.getValue(),
            footer: info => info.column.id,
        }),
        columnHelper.accessor('Solde', {
            cell: info => info.getValue(),
            footer: info => info.column.id,
        }),
        columnHelper.accessor('Renouvellement', {
            cell: info => <Badge variant='solid' colorScheme={info.getValue() === "Annulé" ? "red" : info.getValue() === "Actif" ? "green" : "yellow"}>{info.getValue()}</Badge>,
            footer: info => info.column.id,
        }),
        columnHelper.accessor('Carte', {
            cell: info => info.getValue().length === 0
                ? (<Badge variant='solid' colorScheme="red">Aucune</Badge>)
                : info.getValue().length === 1
                    ? (<Badge variant='outline' colorScheme={bagdeColor(info.getValue()[0])}>{info.getValue()[0]}</Badge>)
                    : (<Stack direction='column' spacing={2}><Badge width='fit-content' variant='outline' colorScheme={bagdeColor(info.getValue()[0])}>{info.getValue()[0]}</Badge><Badge width='fit-content' variant='outline' colorScheme={bagdeColor(info.getValue()[1])}>{info.getValue()[1]}</Badge></Stack>),
            footer: info => info.column.id,
        }),
        columnHelper.accessor('Accès', {
            cell: info => info.getValue().length === 0
                ? (<Badge variant='solid' colorScheme="red">Aucun</Badge>)
                : info.getValue().length === 1
                    ? (<Badge variant='subtle' colorScheme={bagdeColor(info.getValue()[0])}>{info.getValue()[0]}</Badge>)
                    : (<Stack direction='column' spacing={4}><Badge variant='subtle' width='fit-content' colorScheme={bagdeColor(info.getValue()[0])}>{info.getValue()[0]}</Badge><Badge variant='subtle' width='fit-content' colorScheme={bagdeColor(info.getValue()[1])}>{info.getValue()[1]}</Badge></Stack>),
            footer: info => info.column.id,
        }),
        columnHelper.accessor('Actions', {
            cell: info => <Grid
                templateColumns="repeat(4, 1fr)"
                gap={4}
            >
                {(!info.row.original.Carte.find(carte => carte === "Physique") &&
                    <AssociateCard userId={info.row.original.Uuid} returnBool={addCardCallback} iconColor="white" buttonMode="outline" />
                ) || ((users.filter(user => user.Carte.length === 0).length > 0) && <DisableCard userId={info.row.original.Uuid} returnBool={disableCardCallback} iconColor="white" buttonMode="outline" />)}
                <Modify firstname={info.row.original.Prénom} lastname={info.row.original.Nom} email={info.row.original.Email} phone={info.row.original.Phone} irm={info.row.original.Irm} uuid={info.row.original.Uuid} updatedUser={updateUserCallback} />
                <BanUser ban_action={info.row.original.Status !== "banned"} icon_label={(info.row.original.Status !== "banned") ? "ban" : "unban"} validate_label={(info.row.original.Status !== "banned") ? "Bannir" : "Débannir"} validate={() => { (info.row.original.Status !== "banned") ? banUserSelect(info.row.original.Uuid) : unbanUserSelect(info.row.original.Uuid) }} title={(info.row.original.Status !== "banned") ? "Bannir l'utilisateur" : "Débannir l'utilisateur"} message={`Etes-vous sur de vouloir ${(info.row.original.Status !== "banned") ? "bannir" : "débannir"} cet utilisateur ?`} />
                <DeleteUser validate={() => { deleteUser(info.row.original.Uuid) }} />
            </Grid>,
        })
    ]

    const [datasTab, setDatasTab] = useState<Person[][]>([]);

    const updateTable = () => {
        const updatedDatasTab: Person[][] = [];
        let tab: Person[] = [];

        const userArray = users.flat();

        let filteredUsers = userArray;

        if (accessFilter === "both") {
            filteredUsers = filteredUsers.filter(user => user.Accès.length === 2);
        } else if (accessFilter !== "all") {
            filteredUsers = filteredUsers.filter(user => user.Accès.length === 1 && user.Accès[0].toLowerCase() === accessFilter.toLowerCase());
        }

        if (cardFilter === "withCard") {
            filteredUsers = filteredUsers.filter(user => user.Carte.length > 0);
        } else if (cardFilter === "withoutCard") {
            filteredUsers = filteredUsers.filter(user => user.Carte.length === 0);
        }

        if (search !== "") {
            filteredUsers = filteredUsers.filter(user => user.Nom.toLowerCase().includes(search.toLowerCase()) || user.Prénom.toLowerCase().includes(search.toLowerCase()) || user.Email.toLowerCase().includes(search.toLowerCase()) || user.Phone.toLowerCase().includes(search.toLowerCase()) || user.Irm.toLowerCase().includes(search.toLowerCase()) || user.Carte.find(carte => carte.toLowerCase().includes(search.toLowerCase())) || user.Crédits.toString().includes(search.toLowerCase()) || user.Solde.toString().includes(search.toLowerCase()));
        }

        filteredUsers.forEach((d, index) => {
            if ((index + 1) % 9 === 0) {
                updatedDatasTab.push(tab);
                tab = [];
            }
            tab.push(d);
        }
        );

        if (tab.length > 0) {
            updatedDatasTab.push(tab);
        }

        return updatedDatasTab;
    }

    useEffect(() => {
        setDatasTab(updateTable());
    }, [search, users, accessFilter, cardFilter])

    const handlePageLeft = () => {
        if (pageIndex > 0)
            setPageIndex(pageIndex - 1)
    }

    const handlePageRight = () => {
        if (pageIndex < datasTab.length - 1)
            setPageIndex(pageIndex + 1)
    }


    return (
        <MainContainer>
            <Box display='flex' justifyContent='space-between' flexWrap={["wrap", "nowrap"]} alignItems='center' mb='2rem' gap={"2rem"}>

                <Text fontSize="3xl" color="white" fontWeight="bold">
                    Collaborateurs
                </Text>
                <Box>
                    <Stack spacing='4' direction='row'>
                        <Button leftIcon={<ExternalLink />} variant='solid' bg='green.500' color="white" onClick={(session != null && session?.getRole() as string !== "collaborateur") ? onOpen : null}>Exporter</Button>
                        <Popup validateLabel="Exporter et télécharger" closeLabel="Annuler" close={onClose} validate={() => { exportData(); onClose() }} isVisible={isOpen} title="Exporter les données">
                            <Text fontSize='xl' color='#919CAC' className='entries' mb='0.4rem' pb='2'>
                                <span>Date de début</span>
                                <span style={{ color: 'red' }}>*</span>
                            </Text>
                            <Input type="datetime-local" name='date-start' onChange={(e) => {
                                setExportDateStart(e.target.value);
                            }} value={exportDateStart} />
                            <Text fontSize='xl' color='#919CAC' className='entries' mb='0.4rem' pb='2' pt='3'>
                                <span>Date de fin</span>
                                <span style={{ color: 'red' }}>*</span>
                            </Text>
                            <Input type="datetime-local" name='date-end' onChange={(e) => {
                                setExportDateEnd(e.target.value);
                            }} value={exportDateEnd} />

                        </Popup>
                        <AddUser onAddUser={addUserCallback} userType="collaborateur" title="Ajouter un collaborateur" />
                    </Stack>
                </Box>
            </Box>
            {/* TODO: rajouter les onClick */}
            <Stack spacing='4' direction='row' overflowX='auto'>
                <Widget info={`${users.length}`} text="Collaborateurs" icon={<Users />} />
                <Widget info={`${refill}`} text="Recharges" icon={<DollarSign />} />
                <Widget info={`${withoutCard}`} text="Sans carte" icon={<AlertCircle />} />
                {/* <Widget info={`${renewals}`} text="Futurs renouvellements" icon={<Clock />} /> */}
            </Stack>

            {/* <Search onQuery={null} placeholder="Search..."/> */}
            <Box marginTop="1.5rem" border='2px solid #1A1E23' borderRadius='10px' pb='3'>
                <Text fontSize="xl" pl='3' pt='3' color="white" fontWeight="bold">
                    Liste des collaborateurs
                </Text>
                <Box>
                    <Stack direction='row' spacing={4} alignItems='center' justifyContent='space-between' px='3' pt='3' flexWrap={'wrap'}>
                        <InputGroup width='30rem' alignItems='center'>
                            <InputLeftElement backgroundColor='transparent'>
                                <SearchIcon size={16} />
                            </InputLeftElement>
                            <Input placeholder="Rechercher ..." onChange={(e) => {
                                setSearch(e.target.value);
                            }} />
                        </InputGroup>
                        <Stack direction='row' spacing={4} alignItems='center'>
                            <Menu closeOnSelect={false}>
                                <MenuButton as={Button} leftIcon={<Filter />} colorScheme="gray" variant='solid' border='1px solid #252B32' backgroundColor='#1A1E23' color='#FFFFFF'>
                                    Filtres
                                </MenuButton>
                                <MenuList minWidth='240px' zIndex='9999'>
                                    <MenuOptionGroup defaultValue='all' title='Cartes' type='radio' onChange={(value) => setCardFilter(value as string)}>
                                        <MenuItemOption value='all'>Toutes</MenuItemOption>
                                        <MenuItemOption value='withCard'>Avec carte</MenuItemOption>
                                        <MenuItemOption value='withoutCard'>Sans carte</MenuItemOption>
                                    </MenuOptionGroup>
                                    <MenuOptionGroup defaultValue='all' title='Accès' type='radio' onChange={(value) => setAccessFilter(value as string)}>
                                        <MenuItemOption value='all'>Toutes</MenuItemOption>
                                        <MenuItemOption value='cours'>Cours</MenuItemOption>
                                        <MenuItemOption value='salle'>Salle</MenuItemOption>
                                        <MenuItemOption value='both'>Salle + Cours</MenuItemOption>
                                    </MenuOptionGroup>
                                    <MenuDivider />
                                    <Text>D'autres filtres à venir bientôt.</Text>
                                </MenuList>
                            </Menu>
                        </Stack>
                        <Box display='flex' ml='auto' alignItems='center' justifyContent='flex-end'>
                            <Text fontSize='md' mr='4'>{pageIndex + 1} sur {datasTab.length}</Text>
                            <Box w='5.5rem' h='10' bg='#1A1E23' border='1px solid #252B32' borderRadius='3px' display='flex' flexDirection='row' cursor='pointer'>
                                <Box w='10' borderLeftRadius='3px' borderRight='1px solid #252B32' display='flex' justifyContent='center' alignItems='center' onClick={handlePageLeft} _hover={{ backgroundColor: '#393D42' }}>
                                    <ChevronLeft size='24' />
                                </Box>
                                <Box w='10' borderRightRadius='3px' display='flex' justifyContent='center' alignItems='center' onClick={handlePageRight} _hover={{ backgroundColor: '#393D42' }}>
                                    <ChevronRight />
                                </Box>
                            </Box>
                        </Box>

                    </Stack>
                    <Box w='100%' px='3' pt='3'>
                        {datasTab.length > 0 ?
                            <DataTable data={datasTab[pageIndex]} columns={columns} loading={false} sx={{}} />
                            : null}
                    </Box>
                </Box>
            </Box>
        </MainContainer >
    )
}