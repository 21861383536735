import { Box, Stack, Image, Text, Divider, Link } from "@chakra-ui/react";
import Brand from "./Brand";
import { useNavigate } from "react-router-dom";

export default function Footer() {
    const navigate = useNavigate()
    return (
        <footer>
            <Divider orientation="horizontal" />
            <Stack direction="column" spacing={3} align="center" justify="center" p={4} marginBlock={"1rem"}>
                <Text fontSize="sm" color="gray.500" textAlign="center">
                    <b>LE CENTRE D'ENTRAINEMENT</b> by Free Pro
                    <br />
                    <b>Version :</b> alpha 0.1.0
                    <br />
                </Text>
                <Text onClick={() => navigate("/cgu")} cursor="pointer" color="blue.500" _hover={{ color: "blue.600" }}>Conditions générales d'utilisation</Text>
                <Box display="flex" alignItems="center" justifyContent="center" height="50px" gap={"2rem"}>
                    <Image src="https://lce.inno.freepro.com/api/cdn/images/logo-fp.png" alt="Logo Free Pro" h="auto" objectFit="fill" width={"100px"} />
                    &times;
                    <Brand size={100} />
                </Box>
                <Text fontSize="sm" color="gray.500" textAlign="center">
                    <b>Contact :</b> <a href="mailto:lce@freepro.com">lce@freepro.com</a>
                </Text>
            </Stack>
        </footer>
    )
}