import {
	Box,
	Flex,
	Slider,
	SliderFilledTrack,
	SliderProps,
	SliderThumb,
	SliderTrack,
	Spacer,
	Stack,
	Text,
} from "@chakra-ui/react";
import {
	Control,
	FieldPath,
	Path,
	PathValue,
	useController,
} from "react-hook-form";

type RessourceSliderProps<T = number> = {
	label: string;
	control: Control<T>;
	name: FieldPath<T>;
	defaultValue?: PathValue<T, Path<T>>;
	displayedValue(value: PathValue<T, Path<T>>): string;
	min: number;
	displayedMin?: string | number;
	max: number;
	displayedMax?: string | number;
} & SliderProps;

export default function RessourceSlider<T = number>(
	props: RessourceSliderProps<T>
) {
	const {
		label,
		control,
		name,
		defaultValue,
		displayedValue,
		displayedMin,
		displayedMax,
		isDisabled,
		...other
	} = props;
	const {
		field: { value, ref, onChange },
	} = useController({
		defaultValue,
		rules: {},
		control,
		name,
	});

	return (
		<Stack spacing="1">
			<Flex alignItems="center">
				<Text>{label}</Text>
				<Spacer />
				<Text color={isDisabled ? "darkGrey.50" : ""}>
					{displayedValue(value)}
				</Text>
			</Flex>
			<Slider
				isDisabled={isDisabled}
				value={value as number}
				tabIndex={-1}
				ref={ref}
				onChange={onChange}
				{...other}
			>
				<SliderTrack tabIndex={-1} height="6px" borderRadius="full">
					<SliderFilledTrack />
				</SliderTrack>
				<SliderThumb tabIndex={-1}>
					<Box
						width="6px"
						height="6px"
						bg="white"
						_dark={{ bg: "blue.500" }}
						borderRadius="full"
					/>
				</SliderThumb>
			</Slider>
			<Flex>
				<Text fontSize="sm" color="darkGrey.50">
					{displayedMin || other?.min}
				</Text>
				<Spacer />
				<Text fontSize="sm" color="darkGrey.50">
					{displayedMax || other?.max}
				</Text>
			</Flex>
		</Stack>
	);
}
