import { modalAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/styled-system";

/**
 * Permet de surcharger le style du composant "Modal"
 * https://chakra-ui.com/docs/components/modal/theming
 */
const { definePartsStyle, defineMultiStyleConfig } =
	createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
	overlay: {
		// bg: "blackAlpha.200", //change the background
	},
	dialog: {
		pb: "0!important",
		borderRadius: "3xl",
		bg: "white",
		_dark: {
			bg: "darkGrey.900",
			border: "1px",
			borderColor: "darkGrey.800",
			boxShadow: "-5px 0 14px 0 rgba(#0C0E0F, .7)",
		},
	},
	header: {
		p: 6
	},
	footer: {
		p: 6
	},
	closeButton: {
		top: "16px",
		right: "16px",
	}
});

export const Modal = defineMultiStyleConfig({
	baseStyle,
	variants: {
		floating: {
			dialogContainer: defineStyle({
				justifyContent: "flex-end",
				alignItems: "center",
				padding: "20px",
				pointerEvents: "none"
			}),
			dialog: defineStyle({
				pointerEvents: "auto",
				m: 0,
				height: "100%",
				borderRadius: "3xl",
				maxHeight: "initial",
				_dark: {
					bg: "darkGrey.900",
					border: "1px",
					borderColor: "darkGrey.800",
					boxShadow: "-5px 0 14px 0 0C0E0F",
				}
			}),
			body: defineStyle({
				p: 0
			})
		}
	}
});
