import { ChakraProvider } from "@chakra-ui/react";
import "@fontsource/inter";
import { I18nextProvider } from "react-i18next";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Route, Routes, useParams } from "react-router-dom";
import { Fonts } from "./components";
import { DialogProvider } from "./dialog";
import i18n from "./i18n";
import { MainScreen } from "./screens";
import theme from "./theme";
import Dashboard from "./screens/dashboard/Dashboard";
import CollabList from "./screens/dashboard/CollabList";
import Profile from "./screens/dashboard/Profile";
import Login from "./screens/Login";
import Cards from "./screens/dashboard/Cards";
import FirstLogin from "./screens/FirstLogin";
import Reservations from "./screens/dashboard/Reservations";
import { useEffect, useState } from "react";
import UserSession from "./Classes/UserSession";
import ForgottenPassword from "./screens/ForgottenPassword";
import AssignCardsToCollab from "./screens/dashboard/assignCardsToCollab";
import { PageLoader } from "./screens/PageLoader";
import Logout from "./screens/Logout";
import Contact from "./screens/dashboard/Contact";
import CoachList from "./screens/dashboard/CoachList";
import Gestion from "./screens/dashboard/Gestion";
import Stats from "./screens/dashboard/Stats";
import Cgu from "./screens/dashboard/Cgu";
import BackSoon from "./screens/backsoon";

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			structuralSharing: false,
		},
	},
});

const OFFLINE = false

function App() {
	const [session, setSession] = useState<UserSession | null>(null)
	const [isCheckingSession, setIsCheckingSession] = useState<boolean>(true)
	const [firstLogin, setFirstLogin] = useState<boolean>(true)
	const [role, setRole] = useState<string>("")

	useEffect(() => {
		const checkSession = async () => {
			console.log('Checking session')
			if (UserSession.getSession() !== null) {
				console.log('Session found')

				const currentSession = UserSession.getSession()

				if (await UserSession.checkSession(currentSession?.getToken() as string)) {
					console.log("First login: ", currentSession?.firstLogin())

					setRole(currentSession?.getRole() as string)

					if (currentSession?.firstLogin()) {
						setFirstLogin(true)
					} else {
						setFirstLogin(false)
					}
					setIsCheckingSession(false)
					setSession(currentSession)
				} else {
					setIsCheckingSession(false)
					UserSession.clearSession()
					window.location.reload()
				}
			} else {
				console.log('No session found')
				setIsCheckingSession(false)

			}
		}

		checkSession()


	}, [])
	return (
		<QueryClientProvider client={queryClient}>
			<I18nextProvider i18n={i18n}>
				<ChakraProvider theme={theme}>
					<Fonts />
					<DialogProvider>
						<BrowserRouter>
							<Routes>
								{OFFLINE && (
									<Route path="*" element={<BackSoon />} />
								) || (
										<>

											{(isCheckingSession && (
												< Route path="*" element={<PageLoader />} />
											)) || (session !== null && (
												(firstLogin && (
													<>
														< Route path="/logout" element={<Logout />} />
														< Route path="*" element={<FirstLogin />} />
													</>
												)) || (
													<Route path="" element={<MainScreen />}>
														{((role !== "collaborateur") && (role !== "coach") && (
															<>
																<Route path="collabs" element={<CollabList />} />
																<Route path="coachs" element={<CoachList />} />
																<Route path="assign-cards" element={<AssignCardsToCollab />} />
															</>
														)) || (role === "collaborateur" && (
															<>
																<Route path="reservations" element={<Reservations />} />
																<Route path="cards" element={<Cards />} />
																<Route path="contact" element={<Contact />} />
															</>
														))}

														{(role !== "collaborateur" && (
															<>
																<Route path="gestion" element={<Gestion />} />
																<Route path="stats" element={<Stats />} />
															</>
														))}

														<Route path="/" element={<Dashboard />} />
														<Route path="profile" element={<Profile />} />
														<Route path="cgu" element={<Cgu />} />
														<Route path="*" element={<Dashboard />} />

													</Route>
												)
											)) || (
													<>
														<Route path="*" element={<Login />} />
														<Route path="/login" element={<Login />} />
														<Route path="/forgot-password" element={<ForgottenPassword />} />
													</>

												)}
											<Route path="*" element={<MainScreen />} />
										</>
									)}
							</Routes>
						</BrowserRouter>
					</DialogProvider>
				</ChakraProvider>
			</I18nextProvider>
		</QueryClientProvider>
	);
}

export default App;
