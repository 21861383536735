import { useState } from "react";
import { Box, Alert } from "@chakra-ui/react";
import MainContainer from "@/components/MainContainer";

export default function Contact() {
    return (
        <MainContainer>

            <Alert status="info" borderRadius="10px" mb="1rem">
                <Box flex="1">
                    <p>
                        <b>Vous avez une question ?</b> <br />
                        <br />
                        Vous pouvez nous contacter par mail à l'adresse suivante : <br />
                        <a href="mailto:lce@freepro.com"> lce@freepro.com </a>
                    </p>
                </Box>
            </Alert>

        </MainContainer>
    );
}