import { Global } from "@emotion/react";

export default function Fonts() {
  return (
    <Global
      styles={`
    @font-face {
        font-family: "Gotham Regular";
        src: url("./fonts/Gotham/Gotham-Medium.woff2");
    }
          `}
    />
    // <Global
    //   styles={`
    //   @font-face {
    //     font-family: 'SF Pro';
    //     font-style: normal;
    //     font-display: swap;
    //     src: url('/fonts/sfpro/sfpro.woff2') format('woff2');
    //   }
    //   `}
    // />
  );
}
