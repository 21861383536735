import { Box, Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, useDisclosure } from "@chakra-ui/react";
import React from "react";
import { useState, useEffect } from "react";
import { timeUntilStale } from "react-query/types/core/utils";

interface PopupProps {
    children: React.ReactNode,
    close: () => void
    validate: () => void
    closeLabel: string
    validateLabel: string
    isVisible: boolean
    title: string
    loading?: boolean
}

export default function Popup({ children, close, validate, closeLabel, validateLabel, isVisible, title, loading = false }: PopupProps) {
    const [visible, setVisible] = useState<boolean>(false)

    useEffect(() => {
        setVisible(isVisible)
    }, [isVisible])

    const { isOpen, onOpen, onClose } = useDisclosure();
    const firstField = React.useRef();
    const btnRef = React.useRef();

    return (
        <>
            <Drawer
                isOpen={visible}
                placement='right'
                initialFocusRef={firstField}
                onClose={close}
                finalFocusRef={btnRef}
            >
                <DrawerOverlay />
                <DrawerContent bg='darkGrey.800'>

                    <DrawerHeader borderBottomWidth='1px'>
                        {title}
                    </DrawerHeader>

                    <DrawerBody>
                        <Box padding="1rem" >
                            {children}
                        </Box>
                    </DrawerBody>

                    <DrawerFooter borderTopWidth='1px'>
                        <Button variant='outline' mr={3} onClick={close}>
                            {closeLabel}
                        </Button>
                        <Button variant='primary' onClick={validate} isLoading={loading}>
                            {validateLabel}
                        </Button>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    )

}