import { ThemeTypings, useTheme } from "@chakra-ui/react";
import delve from "delve";
import { transparentize } from "color2k";

export function useColor(
	token: ThemeTypings["colors"],
	alpha: number = 1
): string {
	const theme = useTheme();

	try {
		const hex = delve(theme, `colors.${token}`);

		if (alpha < 1) {
			return transparentize(hex, 1 - alpha);
		}

		return hex;
	} catch (err) {
		console.error(`[useColor]: `, err);
		return null;
	}
}
