import {
	FormControl as NativeFormControl,
	FormControlProps as NativeFormControlProps,
	FormHelperText,
	FormLabel,
	IconButton,
	Input,
	InputGroup,
	InputLeftElement,
	InputRightElement,
	SystemProps,
	Textarea,
	Tooltip,
	type InputProps,
} from "@chakra-ui/react";
import { ReactElement, useState } from "react";
import { Eye, EyeOff } from "react-feather";
import { useController, UseControllerProps } from "react-hook-form";

export enum FormControlType {
	Text = "text",
	Password = "password",
	LongText = "longtext",
	File = "file",
	Number = "number",
}

export type FormControlProps<T> = {
	label?: string;
	type?: FormControlType;
	helperText?: string;
	uppercase?: boolean;
	rows?: number;
	onFocus?(): void;
	suffix?: ReactElement;
	prefix?: ReactElement;
} & InputProps &
	SystemProps &
	UseControllerProps<T>;

export default function FormControl<T>(props: FormControlProps<T>) {
	const {
		label,
		placeholder,
		control,
		name,
		defaultValue,
		rules = {},
		isRequired,
		type = FormControlType.Text,
		helperText,
		uppercase = false,
		rows = 10,
		onFocus,
		autoFocus,
		suffix,
		prefix,
		...other
	} = props;

	const [showPassword, setShowPassword] = useState(false);

	const {
		field,
		fieldState: { error },
	} = useController({
		control,
		name,
		rules: {
			required: isRequired,
			...rules,
		},
		defaultValue,
	});

	let inputProps = {
		onChange(e) {
			field.onChange(uppercase ? e.target.value.toUpperCase() : e);
		},
		onBlur() {
			field.onBlur();
		},
		onFocus() {
			if (onFocus) onFocus();
		},
		placeholder,
		name,
		ref: field.ref,
		autoFocus,
	};

	return (
		<NativeFormControl
			isRequired={isRequired}
			{...(other as NativeFormControlProps)}
		>
			{label && <FormLabel>{label}</FormLabel>}
			{[FormControlType.Text, FormControlType.Number].includes(type) && (
				<InputGroup>
					{prefix && <InputLeftElement>{prefix}</InputLeftElement>}
					<Input type={type} value={field.value as string} {...inputProps} />
					{suffix && <InputRightElement>{suffix}</InputRightElement>}
				</InputGroup>
			)}
			{type === FormControlType.Password && (
				<InputGroup>
					<Input
						type={showPassword ? "text" : "password"}
						value={field.value as string}
						{...inputProps}
					/>
					<InputRightElement>
						<Tooltip title={showPassword ? "Hide password" : "Show password"}>
							<IconButton
								variant="ghost"
								colorScheme="white"
								aria-label={showPassword ? "Hide password" : "Show password"}
								icon={showPassword ? <Eye size={16} /> : <EyeOff size={16} />}
								onClick={() => setShowPassword((prev) => !prev)}
							/>
						</Tooltip>
					</InputRightElement>
				</InputGroup>
			)}
			{type === FormControlType.LongText && (
				<Textarea rows={rows} value={field.value as string} {...inputProps} />
			)}
			{helperText && <FormHelperText>{helperText}</FormHelperText>}
			{error && (
				<FormHelperText color="text.danger">{error?.message}</FormHelperText>
			)}
		</NativeFormControl>
	);
}
