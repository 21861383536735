import UserSession from "@/Classes/UserSession"
import { addSport } from "@/api"
import { errorMessage } from "@/utils/errorMessage"
import { Button, useDisclosure, useToast, Text, Input, Textarea } from "@chakra-ui/react"
import { useState } from "react"
import { Plus } from "react-feather"
import Popup from "@/components/Popup"


export default function AddSport({ validate }: { validate: (sport: any) => void }) {
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [handleSportName, setHandleSportName] = useState<string>("")
  const [handleSportDesc, setHandleSportDesc] = useState<string>("")

  const updateInput = (e) => {
    switch (e.target.name) {
      case 'sport_name':
        setHandleSportName(e.target.value)
        break
      case 'sport_desc':
        setHandleSportDesc(e.target.value)
        break
    }
  }

  const addSportAction = async () => {
    if (UserSession.getSession() != null) {
      const response = await addSport(UserSession.getSession()?.getToken() as string, handleSportName, handleSportDesc != null ? handleSportDesc : "")
      if (response.success) {
        toast({
          title: "Utilisateur ajouté",
          description: "Le sport a été ajouté avec succès",
          status: "success",
          duration: 9000,
          isClosable: true,
        })
        onClose()
        validate({
          Id: response.data.sport_id,
          Nom: handleSportName,
          Description: handleSportDesc != null ? handleSportDesc : "",
        })
      } else {
        toast({
          title: "Erreur lors de l'ajout'",
          description: errorMessage(response.data.error_code),
          status: "error",
          duration: 9000,
          isClosable: true,
        })
      }
    }
  }

  return (
    <>
      <Button leftIcon={<Plus />} variant='primary' color="white" onClick={(UserSession.getSession() != null && UserSession.getSession()?.getRole() as string !== "collaborateur") ? onOpen : null}>Ajouter un nouveau sport</Button>
      <Popup validateLabel="Ajouter un nouveau sport" closeLabel="Annuler" close={onClose} validate={() => { addSportAction(); onClose() }} isVisible={isOpen} title="Ajouter un sport">
        <Text fontSize='xl' color='#919CAC' className='entries' mb='0.4rem' pb='2'>
          <span>Nom</span>
          <span style={{ color: 'red' }}>*</span>
        </Text>
        <Input name='sport_name' onChange={updateInput} />
        <Text fontSize='xl' color='#919CAC' className='entries' mb='0.4rem' pb='2' pt='3'>
          <span>Description</span>
        </Text>
        <Textarea minHeight="100px" name='sport_desc' onChange={updateInput} />

      </Popup>
    </>
  )
}