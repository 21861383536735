import { defineStyle, defineStyleConfig, StyleFunctionProps } from "@chakra-ui/react";
import { transparentize } from "../utils";

const getDisabledStyles = defineStyle({
	bg: "lightGrey.200!important",
	color: "darkGrey.50!important",
	border: "none!important",
	opacity: "1!important",
	_dark: {
		bg: "darkGrey.700!important",
	}
});

const createDefault = defineStyle({
	border: "1px",
	color: "text.default",
	_light: {
		borderColor: "lightGrey.200",
		_hover: {
			bg: "lightGrey.100",
		},
		_active: {
			bg: "lightGrey.200",
		},
	},
	_dark: {
		bg: "darkGrey.800",
		borderColor: "darkGrey.700",
		_hover: {
			bg: "darkGrey.700",
			borderColor: "darkGrey.600",
		},
		_active: {
			bg: "darkGrey.600",
			borderColor: "darkGrey.700",
		},
	},
	_disabled: getDisabledStyles
});

const createPrimary = defineStyle({
	bg: "blue.500",
	color: "white",
	_hover: {
		bg: "blue.400",
	},
	_active: {
		bg: "blue.300",
	},
	_disabled: getDisabledStyles
});

const createWarn = defineStyle({
	bg: "red.400",
	color: "white",
	_hover: {
		bg: "red.300",
	},
	_active: {
		bg: "red.200",
	},
	_disabled: getDisabledStyles
});

const createGhost = defineStyle((props: StyleFunctionProps) => {
	const styles = defineStyle({
		bg: "transparent",
		_light: {
			color: "text.default",
			_hover: {
				bg: "lightGrey.100",
			},
			_active: {
				bg: "lightGrey.200",
			},
		},
		_dark: {
			color: "text.default",
			_hover: {
				bg: "darkGrey.700",
			},
			_active: {
				bg: "darkGrey.600",
			},
		},
		_disabled: getDisabledStyles
	});

	switch (props.colorScheme) {
		case "green": {
			styles._light = {
				color: "green.500",
				_hover: {
					bg: transparentize("green.500", .12)(props.theme),
				},
				_active: {
					bg: transparentize("green.500", .2)(props.theme),
				},
			};

			styles._dark = {
				...styles._dark,
				color: "green.400",
				_hover: {
					bg: transparentize("green.400", .12)(props.theme),
				},
				_active: {
					bg: transparentize("green.400", .2)(props.theme),
				},
			};

			break;
		}
	}

	return styles;
});

/**
 * Permet de surcharger le style du bouton
 */
export const Button = defineStyleConfig({
	baseStyle: {
		"& svg": {
			width: "16px",
			height: "16px",
		},
		borderRadius: "xl",
		fontWeight: "400"
	},
	sizes: {
		sm: {
			fontSize: "md",
			height: "32px",
			minWidth: "32px"
		},
		md: {
			fontSize: "md",
			height: "40px",
			minWidth: "40px"
		},
		lg: {
			fontSize: "md",
			height: "48px",
			minWidth: "48px"
		}
	},
	variants: {
		default: createDefault,
		primary: createPrimary,
		warn: createWarn,
		ghost: createGhost
	},
	defaultProps: {
		size: "md",
		variant: "default"
	},
});
