import { Box, Collapse, Flex, Heading, Spacer, Stack, useDisclosure } from "@chakra-ui/react";
import { PropsWithChildren } from "react";
import { ChevronDown } from "react-feather";

export type CollapserPanelProps = {
  title: string;
}

export default function CollapserPanel(props: PropsWithChildren<CollapserPanelProps>) {
  const { children, title } = props;
  const { isOpen, onToggle } = useDisclosure({
    defaultIsOpen: true
  });

  return (
    <Stack spacing={4} p={4}>
      <Flex role="button" tabIndex={-1} onClick={onToggle}>
        <Heading as="h5" size="md">
          {title}
        </Heading>
        <Spacer />
        <Box sx={{
          transition: "all .2s ease",
          transform: `rotate(${isOpen ? "0" : "-180"}deg)`
        }}>
          <ChevronDown />
        </Box>
      </Flex>
      <Collapse in={isOpen} animateOpacity>
        {children}
      </Collapse>
    </Stack>
  )
}