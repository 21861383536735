import { DataTable } from "@/components/datatable";
import Search from "../../components/Search";
import {
    Box,
    Stack,
    Text,
    Input,
    Button,
    Badge,
    useDisclosure,
    Grid,
    InputGroup,
    InputLeftElement,
    Menu,
    MenuButton,
    MenuList,
    MenuOptionGroup,
    MenuItemOption,
    MenuDivider,
} from "@chakra-ui/react";
import { AlertCircle, ChevronLeft, ChevronRight, Clock, DollarSign, ExternalLink, Search as SearchIcon, Table, Trash, Users, X } from "react-feather";
import { Filter } from "react-feather";
import { createColumnHelper } from "@tanstack/react-table";
import AddUser from "./AddUser";
import AssociateCard from "./AssociateCard";
import Modify from './Modify'
import Widget from "./Widget";
import Popup from "@/components/Popup"
import { useEffect, useState } from "react";
import UserSession from "@/Classes/UserSession";
import { removeUser, userList } from "@/api";
import DeleteUser from "./DeleteUser";
import { useToast } from '@chakra-ui/react'
import { errorMessage } from "@/utils/errorMessage";
import MainContainer from "@/components/MainContainer";


type Coach = {
    Uuid: string
    Prénom: string
    Nom: string
    Email: string
    Actions: string
    Phone: string
    Irm: string
}

export default function CoachList() {
    const toast = useToast()
    const [pageIndex, setPageIndex] = useState(0)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [session, setSession] = useState<UserSession | null>(null)
    const currentDate = new Date();
    const previousMonth = currentDate.getMonth() - 1;
    const formattedDate = new Date(currentDate.getFullYear(), previousMonth, currentDate.getDate(), currentDate.getHours() + 2, currentDate.getMinutes()).toISOString().slice(0, 16);
    const [users, setUsers] = useState<Coach[]>([])

    const [search, setSearch] = useState<string>("")



    useEffect(() => {
        setSession(UserSession.getSession() as UserSession | null)
    }, [])

    const fetchUsers = async () => {
        if (UserSession.getSession() !== null) {
            const session_token = UserSession?.getSession()?.session_token as string
            const response = await userList(session_token)
            setUsers(response?.data?.filter((user: any) => user.role === "coach").map((user: any, index: number) => {
                return {
                    Uuid: user.uuid as string,
                    Prénom: user.firstname as string,
                    Nom: user.lastname as string,
                    Email: user.email as string,
                    Modifier: true,
                    Phone: user.phone as string,
                    Irm: user.irm as string
                }
            }))
        }
    }

    useEffect(() => {
        if (session !== null) {
            if (session?.getRole() !== "collaborateur") {
                fetchUsers()
            }
        }
    }, [session])

    const addUserCallback = (user: Coach) => {
        setUsers([...users, user])
    }

    const updateUserCallback = (user: Coach) => {
        setUsers((users) => {
            users.find(u => u.Uuid === user.Uuid).Prénom = user.Prénom;
            users.find(u => u.Uuid === user.Uuid).Nom = user.Nom;
            users.find(u => u.Uuid === user.Uuid).Email = user.Email;
            users.find(u => u.Uuid === user.Uuid).Phone = user.Phone;
            users.find(u => u.Uuid === user.Uuid).Irm = user.Irm;
            return [...users];
        })
    }

    const deleteUser = async (user_uuid: string) => {
        const response = await removeUser(UserSession.getSession()?.getToken() as string, user_uuid)
        if (response?.success) {
            onClose()
            fetchUsers()
            toast({
                title: "Utilisateur supprimé",
                description: "L'utilisateur a bien été supprimé",
                status: "success",
                duration: 3000,
                isClosable: true,
            })

        } else {
            toast({
                title: "Erreur",
                description: errorMessage(response.data.error_code),
                status: "error",
                duration: 3000,
                isClosable: true,
            })
        }
    }

    const columnHelper = createColumnHelper<Coach>()

    const columns = [
        columnHelper.accessor('Prénom', {
            cell: info => info.getValue(),
            footer: info => info.column.id,
        }),
        columnHelper.accessor('Nom', {
            cell: info => info.getValue(),
            footer: info => info.column.id,
        }),
        columnHelper.accessor('Email', {
            cell: info => info.getValue(),
            footer: info => info.column.id,
        }),
        columnHelper.accessor('Phone', {
            cell: info => info.getValue(),
            footer: info => info.column.id,
        }),
        columnHelper.accessor('Actions', {
            cell: info => <Grid
                templateColumns="repeat(2, 25%)"
                gap={4}
            >
                <Modify firstname={info.row.original.Prénom} lastname={info.row.original.Nom} email={info.row.original.Email} phone={info.row.original.Phone} irm={info.row.original.Irm} uuid={info.row.original.Uuid} updatedUser={updateUserCallback} />
                <DeleteUser validate={() => { deleteUser(info.row.original.Uuid) }} />
            </Grid>,
        })
    ]

    const [datasTab, setDatasTab] = useState<Coach[][]>([]);

    const updateTableBySearch = () => {
        const updatedDatasTab: Coach[][] = [];
        let tab: Coach[] = [];

        const filteredUsers = users.filter(user => user.Nom.toLowerCase().includes(search.toLowerCase()) || user.Prénom.toLowerCase().includes(search.toLowerCase()) || user.Email.toLowerCase().includes(search.toLowerCase()) || user.Phone.toLowerCase().includes(search.toLowerCase()) || user.Irm.toLowerCase().includes(search.toLowerCase()));

        filteredUsers.forEach((d, index) => {
            if ((index + 1) % 9 === 0) {
                updatedDatasTab.push(tab);
                tab = [];
            }
            tab.push(d);
        }
        );

        if (tab.length > 0) {
            updatedDatasTab.push(tab);
        }

        return updatedDatasTab;
    }

    useEffect(() => {
        setDatasTab(updateTableBySearch());
    }, [search, users])

    const handlePageLeft = () => {
        if (pageIndex > 0)
            setPageIndex(pageIndex - 1)
    }

    const handlePageRight = () => {
        if (pageIndex < datasTab.length - 1)
            setPageIndex(pageIndex + 1)
    }


    return (
        <MainContainer>
            <Box display='flex' justifyContent='space-between' flexWrap={["wrap", "nowrap"]} alignItems='center' mb='2rem' gap={"2rem"}>

                <Text fontSize="3xl" color="white" fontWeight="bold">
                    Coachs
                </Text>
                <Box>
                    <Stack spacing='4' direction='row'>
                        <AddUser onAddUser={addUserCallback} userType="coach" title="Ajouter un coach" />
                    </Stack>
                </Box>
            </Box>
            {/* TODO: rajouter les onClick */}
            <Stack spacing='4' direction='row' overflowX='auto'>
                <Widget info={`${users.length}`} text="Coachs" icon={<Users />} />
            </Stack>

            {/* <Search onQuery={null} placeholder="Search..."/> */}
            <Box marginTop="1.5rem" border='2px solid #1A1E23' borderRadius='10px' pb='3'>
                <Text fontSize="xl" pl='3' pt='3' color="white" fontWeight="bold">
                    Liste des coachs
                </Text>
                <Box>
                    <Stack direction='row' spacing={4} alignItems='center' justifyContent='space-between' px='3' pt='3' flexWrap={'wrap'}>
                        <InputGroup width='30rem' alignItems='center'>
                            <InputLeftElement backgroundColor='transparent'>
                                <SearchIcon size={16} />
                            </InputLeftElement>
                            <Input placeholder="Rechercher ..." onChange={(e) => {
                                setSearch(e.target.value);
                            }} />
                        </InputGroup>
                        {/* <Stack direction='row' spacing={4} alignItems='center'>
                            <Menu closeOnSelect={false}>
                                <MenuButton as={Button} leftIcon={<Filter />} colorScheme="gray" variant='solid' border='1px solid #252B32' backgroundColor='#1A1E23' color='#FFFFFF'>
                                    Filtres
                                </MenuButton>
                                <MenuList minWidth='240px' zIndex='9999'>
                                    <MenuOptionGroup defaultValue='all' title='Cartes' type='radio' onChange={(value) => setCardFilter(value as string)}>
                                        <MenuItemOption value='all'>Toutes</MenuItemOption>
                                        <MenuItemOption value='withCard'>Avec carte</MenuItemOption>
                                        <MenuItemOption value='withoutCard'>Sans carte</MenuItemOption>
                                    </MenuOptionGroup>
                                    <MenuDivider />
                                    <Text>D'autres filtres à venir bientôt.</Text>
                                </MenuList>
                            </Menu>
                        </Stack> */}
                        <Box display='flex' ml='auto' alignItems='center' justifyContent='flex-end'>
                            <Text fontSize='md' mr='4'>{pageIndex + 1} sur {datasTab.length}</Text>
                            <Box w='5.5rem' h='10' bg='#1A1E23' border='1px solid #252B32' borderRadius='3px' display='flex' flexDirection='row' cursor='pointer'>
                                <Box w='10' borderLeftRadius='3px' borderRight='1px solid #252B32' display='flex' justifyContent='center' alignItems='center' onClick={handlePageLeft} _hover={{ backgroundColor: '#393D42' }}>
                                    <ChevronLeft size='24' />
                                </Box>
                                <Box w='10' borderRightRadius='3px' display='flex' justifyContent='center' alignItems='center' onClick={handlePageRight} _hover={{ backgroundColor: '#393D42' }}>
                                    <ChevronRight />
                                </Box>
                            </Box>
                        </Box>

                    </Stack>
                    <Box w='100%' px='3' pt='3'>
                        {datasTab.length > 0 ?
                            <DataTable data={datasTab[pageIndex]} columns={columns} loading={false} sx={{}} />
                            : null}
                    </Box>
                </Box>
            </Box>
        </MainContainer >
    )
}