import { Box, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import React from "react";

interface PopupProps {
  children: React.ReactNode,
  close: () => void
  validate: () => void
  closeLabel: string
  validateLabel: string
  isVisible: boolean
  title: string
  loading?: boolean
}

export default function AssociateCard({ children, close, validate, closeLabel, validateLabel, isVisible, title, loading = false }: PopupProps) {

  const initialRef = React.useRef(null)
  const finalRef = React.useRef(null)

  return (
    <>

      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isVisible}
        onClose={close}
        isCentered
        size={['sm', 'md', 'lg', 'xl']}
      >
        <ModalOverlay />
        <ModalContent bg='darkGrey.800'>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Box>{children}</Box>
          </ModalBody>

          <ModalFooter>
            <Button onClick={close} mr={3}>{closeLabel}</Button>
            <Button isLoading={loading} colorScheme='blue' variant='primary' onClick={validate}>
              {validateLabel}
            </Button>
          </ModalFooter>

        </ModalContent>
      </Modal>
    </>
  )
}