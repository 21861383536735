import { Badge, Box, Divider, Flex, IconButton, Stack, Text, Tooltip } from "@chakra-ui/react";
import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Icon from "../Icon";
import SidenavLink from "./SidenavLink";
import { faCreditCard } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './sideNav.scss'

export type NavLink = {
	icon: string;
	label: string;
	to: string;
	visible?: boolean;
};

export type NavItem = {
	label: string;
	links: NavLink[];
};

export type NavItems = NavItem[];

export type SidenavProps = {
	brandContainer?(isCollapsed: boolean): ReactElement;
	items: NavItems;
	bottomContainer?(isCollapsed: boolean): ReactElement;
};

export default function Sidenav(props: SidenavProps) {
	const { t } = useTranslation();
	const { brandContainer, items, bottomContainer } = props;
	const [isCollapsed, setIsCollapsed] = useState(false);
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	const handleResize = useCallback(() => {
		if (window.innerWidth < 768) setIsCollapsed(true)
		setWindowWidth(window.innerWidth);
	}, []);

	useEffect(() => {
		handleResize();
	}, [handleResize]);

	useEffect(() => {
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, [handleResize]);


	const toggleCollapse = useCallback(() => {
		setIsCollapsed((prev) => !prev);
	}, []);

	const brandContainerStyle = useMemo(
		() => ({
			px: isCollapsed ? "0" : "5",
			justifyContent: isCollapsed ? "center" : "space-between",
		}),
		[isCollapsed]
	);

	const currentCollapseIcon = useMemo(
		() => (isCollapsed ? <Icon name="plus" /> : <Icon name="minus" />),
		[isCollapsed]
	);

	const hasBrandContainer = useMemo(
		() => Boolean(brandContainer),
		[brandContainer]
	);
	const hasBottomContainer = useMemo(
		() => Boolean(bottomContainer),
		[bottomContainer]
	);

	const collapseLabel = useMemo(
		() => (isCollapsed ? t("Expand") : t("Collapse")),
		[t, isCollapsed]
	);

	return (
		<Box
			className={`sidenav ${isCollapsed ? "collapsed" : ""}`}
			h="100dvh"
			bg="white"
			display="flex"
			flexDirection="column"
			transition="width .4s cubic-bezier(0.16, 1, 0.3, 1)"
			zIndex="999"
			_dark={{
				bg: "darkGrey.900",
			}}
			borderRight='2px solid #1A1E23'
		>
			<Stack spacing="8" pt="5" flex="1">
				<Flex alignItems="center" sx={brandContainerStyle}>
					{hasBrandContainer && brandContainer(isCollapsed)}
					<Tooltip label={collapseLabel} placement="right">
						<IconButton
							aria-label={collapseLabel}
							onClick={toggleCollapse}
							size="sm"
							icon={currentCollapseIcon}
						/>
					</Tooltip>
				</Flex>
				<Stack spacing="1" alignItems={'center'}>
					{
						items.map((item) => (
							<Stack spacing="1" key={item.label} w={isCollapsed ? "auto" : "100%"} px={"1rem"}>
								<Box as="nav">
									{item.links.filter(l => l.visible).map((link, index: number) => (
										<SidenavLink
											key={index}
											isCollapsed={isCollapsed}
											icon={({ isActive }) => (
												<Icon
													_light={{
														color: isActive ? "red.500" : null,
													}}
													name={link.icon}
												/>
											)}
											label={link.label}
											to={link.to}
										/>
									))}
								</Box >
							</Stack>
						))}
				</Stack>
			</Stack>
			{hasBottomContainer && <Box p="5" zIndex={9999}>{bottomContainer(isCollapsed)}</Box>}
		</Box >
	);
}
