import { defineStyle, defineStyleConfig } from '@chakra-ui/react';
import { transparentize } from '../utils';

const outlineColor = transparentize("blue.500", .16);

const sizes = {
  md: defineStyle({
    height: "40px"
  })
}

const outline = defineStyle({
  borderRadius: "xl",
  border: "1px",
  bg: "white",
  borderColor: "lightGrey.100",
  "&:hover": {
    borderColor: "lightGrey.200",
    bg: "white",
  },
  "&:focus": {
    borderColor: "blue.500",
    outline: "3px solid",
    outlineColor,
    bg: "white",
  },
  boxShadow: "initial!important",
  _dark: {
    borderColor: "darkGrey.600",
    bg: "darkGrey.800",
    _placeholder: {
      color: "darkGrey.50",
    },
    "&:hover": {
      borderColor: "darkGrey.500",
    },
    "&:focus": {
      borderColor: "blue.500",
      outline: "3px solid",
      outlineColor: outlineColor,
    },
    boxShadow: "initial!important",
  },
})

export const Textarea = defineStyleConfig({
  variants: {
    outline
  },
  sizes,
  defaultProps: {
    variant: "outline",
    size: "md"
  }
});