import { selectAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";
import { transparentize } from '../utils';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(selectAnatomy.keys)
const outlineColor = transparentize("blue.500", .16);

/**
 * Permet de surcharger le style du composant "Input"
 */
const outline = definePartsStyle({
  field: {
    borderRadius: "xl",
    border: "1px",
    bg: "white",
    borderColor: "lightGrey.100",
    "&:hover": {
      borderColor: "lightGrey.200",
      bg: "white",
    },
    "&:focus": {
      borderColor: "blue.500",
      outline: "3px solid",
      outlineColor,
      bg: "white",
    },
    boxShadow: "initial!important",
    _dark: {
      borderColor: "darkGrey.600",
      bg: "darkGrey.800",
      _placeholder: {
        color: "darkGrey.50",
      },
      "&:hover": {
        borderColor: "darkGrey.500",
      },
      "&:focus": {
        borderColor: "blue.500",
        outline: "3px solid",
        outlineColor: outlineColor,
      },
      boxShadow: "initial!important",
    },
  },
});

const sizes = {
  md: definePartsStyle({
    field: defineStyle({
      height: "40px"
    })
  })
}

export const Select = defineMultiStyleConfig({
  sizes,
  variants: {
    outline
  },
  defaultProps: {
    variant: "outline",
    size: "md"
  }
})