import PopupForm from "@/components/PopupForm"
import { FormControl, FormLabel, IconButton, Input, useDisclosure } from "@chakra-ui/react"
import { useRef, useState } from "react"
import { Edit2 } from "react-feather"
import { updateUser } from "@/api"
import UserSession from "@/Classes/UserSession"
import { useToast } from "@chakra-ui/react"
import { errorMessage } from "@/utils/errorMessage"

interface ModifyProps {
  firstname: string
  lastname: string
  email: string
  phone: string
  irm: string
  uuid: string
  updatedUser: (user: any) => void
}

export default function Modify({ firstname, lastname, email, phone, irm, uuid, updatedUser }: ModifyProps) {
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [handleFirstname, setHandleFirstname] = useState<string>(firstname)
  const [handleLastname, setHandleLastname] = useState<string>(lastname)
  const [handleEmail, setHandleEmail] = useState<string>(email)
  const [handlePhone, setHandlePhone] = useState<string>(phone)
  const [handleIRM, setHandleIRM] = useState<string>(irm)

  const updateInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    switch (e.target.name) {
      case 'firstname':
        setHandleFirstname(e.target.value)
        break
      case 'lastname':
        setHandleLastname(e.target.value)
        break
      case 'email':
        setHandleEmail(e.target.value)
        break
      case 'phone':
        setHandlePhone(e.target.value)
        break
      case 'irm':
        setHandleIRM(e.target.value)
        break
    }
  }

  const update = async () => {
    if (UserSession.getSession() != null) {
      const response = await updateUser(UserSession.getSession()?.getToken() as string, uuid, handleEmail, handleFirstname, handleLastname, handlePhone, handleIRM)
      if (response.success) {
        toast({
          title: "Utilisateur modifié",
          description: "L'utilisateur a été modifié avec succès",
          status: "success",
          duration: 9000,
          isClosable: true,
        })
        onClose()
        updatedUser({
          Uuid: uuid,
          Prénom: handleFirstname,
          Nom: handleLastname,
          Email: handleEmail,
          Phone: handlePhone,
          Irm: handleIRM,
        })
      } else {
        toast({
          title: "Erreur lors de la modification",
          description: errorMessage(response.data.error_code),
          status: "error",
          duration: 9000,
          isClosable: true,
        })
      }
    }
  }
  const initialRef = useRef(null)
  //const finalRef = useRef(null)

  return (
    <>
      <IconButton aria-label="Modifier" title="Modifier l'utilisateur" icon={<Edit2 color="white" />} variant='outline' colorScheme="blue" onClick={onOpen} maxWidth="80px" />
      <PopupForm closeLabel="Annuler" validateLabel="Modifier" close={onClose} validate={update} isVisible={isOpen} title="Modifier le collaborateur">
        <FormControl>
          <FormLabel>Prénom</FormLabel>
          <Input name="firstname" ref={initialRef} defaultValue={firstname} onChange={updateInput} />
        </FormControl>

        <FormControl mt={4}>
          <FormLabel>Nom</FormLabel>
          <Input name="lastname" defaultValue={lastname} onChange={updateInput} />
        </FormControl>

        <FormControl mt={4}>
          <FormLabel>Email</FormLabel>
          <Input name="email" defaultValue={email} onChange={updateInput} />
        </FormControl>

        <FormControl mt={4}>
          <FormLabel>Téléphone</FormLabel>
          <Input name="phone" defaultValue={phone} onChange={updateInput} />
        </FormControl>

        <FormControl mt={4}>
          <FormLabel>IRM</FormLabel>
          <Input name="irm" defaultValue={irm} onChange={updateInput} />
        </FormControl>
      </PopupForm>
    </>
  )
}