import { DataTable } from '@/components/datatable';
import { Box, Button, Input, InputGroup, InputLeftElement, Stack, Text, useToast, useDisclosure } from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import { Search as SearchIcon, Trash, Users } from "react-feather";
import { createColumnHelper } from '@tanstack/react-table';
import UserSession from '@/Classes/UserSession';
import { faCreditCard } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Widget from './Widget';
import { countDemands, countPhysicalCards, cancelCardAsk } from '@/api';
import AssociateCard from './AssociateCard';
import Popup from '@/components/Popup';
import { errorMessage } from '@/utils/errorMessage';
import MainContainer from '@/components/MainContainer';

export default function AssignCardsToCollab() {
    const toast = useToast()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [sessionToken, setSessionToken] = useState<string | null>(null)
    const [askId, setAskId] = useState<number | null>(null)

    const [currentDemands, setCurrentDemands] = useState<number>(0)
    const [currentCards, setCurrentCards] = useState<number>(0)

    const [datasTab, setDatasTab] = useState<any>([])

    const columnHelper = createColumnHelper<any>()



    useEffect(() => {
        setSessionToken(UserSession.getSession()?.getToken() as string)
    }, [])

    const addCardCallback = (bool: boolean, uuid: string) => {
        if (bool) {
            setDatasTab((prevDatasTab: []) => {
                const filteredData = prevDatasTab.filter((data: any) => data.uuid !== uuid)
                setCurrentCards(prevCurrentCards => prevCurrentCards + 1);
                setCurrentDemands(filteredData.length);
                return filteredData;
            });
        }
    }

    const cancelAsk = async (ask_id: number) => {
        const response = await cancelCardAsk(sessionToken as string, ask_id)
        onClose()
        if (response.success) {
            toast({
                title: "Demande annulée",
                description: "La demande a bien été annulée.",
                status: "success",
                duration: 3000,
                isClosable: true,
            })
            setDatasTab((prevDatasTab: []) => {
                const filteredData = prevDatasTab.filter((data: any) => data.ask_id !== ask_id)
                setCurrentDemands(filteredData.length);
                return filteredData;
            });
        } else {
            toast({
                title: "Annulation impossible",
                description: errorMessage(response.data.error_code),
                status: "error",
                duration: 3000,
                isClosable: true,
            })
        }
    }

    const fetchInfos = async () => {
        const response = await countDemands(sessionToken as string)
        setCurrentDemands(response.data.demands)
        setDatasTab(response?.data?.users?.map((user: any) => {
            return {
                Prénom: user.firstname,
                Nom: user.lastname,
                Email: user.email,
                ask_id: user.ask_id,
                uuid: user.uuid,
                Actions:
                    <Box display='flex' justifyContent='space-around'>
                        <AssociateCard userId={user.uuid} iconColor='black' buttonMode='solid' returnBool={(bool: boolean, uuid: string) => { addCardCallback(bool, uuid); setAskId(user.ask_id) }} />

                        <Button colorScheme="red" variant="solid" onClick={() => { setAskId(user.ask_id); onOpen() }}>
                            <Trash size={16} />
                        </Button>
                    </Box>
            }
        }
        ))

        const response2 = await countPhysicalCards(sessionToken as string)
        setCurrentCards(response2.data.count)
    }

    const columns = [
        columnHelper.accessor('Prénom', {
            cell: info => info.getValue(),
            footer: info => info.column.id,
        }),
        columnHelper.accessor('Nom', {
            cell: info => info.getValue(),
            footer: info => info.column.id,
        }),
        columnHelper.accessor('Email', {
            cell: info => info.getValue(),
            footer: info => info.column.id,
        }),
        columnHelper.accessor('Actions', {
            cell: info => info.getValue(),
            footer: info => info.column.id,
        }),
    ]





    useEffect(() => {
        if (sessionToken != null) {
            if (UserSession.getSession()?.getRole() !== "collaborateur") {
                fetchInfos()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionToken])



    return (
        <MainContainer>
            <Text fontSize="3xl" color="white" fontWeight="bold" mb="2rem">
                Gestion des demandes de cartes
            </Text>

            <Stack spacing='4' direction='row' overflowX={'auto'}>
                <Widget info={`${currentCards}`} text="Cartes physiques" icon={<FontAwesomeIcon icon={faCreditCard} />} />
                <Widget info={`${currentDemands}`} text="Demandes" icon={<Users />} />
            </Stack>

            <Box marginTop="1.5rem" border='2px solid #1A1E23' borderRadius='10px' pb='1.6rem' overflowX={'auto'}>
                {
                    ((datasTab?.length === 0) && <Text fontSize="xl" pl='10' pt='5' color="white" fontWeight="bold">
                        Aucune demande de carte pour le moment
                    </Text>
                    ) || (<>
                        <Text fontSize="xl" pl='3' pt='5' color="white" fontWeight="bold">
                            Liste des demandes
                        </Text>
                        <Box>
                            {/* <Box display='flex' pl='10' pr='10' pt='6'>
                                <InputGroup border='1px solid #303740' borderRadius='10px' width='30rem' backgroundColor='#1A1E23' alignItems='center'>
                                    <InputLeftElement backgroundColor='transparent'>
                                        <SearchIcon size={16} />
                                    </InputLeftElement>
                                    <Input placeholder="Search..." />
                                </InputGroup>
                            </Box> */}
                            <Box w='10xl' px='3' pt='3'>
                                {datasTab.length > 0 ?
                                    <DataTable data={datasTab} columns={columns} loading={false} sx={{}} />
                                    : null}
                            </Box>
                        </Box>
                    </>
                    )
                }

            </Box>

            <Popup close={onClose} validate={() => cancelAsk(askId)} closeLabel={'Annuler'} validateLabel={'Supprimer'} isVisible={isOpen} title={'Supprimer la demande'}>
                Voulez-vous vraiment annuler cette demande ? <b>Cette action est irréversible.</b>
                <br />
                Cependant, vous pourrez toujours associer une carte à cet utilisateur via le tableau des collaborateurs.
            </Popup>
        </MainContainer >
    )
}