import { Box, Text, Stack, IconButton, useDisclosure, useToast, Grid, GridItem, Button, MenuButton, MenuList, MenuOptionGroup, MenuItemOption, MenuDivider, Menu } from "@chakra-ui/react";
import { Trash, Clock, Check, Filter } from "react-feather";
import { useEffect, useState } from "react";
import UserSession from "@/Classes/UserSession";
import { cancelReservation, getOwnReservations, coursesTaken } from "@/api";
import Popup from "@/components/Popup";
import { errorMessage } from "@/utils/errorMessage";
import MainContainer from "@/components/MainContainer";


export default function Reservations() {
    const toast = useToast()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [reservations, setReservations] = useState([])
    const [session, setSession] = useState<UserSession | null>()
    const [coursesDone, setCourseDone] = useState<number>(0)
    const [showOldCourses, setShowOldCourses] = useState(false)
    const [showFutureCourses, setShowFutureCourses] = useState(true)

    useEffect(() => {
        setSession(UserSession.getSession() as UserSession | null)
    }, [])



    useEffect(() => {
        if (UserSession.getSession() !== null) {
            const session_token = UserSession?.getSession()?.session_token as string


            Promise.all([getOwnReservations(session_token), coursesTaken(session_token)]).then((values) => {
                setReservations(values[0].data.sort((a: any, b: any) => new Date(a.cours_date).getTime() - new Date(b.cours_date).getTime()))
                setCourseDone(values[1].data.number_courses)
            }).catch((err) => {
                console.log(err)
            })
        }
    }, [])


    const cancelResa = async (reservation: any) => {
        const response = await cancelReservation(UserSession.getSession()?.session_token as string, reservation.cours_id);
        if (response.success) {
            session?.incrementCredits()
            toast({
                title: "Réservation annulée",
                description: "Votre réservation a bien été annulée",
                status: "success",
                duration: 2000,
                isClosable: true,
            })
            onClose()

            setReservations(reservations.filter((resa: any) => resa.cours_id !== reservation.cours_id))
        } else {
            toast({
                title: "Erreur",
                description: errorMessage(response.data.error_code),
                status: "error",
                duration: 2000,
                isClosable: true,
            })
        }
    }

    const getNbOfActiveReservations = () => {
        let length = 0

        reservations.map((reservation) => {
            if (reservation.status === "active")
                length++
        })

        return length
    }

    const setReservationFilter = (value) => {
        if (value === "all") {
            setShowOldCourses(true)
            setShowFutureCourses(true)
        } else if (value === "futureReservations") {
            setShowOldCourses(false)
            setShowFutureCourses(true)
        } else {
            setShowOldCourses(true)
            setShowFutureCourses(false)
        }
    }

    return (
        <MainContainer>
            <Text fontSize="3xl" color="white" fontWeight="bold">
                Mes réservations
            </Text>
            <Stack spacing='4' direction='row'>
                <Box border='2px solid #1A1E23' w='xs' borderRadius="10px" mt='1.5rem'>
                    <Box p='4'>
                        <IconButton aria-label="icon" isActive icon={<Check />} cursor='default' variant='' border='2px solid #1A1E23' />
                    </Box>
                    <Text fontSize='3xl' px='4'>{`${coursesDone}`}</Text>
                    <Text fontSize='sm' color='#738296' px='4' pb='4'>Cours effectués</Text>
                </Box>
                <Box border='2px solid #1A1E23' w='xs' borderRadius="10px" mt='1.5rem'>
                    <Box p='4'>
                        <IconButton aria-label="icon" isActive icon={<Clock />} cursor='default' variant='' border='2px solid #1A1E23' />
                    </Box>
                    <Text fontSize='3xl' px='4'>{getNbOfActiveReservations()}</Text>
                    <Text fontSize='sm' color='#738296' px='4' pb='4'>Réservations à venir</Text>
                </Box>
            </Stack>
            <Box marginTop="1.5rem" border='2px solid #1A1E23' borderRadius='10px'>
                {(reservations.length > 0 &&
                    (
                        <>
                            <Stack direction='row' spacing='4' ml='5' mt='3' alignItems='center'>
                                <Text fontSize="xl" color="white" fontWeight="bold">
                                    Réservations
                                </Text>
                                <Menu closeOnSelect={true}>
                                    <MenuButton as={Button} leftIcon={<Filter />} colorScheme="gray" variant='solid' border='1px solid #252B32' backgroundColor='#1A1E23' color='#FFFFFF'>
                                        Filtres
                                    </MenuButton>
                                    <MenuList minWidth='240px' zIndex='9999'>
                                        <MenuOptionGroup defaultValue='futureReservations' title='Réservations' type='radio' onChange={(value) => setReservationFilter(value as string)}>
                                            <MenuItemOption value='all'>Toutes</MenuItemOption>
                                            <MenuItemOption value='futureReservations'>Futures</MenuItemOption>
                                            <MenuItemOption value='ancientReservations'>Anciennes</MenuItemOption>
                                        </MenuOptionGroup>
                                    </MenuList>
                                </Menu>
                                {/* <Text>Légende :</Text> */}
                            </Stack>
                            <Grid gap='4' px='4' pb='4' mt='1rem' templateColumns="repeat(auto-fit, minmax(200px, 300px))">
                                {reservations.map((reservation: any, index: number) => {
                                    const hours = Math.floor(reservation.cours_duree / 60)
                                    const minutes = reservation.cours_duree % 60

                                    // Show only old courses
                                    if (showOldCourses && !showFutureCourses) {
                                        if (reservation.reservation_status === "old") {
                                            return (
                                                <GridItem border='2px solid #252B32' key={index} borderRadius="10px" bg='darkGrey.800' w='100%'>
                                                    <Box px='4' pt='4' display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
                                                        <Text fontSize='2xl' mt='0.30rem' fontWeight={600}>{reservation.cours_name}</Text>
                                                    </Box>
                                                    <Box fontSize='2xl' px='4'>
                                                        <Text as='p' fontSize={16} color='orange.500'>{new Date(new Date(reservation.cours_date).getTime() - 7200000).toLocaleDateString('fr-FR', {
                                                            weekday: 'long',
                                                            year: 'numeric',
                                                            month: 'long',
                                                            day: 'numeric',
                                                            hour: 'numeric',
                                                            minute: 'numeric',
                                                        })}</Text>
                                                        <Text as='p' color='#B3BBC6' fontSize={18}>Durée : {hours} {hours > 1 ? 'heures' : 'heure'} {minutes > 0 ? minutes : ""} {minutes > 0 ? minutes > 1 ? 'minutes' : 'minute' : ""}</Text>
                                                    </Box>
                                                    <Text fontSize='sm' color='#738296' px='4' pb='4'>{`${reservation.teacher_name} | ${reservation.salle}`}</Text>
                                                </GridItem>
                                            )
                                        }
                                    } else if (!showOldCourses && showFutureCourses) {
                                        if (reservation.reservation_status === "active") {
                                            return (
                                                <GridItem border='2px solid #252B32' key={index} borderRadius="10px" bg='darkGrey.800' w='100%'>
                                                    <Box px='4' pt='4' display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
                                                        <Text fontSize='2xl' mt='0.30rem' fontWeight={600}>{reservation.cours_name}</Text>
                                                        <IconButton aria-label="more" icon={<Trash />} variant='' _hover={{ color: '#738296' }} color='red.400' onClick={onOpen} />
                                                        <Popup closeLabel="Annuler" close={onClose} validateLabel="Supprimer la réservation" validate={() => cancelResa(reservation)} isVisible={isOpen} title="Annuler la réservation">
                                                        </Popup>
                                                    </Box>
                                                    <Box fontSize='2xl' px='4'>
                                                        <Text as='p' fontSize={16} color='blue.500'>{new Date(new Date(reservation.cours_date).getTime() - 7200000).toLocaleDateString('fr-FR', {
                                                            weekday: 'long',
                                                            year: 'numeric',
                                                            month: 'long',
                                                            day: 'numeric',
                                                            hour: 'numeric',
                                                            minute: 'numeric',
                                                        })}</Text>
                                                        <Text as='p' color='#B3BBC6' fontSize={18}>Durée : {hours} {hours > 1 ? 'heures' : 'heure'} {minutes > 0 ? minutes : ""} {minutes > 0 ? minutes > 1 ? 'minutes' : 'minute' : ""}</Text>
                                                    </Box>
                                                    <Text fontSize='sm' color='#738296' px='4' pb='4'>{`${reservation.teacher_name} | ${reservation.salle}`}</Text>
                                                </GridItem>
                                            )
                                        }
                                    } else if (showOldCourses && showFutureCourses) {
                                        if (reservation.reservation_status !== "cancelled") {
                                            return (
                                                <GridItem border='2px solid #252B32' key={index} borderRadius="10px" bg='darkGrey.800' w='100%'>
                                                    <Box px='4' pt='4' display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
                                                        <Text fontSize='2xl' mt='0.30rem' fontWeight={600}>{reservation.cours_name}</Text>
                                                        {reservation.reservation_status === "active" ?
                                                            <>
                                                                <IconButton aria-label="more" icon={<Trash />} variant='' _hover={{ color: '#738296' }} color='red.400' onClick={onOpen} />
                                                                <Popup closeLabel="Annuler" close={onClose} validateLabel="Supprimer la réservation" validate={() => cancelResa(reservation)} isVisible={isOpen} title="Annuler la réservation">
                                                                </Popup>
                                                            </>
                                                            : null}
                                                    </Box>
                                                    <Box fontSize='2xl' px='4'>
                                                        <Text as='p' fontSize={16} color={reservation.reservation_status === "old" ? 'orange.500' : 'blue.500'}>{new Date(new Date(reservation.cours_date).getTime() - 7200000).toLocaleDateString('fr-FR', {
                                                            weekday: 'long',
                                                            year: 'numeric',
                                                            month: 'long',
                                                            day: 'numeric',
                                                            hour: 'numeric',
                                                            minute: 'numeric',
                                                        })}</Text>
                                                        <Text as='p' color='#B3BBC6' fontSize={18}>Durée : {hours} {hours > 1 ? 'heures' : 'heure'} {minutes > 0 ? minutes : ""} {minutes > 0 ? minutes > 1 ? 'minutes' : 'minute' : ""}</Text>
                                                    </Box>
                                                    <Text fontSize='sm' color='#738296' px='4' pb='4'>{`${reservation.teacher_name} | ${reservation.salle}`}</Text>
                                                </GridItem>
                                            )
                                        }
                                    }
                                })}



                            </Grid>
                        </>
                    )) || (
                        <>
                            <Text fontSize='2xl' margin={"0.5rem"} fontWeight={600}>Aucune réservation</Text>
                        </>
                    )}
            </Box>
        </MainContainer >
    )
}