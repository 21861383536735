import Popup from "@/components/Popup"
import { IconButton, useDisclosure } from "@chakra-ui/react"
import { title } from "process"
import { UserCheck, UserX } from "react-feather"
import Ban from "@/components/icons/Ban"

interface BanProps {
    ban_action: boolean,
    icon_label: string,
    validate: () => void,
    validate_label: string,
    title: string,
    message: string
}


export default function BanUser({ ban_action, icon_label, validate, validate_label, message, title }: BanProps) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <>
            <IconButton aria-label={icon_label} icon={(ban_action) ? <Ban fill="white" /> : <UserCheck stroke="white" />} variant='outline' colorScheme={ban_action ? "yellow" : "green"} onClick={onOpen} maxWidth="75px"/>
            <Popup closeLabel="Annuler" validateLabel={validate_label} close={onClose} validate={() => validate()} isVisible={isOpen} title={title}>
                <p>{message}</p>
            </Popup>
        </>
    )
}