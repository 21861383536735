import { Box, Stack, Text, Image, Button, Link, useDisclosure, useToast } from '@chakra-ui/react';
import UserSession from '@/Classes/UserSession'
import { useState, useEffect } from 'react';
import { requestPass, getUserCards, askForCard } from '@/api';
import Popup from '@/components/Popup';
import { C } from '@fullcalendar/core/internal-common';
import { errorMessage } from '@/utils/errorMessage';
import MainContainer from '@/components/MainContainer';

export default function Cards() {
    const toast = useToast()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [session, setSession] = useState<UserSession | null>(null)
    const [cards, setCards] = useState<any[]>([])
    const [loading, setLoading] = useState(false)
    const [cardAsked, setCardAsked] = useState<boolean>(UserSession.getSession()?.getAskedCard() && !UserSession.getSession()?.getHaveCard() as boolean || false)
    const image_slug = 'https://lce.inno.freepro.com/api/cdn/images/' //TODO: Change this to the real URL

    useEffect(() => {
        setSession(UserSession.getSession() as UserSession | null)
    }, [])

    useEffect(() => {
        if (session !== null) {
            getUserCards(session?.getToken() as string).then(res => {
                if (res.success) {
                    setCards(res.data)
                } else if (res.message === "No cards found") {
                    setCards([])
                }
            })
        }
    }, [session])


    const getPass = async (type: string) => {
        const res = await requestPass(session?.getToken() as string, type)
        if (res.success) {
            if (res.url && type === "google") {
                const a = document.createElement('a')
                a.href = res.url
                a.target = '_blank'
                console.log(a)
                a.click()
            }

            toast({
                title: "Carte ajoutée",
                description: "La carte a été ajoutée dans votre wallet.",
                status: "success",
                duration: 3000,
                isClosable: true,
            })
        } else {
            toast({
                title: "Ajout impossible",
                description: "Une erreur est survenue lors de la génération du pass",
                status: "error",
                duration: 3000,
                isClosable: true,
            })
        }

    }

    const chooseCard = async () => {
        setLoading(true)
        const res = await askForCard(session?.getToken() as string)

        if (res.success) {
            setCardAsked(true)
            session?.setAskedCard(true)
            setLoading(false)
            onClose()
            toast({
                title: "Demande envoyée",
                description: "L'équipe RH prendra contact avec vous quand votre carte d'accès sera prête.",
                status: "success",
                duration: 3000,
                isClosable: true,
            })
        } else {
            setLoading(false)
            onClose()
            toast({
                title: "Demande impossible",
                description: errorMessage(res.data.error_code),
                status: "error",
                duration: 3000,
                isClosable: true,
            })
        }
    }

    return (
        <MainContainer>
            <Text fontSize="3xl" color="white" fontWeight="bold" mb="2rem">
                Mes cartes
            </Text>

            <Box mt="2rem">
                <Stack spacing="2rem" direction="row" flexWrap="wrap">
                    {cards?.length > 0 ? cards.map((card, index) => {
                        return (
                            <Box key={index} w="300px" h="200px" bg="rgb(50,50,50)" borderRadius="1rem" p="1rem">
                                <Text fontSize="xl" fontWeight="bold" mb="1rem">
                                    Carte {card.card_type}
                                </Text>
                                <Text fontSize="md" fontWeight="bold" mb="1rem">
                                    {card.card_uid}
                                </Text>
                            </Box>
                        )
                    }) :
                        <Box border="1px solid rgb(150,150,150)" borderRadius="1rem" p="1rem" mb={"2rem"}>
                            <Text fontSize="xl" fontWeight="bold">
                                Vous n'avez pas encore de carte
                            </Text>
                        </Box>
                    }
                </Stack>
            </Box>

            {/* <Text fontSize="xl" color="white" fontWeight="bold" mt="2rem" mb="1rem">
                Ajouter une carte virtuelle
            </Text>

            <Stack spacing='4' direction='row' flexWrap={'wrap'}>
                <Box>
                    <Text>
                        Pour Apple :
                    </Text>
                    <Link onClick={() => getPass("apple")} isExternal>
                        <Image src={`${image_slug}/addToAppleWallet.svg`} h={50} />
                    </Link>
                </Box>
                <Box>

                    <Text>
                        Pour Android :
                    </Text>
                    <Link onClick={() => getPass("google")} isExternal>
                        <Image src={`${image_slug}/addToGoogleWallet.svg`} h={50} />
                    </Link>
                </Box>
            </Stack> */}
            {
                ((cards?.filter(card => card.card_type === "physique").length === 0 && !cardAsked) &&
                    <Button colorScheme="blue" onClick={() => onOpen()} variant="primary" mt={"1rem"}>
                        Je demande une carte physique
                    </Button>
                )
            }

            {cardAsked &&
                <Text fontSize="lg" fontWeight="light">
                    Une demande de carte physique est en cours de traitement. <br /> Vous recevrez un mail dans les prochains jours.
                </Text>
            }



            {/* {
                (cards.filter(card => card.card_type === "physique").length === 0 &&
                    <Button colorScheme="blue" onClick={() => onOpen()} variant="primary" mt={"1rem"}>
                        Je préfère une carte physique
                    </Button>
                )
            } */}



            <Popup validateLabel="Oui je suis sûr" loading={loading} closeLabel="Annuler" close={onClose} validate={() => chooseCard()} isVisible={isOpen} title="Êtes vous sur de vouloir une carte physique ?">
                <p>
                    En demandant une carte physique, vous pourrez toujours profiter des cartes virtuelles. La demande de carte physique sera envoyée aux RH et vous recevrez un mail dans les prochains jours.
                </p>
            </Popup>
        </MainContainer >
    )
}