import {
	Box,
	IconButton,
	Stack,
	Text,
	Input,
	FormControl,
	FormLabel,
	Select,
	UnorderedList,
	ListItem,
	Button,
	useToast,
	useDisclosure,
	Skeleton
} from "@chakra-ui/react";
import FullCalendar from '@fullcalendar/react'
import frLocale from '@fullcalendar/core/locales/fr'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction';
import { useEffect, useRef, useState } from "react";
import { addCourse, endDateMembership, getSalles, getSelfBalance, getSports, updateCourse, getTeachers, refillCredits, reserveCourse, userCourses, countDemands, cancelCourse, countPremiumUsers, countCoursUsers, countSalleUsers, cancelReservation, subscribeToGym } from "@/api";
import UserSession from "@/Classes/UserSession";
import PopupForm from "../../components/PopupForm";
import Popup from "../../components/Popup";
import React from "react";
import { AlertCircle, ArrowRight, CheckCircle, CreditCard, Crosshair, DollarSign, FilePlus, Plus, PlusCircle, PlusSquare, User, UserPlus, XOctagon } from "react-feather";
import './dashboard.scss'
import Widget from "./Widget";
import { useNavigate } from "react-router-dom";
import { errorMessage } from "@/utils/errorMessage";
import { resiliateOwnSubscription } from "@/api";
import MainContainer from "@/components/MainContainer";


export default function Dashboard() {
	const toast = useToast()
	const { isOpen: isOpenRefill, onOpen: onOpenRefill, onClose: onCloseRefill } = useDisclosure()
	const { isOpen: isOpenResiliation, onOpen: onOpenResiliation, onClose: onCloseResiliation } = useDisclosure()
	const { isOpen: isOpenSubscribe, onOpen: onOpenSubscribe, onClose: onCloseSubscribe } = useDisclosure()
	const { isOpen: isOpenConfirm, onOpen: onOpenConfirm, onClose: onCloseConfirm } = useDisclosure()
	const [courses, setCourses] = useState<any>([])
	const [formattedCourses, setFormattedCourses] = useState<any>([])
	const [session, setSession] = useState<UserSession | null>(null)
	const [courseName, setCourseName] = useState('')
	const [courseDate, setCourseDate] = useState('')
	const [courseDuration, setCourseDuration] = useState(0)
	const [courseTeacher, setCourseTeacher] = useState('')
	const [courseSport, setCourseSport] = useState(0)
	const [courseMaxParticipants, setCourseMaxParticipants] = useState(0)
	const [courseRoom, setCourseRoom] = useState(0)
	const [popupCourses, setPopupCourses] = useState(false)
	const [profs, setProfs] = useState([])
	const [sports, setSports] = useState<any>([])
	const [rooms, setRooms] = useState([])
	const [popupVisible, setPopupVisible] = useState(false)
	const [popupResaVisible, setPopupResaVisible] = useState(false)
	const [popupMessage, setPopupMessage] = useState('')
	const [reservationCoursId, setReservationCoursId] = useState(0)
	const [popupEditVisible, setPopupEditVisible] = useState(false)
	const [popupValidateDelete, setPopupValidateDelete] = useState(false)
	const [loading, setLoading] = useState(true)
	const [editCourseId, setEditCourseId] = useState(0)
	const [editCourseDate, setEditCourseDate] = useState('')
	const [editCourseDuration, setEditCourseDuration] = useState(0)
	const [editCourseTeacher, setEditCourseTeacher] = useState('')
	const [editCourseSport, setEditCourseSport] = useState(0)
	const [editCourseRoom, setEditCourseRoom] = useState(0)
	const [editCourseName, setEditCourseName] = useState('')
	const [editCourseMaxParticipants, setEditCourseMaxParticipants] = useState(0)
	const [haveResiliation, setHaveResiliation] = useState(false)

	const [endOfMembership, setEndOfMembership] = useState<string | null>(null)
	const [currentDemands, setCurrentDemands] = useState<number>(0)
	const [courseParticipants, setCourseParticipants] = useState([])
	const [coursUserCount, setCoursUserCount] = useState(0)
	const [salleUserCount, setSalleUserCount] = useState(0)
	const [premiumUserCount, setPremiumUserCount] = useState(0)
	const [hasMembership, setHasMembership] = useState(true)

	const navigate = useNavigate()



	const calendarRef = useRef<any>(null)
	const initialRef = React.useRef(null)
	const finalRef = React.useRef(null)

	const [creditsLeft, setCreditsLeft] = useState(0)
	const [isSubscribed, setIsSubscribed] = useState(false)
	const [confirmValue, setConfirmValue] = useState("")
	const [confirmValueError, setConfirmValueError] = useState(false)

	useEffect(() => {
		const fetchCredits = async () => {
			const credits = await getSelfBalance(UserSession.getSession()?.session_token as string)
			if (credits?.success) {
				setIsSubscribed(true)
			}
			setCreditsLeft(credits?.data.balance as number || 0)
		}
		fetchCredits()

		const fetchDemands = async () => {
			const response = await countDemands(UserSession.getSession()?.session_token as string)
			setCurrentDemands(response.data.demands)
		}
		fetchDemands()
	}, [])

	const refill = async () => {
		const res = await refillCredits(UserSession.getSession()?.getToken() as string)

		if (res.success) {
			toast({
				title: "Crédits ajoutés",
				description: "Vous avez rechargé votre compte avec succès",
				status: "success",
				duration: 9000,
				isClosable: true,
			})
			setCreditsLeft(res?.data?.balance - courses.filter((cours: { reserved: any; }) => cours.reserved).length)
			onCloseRefill()
		} else {
			toast({
				title: "Rechargement impossible",
				description: errorMessage(res.data.error_code),
				status: "error",
				duration: 9000,
				isClosable: true,
			})
		}

	}

	useEffect(() => {
		if (calendarRef.current) {
			const now = new Date();
			const currentTime = now.getHours() + ':' + now.getMinutes();
			calendarRef.current.getApi().scrollToTime(currentTime);
		}
	}, [])

	useEffect(() => {
		const sess = UserSession.getSession()
		if (sess !== null) {
			setSession(sess)
			setHaveResiliation(sess.have_resiliation)
		}
	}, [])

	useEffect(() => {
		if (session !== null) {
			const token = session?.getToken()
			if (session?.getRole() === "collaborateur") {
				Promise.all([endDateMembership(token), userCourses(token)]).then((res) => {
					setEndOfMembership(res[0]?.data?.endDate ? new Date(res[0]?.data?.endDate).toLocaleDateString("fr-FR") as string : null)
					setCourses(res[1].data)
				})
				setLoading(false)
			} else {
				Promise.all([getSports(token), getTeachers(token), getSalles(token), userCourses(token), countPremiumUsers(token), countSalleUsers(token), countCoursUsers(token)]).then((res) => {
					setSports(res[0].data)
					setProfs(res[1].data)
					setRooms(res[2].data)
					setCourses(res[3].data)
					setPremiumUserCount(res[4].data.count)
					setCoursUserCount(res[5].data.count)
					setSalleUserCount(res[6].data.count)
					setLoading(false)
				})
			}
		}
	}, [session])

	useEffect(() => {
		setHasMembership(endOfMembership !== null)
	}, [endOfMembership])

	async function reserve(courseId: number) {
		if (session !== null) {
			const token = session?.getToken()
			const res = await reserveCourse(token, courseId)

			if (res.success) {
				toast({
					title: "Cours réservé",
					description: "Le cours a bien été réservé",
					status: "success",
					duration: 5000,
					isClosable: true,
				})
				session.decrementCredits()
				setCourses(prevCourses => {
					return prevCourses.map(course => {
						if (course.id === courseId) {
							return { ...course, reserved: true, nombre_reservation: course.nombre_reservation + 1 };
						}
						return course;
					});
				});
				setCreditsLeft(creditsLeft - 1)
				console.log(creditsLeft)
			} else {
				toast({
					title: "Réservation impossible",
					description: errorMessage(res.data.error_code),
					status: "warning",
					duration: 5000,
					isClosable: true,
				})
			}
		}
	}

	async function deleteCourse(courseId: number) {
		if (session !== null) {
			const token = session?.getToken()
			const res = await cancelCourse(token, courseId)
			setPopupValidateDelete(false)
			setPopupEditVisible(false)
			if (res.success) {
				toast({
					title: "Cours supprimé",
					description: "Le cours a bien été supprimé",
					status: "success",
					duration: 5000,
					isClosable: true,
				})
				setCourses(courses.filter((course: any) => course.id !== courseId))
			} else {
				toast({
					title: "Cours non supprimé",
					description: errorMessage(res.data.error_code),
					status: "warning",
					duration: 5000,
					isClosable: true,
				})
			}
		}
	}

	useEffect(() => {
		const fc = courses.map((course: any) => {
			const startDate = new Date(course.date_start);
			const timezoneOffset = startDate.getTimezoneOffset(); //(new Date()).getTimezoneOffset();

			startDate.setMinutes(startDate.getMinutes() + timezoneOffset);
			const durationInMinutes = course.duree;
			const endDate = new Date(startDate.getTime() + durationInMinutes * 60000);

			return {
				id: course.id,
				title: course.name + ' | ' + course.nombre_reservation + '/' + (course.max_participants !== null ? course.max_participants : '0'),
				start: startDate,
				end: endDate,
				allDay: false,
				backgroundColor: course.reserved && course.status === "available" ? '#094530' : (course.status === 'available' ? '#347399' : course.status === 'old' ? '#a0a0a0' : '#661020'),
				extendedProps: {
					status: course.status,
					name: course.name,
					coach: course.teacher_name,
					coach_id: course.teacher_id,
					sport: course.sport_name,
					sport_id: course.sport_id,
					salle: course.salle,
					salle_id: course.salle_id,
					participants: course.participants,
					max_participants: course.max_participants,
				}
			};
		});

		setFormattedCourses(fc)
	}, [courses, session])

	async function createCourse(date_start: string, duree: number, name: string, prof_id: string, sport_id: number, salle_id: number) {
		const res = await addCourse(session?.getToken() as string, name, date_start, prof_id, salle_id, sport_id, duree, 10)
		if (res.success) {

			const course_data = {
				id: res.data.cours_id,
				date_start: new Date(parseInt(date_start) * 1000).getTime() - ((new Date()).getTimezoneOffset() * 60000),
				duree: duree,
				name: name,
				teacher_id: prof_id,
				status: 'available',
				nombre_reservation: 0,
				max_participants: 10,
				sport_name: sports.find((sport: any) => sport.id === sport_id)?.sport_name as string,
				sport_id: sport_id,
				salle_id: salle_id,

			}
			setCourses([...courses, course_data])

			toast({
				title: "Cours ajouté",
				description: "Le cours a bien été ajouté",
				status: "success",
				duration: 3000,
				isClosable: true,
			})
		} else {
			toast({
				title: "Cours non ajouté",
				description: res.message,
				status: "error",
				duration: 3000,
				isClosable: true,
			})
		}
	}


	async function cancelResa(courseId: number) {
		const res = await cancelReservation(session?.getToken() as string, courseId)
		if (res.success) {
			toast({
				title: "Réservation annulée",
				description: "La réservation a bien été annulée",
				status: "success",
				duration: 3000,
				isClosable: true,
			})
			session.incrementCredits()
			setCourses(prevCourses => {
				return prevCourses.map(course => {
					if (course.id === courseId) {
						return { ...course, reserved: false, nombre_reservation: course.nombre_reservation - 1 };
					}
					return course;
				});
			});
			setCreditsLeft(creditsLeft + 1)
		} else {
			toast({
				title: "Annulation impossible",
				description: errorMessage(res.data.error_code),
				status: "warning",
				duration: 3000,
				isClosable: true,
			})
		}
	}



	async function fetchUpdateCourse(courseId: number) {
		const teacherId = editCourseTeacher || "null"
		const roomId = editCourseRoom || 0
		const sportId = editCourseSport || 0



		const res = await updateCourse(session?.getToken() as string, courseId, editCourseName, editCourseDate, editCourseDuration, editCourseMaxParticipants, roomId, sportId, teacherId)
		setPopupEditVisible(false)

		if (res.success) {
			toast({
				title: "Cours modifié",
				description: "Le cours a bien été modifié",
				status: "success",
				duration: 3000,
				isClosable: true,
			})
			setCourses(courses.map((course: any) => {
				if (course.id === courseId) {
					console.warn(editCourseDate)
					return {
						...course,
						name: editCourseName,
						date_start: new Date(new Date(editCourseDate).getTime() - ((new Date()).getTimezoneOffset() * 60000)),
						duree: editCourseDuration,
						max_participants: editCourseMaxParticipants,
						salle_id: roomId,
						sport_id: sportId,
						teacher_id: teacherId
					}
				} else {
					return course
				}
			}))
		} else {
			toast({
				title: "Modification impossible",
				description: errorMessage(res.data.error_code),
				status: "warning",
				duration: 3000,
				isClosable: true,
			})
		}
	}

	const resiliate = async () => {
		if (confirmValue !== session.email) {
			setConfirmValueError(true)
			setConfirmValue("")
			return
		}
		setConfirmValueError(false)
		setConfirmValue("")

		const res = await resiliateOwnSubscription(session?.getToken() as string)
		if (res.success) {
			UserSession.getSession()?.resiliate();
			setHaveResiliation(true)
			toast({
				title: "Abonnement résilié",
				description: "Votre abonnement a bien été résilié",
				status: "success",
				duration: 3000,
				isClosable: true,
			})
		} else {
			toast({
				title: "Résiliation impossible",
				description: errorMessage(res.data.error_code),
				status: "warning",
				duration: 3000,
				isClosable: true,
			})
		}
		onCloseConfirm()
	}

	const subscribe = async () => {
		// Changer la route
		const res = await subscribeToGym(session?.getToken() as string)
		if (res.success) {
			toast({
				title: "Abonnement accès salle",
				description: "Votre abonnement a bien été pris en compte",
				status: "success",
				duration: 3000,
				isClosable: true,
			})
		} else {
			toast({
				title: "Abonnement impossible",
				description: errorMessage(res.data.error_code),
				status: "warning",
				duration: 3000,
				isClosable: true,
			})
		}
	}

	return (
		<MainContainer>
			<Box display='flex' justifyContent='space-between'>
				<Text fontSize="3xl" color="white" fontWeight="bold">
					Tableau de bord
				</Text>

			</Box>
			{/* TODO: Onclick */}
			<Stack spacing='8' direction='row' overflowX='auto' maxW='100%'>
				{(session !== null && session?.getRole() === 'collaborateur' && (
					<>
						<Widget loading={loading} info={isSubscribed ? `${creditsLeft}` : 'Aucun abonnement'} text="Crédits" icon={<DollarSign />} button={<Button aria-label="rechargement" variant={'solid'} onClick={() => onOpenRefill()} _hover={{ color: '#738296' }}>Recharger</Button>} />
						<Widget loading={loading} info={courses.filter((cours: { reserved: any; }) => cours.reserved).length} text="Réservations" icon={<CheckCircle />} button={<IconButton variant={'outline'} colorScheme="grey" aria-label="more" icon={<ArrowRight />} onClick={() => navigate('/reservations')} _hover={{ color: '#738296' }} />} />

						{hasMembership ? // Changer ce if
							<Widget loading={loading} info={`${endOfMembership}`} text="Fin d'abonnement" icon={<AlertCircle />} button={!haveResiliation ? <Button variant={'outline'} borderColor="red.500" color="red.500" aria-label="more" rightIcon={<XOctagon />} onClick={onOpenResiliation} _hover={{ color: 'red.300', borderColor: 'red.300' }}> Résilier </Button> : <Text color="green.300" width={'fit-content'} textAlign={"right"}>Résiliation demandée </Text>} />
							: <Widget loading={loading} info="Pas abonné" text="Vous n'avez pas d'accès à la salle" icon={<AlertCircle />} button={<Button variant={'outline'} borderColor="blue.300" color="blue.300" aria-label="more" rightIcon={<CheckCircle />} onClick={onOpenSubscribe} _hover={{ color: 'blue.200', borderColor: 'blue.200' }}> S'abonner </Button>} />}
					</>
				)) || (session !== null && session?.getRole() === 'rh' && (
					<>
						<Widget loading={loading} info={String(currentDemands) as string} text="Demandes de carte" icon={<CreditCard />} />
						<Widget loading={loading} info={String(coursUserCount) as string} text="Accès Cours" icon={<CheckCircle />} />
						<Widget loading={loading} info={String(salleUserCount) as string} text="Accès Salle" icon={<CheckCircle />} />
						<Widget loading={loading} info={String(premiumUserCount) as string} text="Accès Salle + Cours" icon={<User />} />
					</>
				))}
			</Stack>
			<Box w='100%' border='2px solid #1A1E23' borderRadius='10px' mt='2rem' overflowX='auto' maxW='100%' p='1rem'>
				<Text fontSize="2xl" color="white" fontWeight="bold">Calendrier</Text>
				<Skeleton isLoaded={!loading}>
					<Box height='42.5rem' width='100%' mt='1rem'>
						<FullCalendar
							plugins={[timeGridPlugin, interactionPlugin]}
							initialView="timeGridWeek"
							height='100%'
							locale={frLocale}
							timeZone="local"
							allDaySlot={false}
							weekends={false}
							slotMinTime={'09:00:00'}
							slotMaxTime={'20:00:00'}
							businessHours={{
								daysOfWeek: [1, 2, 3, 4, 5], // Monday - Thursday
								startTime: '09:00', // 8am
								endTime: '20:00' // 6pm
							}}
							events={formattedCourses}
							eventClick={(info) => {
								if (session?.getRole() === "collaborateur") {
									setPopupResaVisible(true)
									setReservationCoursId(parseInt(info.event.id))
								} else {
									setPopupEditVisible(true)
									setEditCourseId(parseInt(info.event.id))
									setEditCourseName(info.event.extendedProps.name)

									setEditCourseDate(info.event.startStr.split('+')[0])
									setEditCourseDuration((info.event.end.getTime() - info.event.start.getTime()) / 60000)
									setCourseParticipants(info.event.extendedProps.participants)
									setCourseMaxParticipants(info.event.extendedProps.max_participants)
									setCourseDate(info.event.startStr.split('+')[0])
									setCourseDuration((info.event.end.getTime() - info.event.start.getTime()) / 60000)
									setCourseName(info.event.extendedProps.name)

									setEditCourseTeacher(info.event.extendedProps.coach_id)
									setEditCourseSport(info.event.extendedProps.sport_id)
									setEditCourseRoom(info.event.extendedProps.salle_id)
									setEditCourseMaxParticipants(info.event.extendedProps.max_participants)

									setCourseTeacher(info.event.extendedProps.coach_id)
									setCourseSport(info.event.extendedProps.sport_id)
									setCourseRoom(info.event.extendedProps.salle_id)
								}
							}}
							selectable={session?.getRole() === 'collaborateur' ? false : true}
							editable={session?.getRole() === 'collaborateur' ? false : true}
							select={(info) => {
								setCourseDate(info.startStr)
								setCourseDuration((info.end.getTime() - info.start.getTime()) / 60000)
								setPopupCourses(true)
							}}
							eventDragStop={(info) => {
								console.log(info.event)
							}
							}
							nowIndicator={true}
						/>
					</Box>
				</Skeleton>
			</Box>

			<PopupForm closeLabel='Annuler' validateLabel='Créer' validate={() => { createCourse((new Date(courseDate).getTime() / 1000).toString(), courseDuration, courseName, courseTeacher, courseSport, courseRoom); setPopupCourses(false) }} close={() => setPopupCourses(false)} isVisible={popupCourses} title="Ajouter un cours">
				<FormControl>
					<FormLabel>Nom du cours</FormLabel>
					<Input ref={initialRef} placeholder='Nom du cours' onChange={(e) => setCourseName(e.target.value)} />
				</FormControl>

				<FormControl mt={4}>
					<FormLabel>Date</FormLabel>
					<Input type='datetime-local' defaultValue={courseDate.substring(0, 16)} onChange={(e) => setCourseDate(e.target.value)} />
				</FormControl>

				<FormControl mt={4}>
					<FormLabel>Durée</FormLabel>
					<Input type='number' defaultValue={courseDuration} onChange={(e) => setCourseDuration(parseInt(e.target.value))} />
				</FormControl>

				<FormControl mt={4}>
					<FormLabel>Coach</FormLabel>
					<Select placeholder="--Choisir un coach--" onChange={(e) => setCourseTeacher(e.target.value)}>
						{profs.map((prof: any, index: number) => {
							return <option key={index} value={prof.uuid}>{prof.firstname}</option>
						}
						)}
					</Select>
				</FormControl>

				<FormControl mt={4}>
					<FormLabel>Sport</FormLabel>
					<Select placeholder="--Choisir un sport--" onChange={(e) => setCourseSport(parseInt(e.target.value))}>
						{sports.map((sport: any, index: number) => {
							return <option key={index} value={sport.sport_id}>{sport.sport_name}</option>
						}
						)}
					</Select>
				</FormControl>

				<FormControl mt={4}>
					<FormLabel>Salle</FormLabel>
					<Select placeholder="--Choisir une salle--" onChange={(e) => setCourseRoom(parseInt(e.target.value))}>
						{rooms.map((room: any, index: number) => {
							return <option key={index} value={room.salle_id}>{room.salle_name}</option>
						}
						)}
					</Select>
				</FormControl>
			</PopupForm>

			<Popup closeLabel='Fermer' validateLabel="Ok" validate={() => setPopupVisible(false)} close={() => setPopupVisible(false)} isVisible={popupVisible} title="">
				{popupMessage}
			</Popup>




			<Popup closeLabel='Fermer' validateLabel={courses.find((course: any) => course.id === reservationCoursId)?.reserved ? "Supprimer" : "Réserver"} validate={() => { if (courses.find((course: any) => course.id === reservationCoursId)?.reserved) { cancelResa(reservationCoursId); setPopupResaVisible(false); } else { reserve(reservationCoursId); setPopupResaVisible(false); } }} close={() => setPopupResaVisible(false)} isVisible={popupResaVisible} title="Réservation">
				<Text>{courses.find((course: any) => course.id === reservationCoursId)?.reserved ? "Voulez vous annuler votre réservation ?" : "Vous êtes sur le point de vous inscrire à ce cours"}</Text>
			</Popup>


			<Popup closeLabel='Annuler' validateLabel="Supprimer" validate={() => { deleteCourse(editCourseId) }} close={() => setPopupValidateDelete(false)} isVisible={popupValidateDelete} title="Suppression du cours">
				<Text>
					Vous êtes sur le point de supprimer ce cours. Toutes les réservations seront aussi supprimées.
				</Text>
			</Popup>

			<Popup closeLabel='Annuler' validateLabel="Valider le rechargement" validate={() => { refill() }} close={() => onCloseRefill()} isVisible={isOpenRefill} title="Rechargement du compte">
				<Text>
					Vous êtes sur le point de recharger votre compte. Sachez que vous ne pourrez pas annuler cette action.
					<br />
					Vous serez débité de 40€ contre une recharge de 10 crédits.
				</Text>
				<em style={{ color: "red" }}>Attention, cette action est irréversible !</em>
			</Popup>

			<Popup closeLabel='Annuler' validateLabel="Valider la résiliation" validate={() => { onCloseResiliation(); onOpenConfirm() }} close={onCloseResiliation} isVisible={isOpenResiliation} title="Résiliation de l'abonnement">
				<Text>
					Vous êtes sur le point de résilier votre abonnement.
					<br />
					Vous pourrez continuer à utiliser votre compte normalement jusqu'à la fin de votre abonnement.
					<br />
					Après cela, tout accès à votre compte sera bloqué.
					<br />
					<b>Pour toute questions, veuillez contacter l'équipe RH.</b>
				</Text>
				<em style={{ color: "red" }}>Attention, cette action est irréversible !</em>
			</Popup>

			<Popup closeLabel='Annuler' validateLabel="Valider la résiliation" validate={() => { resiliate() }} close={onCloseConfirm} isVisible={isOpenConfirm} title="Êtes-vous sûr ?">
				<Stack spacing='4'>
					<Text>
						Si vous êtes certain de vouloir résilier votre abonnement, remplissez le champ suivant avec votre adresse email professionnelle.
					</Text>
					<Input placeholder='Adresse email professionnelle' value={confirmValue} onChange={(e) => setConfirmValue(e.target.value)} />
				</Stack>
				{confirmValueError ?
					<Text mt='1' color='red.500'>Erreur, le champ ne correspond pas</Text>
					: null}
			</Popup>


			<Popup closeLabel='Annuler' validateLabel="Valider la souscription" validate={() => { subscribe(); onCloseSubscribe() }} close={onCloseSubscribe} isVisible={isOpenSubscribe} title="Souscription à l'abonnement">
				<Text>
					Vous êtes sur le point de vous abonner pour accéder à la salle de sport.
					<br />
					Vous serez prélevé de 180 euros pour un abonnement d'une année.
					<br />
					<b>Pour toute questions, veuillez contacter l'équipe RH.</b>
				</Text>
				<em style={{ color: "red" }}>Attention, cette action est irréversible !</em>
			</Popup>






			<PopupForm closeLabel='Fermer' validateLabel="Modifier" validate={() => { fetchUpdateCourse(editCourseId); }} close={() => setPopupEditVisible(false)} isVisible={popupEditVisible && UserSession.getSession().getRole() !== "collaborateur"} title={`Modification du cours numéro ${editCourseId}`}>
				<FormControl>
					<FormLabel>Nom du cours</FormLabel>
					<Input ref={initialRef} placeholder='Nom du cours' defaultValue={editCourseName} onChange={(e) => setEditCourseName(e.target.value)} />
				</FormControl>

				<FormControl mt={4}>
					<FormLabel>Date</FormLabel>
					<Input type='datetime-local' defaultValue={editCourseDate} onChange={(e) => setEditCourseDate(e.target.value)} />
				</FormControl>

				<FormControl mt={4}>
					<FormLabel>Durée</FormLabel>
					<Input type='number' defaultValue={editCourseDuration} onChange={(e) => setEditCourseDuration(parseInt(e.target.value))} />
				</FormControl>

				<FormControl mt={4}>
					<FormLabel>Coach</FormLabel>
					<Select onChange={(e) => setEditCourseTeacher(e.target.value)} defaultValue={editCourseTeacher}>
						<option value={null}>--Choisir un coach--</option>
						{profs?.map((prof: any, index: number) => {
							return <option key={index} value={prof.uuid}>{prof.firstname}</option>
						}
						)}
					</Select>
				</FormControl>

				<FormControl mt={4}>
					<FormLabel>Sport</FormLabel>
					<Select onChange={(e) => setEditCourseSport(parseInt(e.target.value))} defaultValue={editCourseSport}>
						{sports?.map((sport: any, index: number) => {
							return <option key={index} value={sport.sport_id}>{sport.sport_name}</option>
						}
						)}
					</Select>
				</FormControl>

				<FormControl mt={4}>
					<FormLabel>Salle</FormLabel>
					<Select onChange={(e) => setEditCourseRoom(parseInt(e.target.value))} defaultValue={editCourseRoom}>
						{rooms?.map((room: any, index: number) => {
							return <option key={index} value={room.salle_id}>{room.salle_name}</option>
						}
						)}
					</Select>
				</FormControl>
				<FormControl mt={4}>
					<FormLabel>Nombre de participants max</FormLabel>
					<Input type='number' defaultValue={String(courseMaxParticipants)} onChange={(e) => setEditCourseMaxParticipants(parseInt(e.target.value))} />
				</FormControl>
				<Box mt={4}>
					<Text fontWeight={600} fontSize={"1.2rem"}>Liste des inscrits</Text>
					{(courseParticipants?.length > 0 &&
						(
							<>
								<UnorderedList mt={2}>
									{courseParticipants?.map((user, index: number) => {
										return (
											<ListItem key={index}>{user.firstname} {user.lastname}</ListItem>
										)
									})}
								</UnorderedList>
							</>
						)
					) || <Text>Aucun inscrit</Text>}
				</Box>

				<Button mt={4} onClick={() => { setPopupValidateDelete(true) }} colorScheme="red" variant={"solid"} bg={"red.400"} textColor={"white"}>Supprimer le cours</Button>
			</PopupForm>
		</MainContainer >

	)
}