import { useState, useEffect } from 'react'
import { updatePassword } from '../api'
import sha256 from 'crypto-js/sha256'
import UserSession from '../Classes/UserSession'
import BrandLogo from "@/components/Brand";

import {
    Box,
    Text,
    Image,
    Button,
    Input,
    Spinner
} from "@chakra-ui/react";



export default function FirstLogin() {
    const [session, setSessionState] = useState<UserSession | null>(null)
    const [password, setPassword] = useState('')
    const [password2, setPassword2] = useState('')
    const [isSame, setIsSame] = useState(false)
    const [isPasswordValid, setIsPasswordValid] = useState(false)
    const [isPassword2Valid, setIsPassword2Valid] = useState(false)
    const [loading, setLoading] = useState(false)
    const image_slug = "https://lce.inno.freepro.com/api/cdn/images"


    useEffect(() => {
        const sess = UserSession.getSession()
        if (sess !== null) {
            setSessionState(sess)
        }
    }, [])

    useEffect(() => {
        if (password === password2) {
            setIsSame(true)
        } else {
            setIsSame(false)
        }
    }, [password, password2])

    useEffect(() => {
        if (password.length >= 8) {
            setIsPasswordValid(true)
        } else {
            setIsPasswordValid(false)
        }
    }, [password])

    useEffect(() => {
        if (password2.length >= 8) {
            setIsPassword2Valid(true)
        } else {
            setIsPassword2Valid(false)
        }
    }, [password2])

    async function update() {
        setLoading(true)
        const res = await updatePassword(session?.getToken() as string, password)
        if (res.success) {
            session?.setFirstLogin(false)
            session?.setSession(session as UserSession)

            window.location.href = '/'
        }
    }

    return (
        <Box flex='1' display='flex' flexDirection='row' onKeyUp={(e) => {
            if (e.key === 'Enter') {
                update()
            }
        }}>
            <Box flex='1' h='100vh' display='flex' alignItems='center' justifyContent='center'>
                <Box display='flex' justifyContent='center' flexDirection='column' p={window.innerWidth > 500 ? '0' : '4'} w='25rem'>
                    <BrandLogo />
                    <br /><br />
                    <Text fontSize='2xl' fontWeight='bold'>Première connexion</Text>
                    <Text fontSize='md' color='#738296'>Centre d'entrainement</Text>
                    <br />
                    <Text fontSize='md' color='#919CAC' className='entries'>
                        <span>Nouveau mot de passe</span>
                        <span style={{ color: 'red' }}>*</span>
                    </Text>
                    <Input
                        type="password"
                        onChange={(e) => setPassword(sha256(e.target.value).toString())}
                        h='10'
                        mt='1'
                    />
                    <Text fontSize='md' color='#919CAC' className='entries' id='password' mt='4'>
                        <span>Comfirmez le mot de passe</span>
                        <span style={{ color: 'red' }}>*</span>
                    </Text>
                    <Input
                        type="password"
                        onChange={(e) => setPassword2(sha256(e.target.value).toString())}
                        mt='1'
                        h='10'
                    />
                    <Button isLoading={loading || !isSame || !isPasswordValid || !isPassword2Valid} loadingText={!isSame ? 'Vérification' : loading ? 'Mise à jour' : ''} spinner={loading ? <Spinner speed='1s' /> : <></>} variant='primary' mt='6' onClick={() => update()}>Valider</Button>
                </Box>
            </Box>
            {window.innerWidth > 500 ?
                <Box flex='1' display='flex' alignItems='center' justifyContent='center'>
                    <Image width='90%' height='90%' borderRadius='30px' objectFit={'cover'} src={`${image_slug}/bg_home.png`} />
                </Box>
                : null}
        </Box>
    )
}