import { Box, IconButton, IconProps, Text, Skeleton } from "@chakra-ui/react";
import { ReactElement } from "react";
import { DollarSign } from "react-feather";

type WidgetProps = {
    info: string
    text: string
    icon: ReactElement
    button?: ReactElement
    loading?: boolean | false
}

export default function Widget(props: WidgetProps) {
    const { info, text, icon, button } = props

    return (
        <Box border='2px solid #1A1E23' w='xs' borderRadius="10px" mt='1.5rem' minW={200} maxW={300}>
            <Box p='4' display='flex' justifyContent='space-between'>
                <IconButton aria-label="icon" isActive icon={icon} cursor='default' variant='' border='2px solid #1A1E23' />
                {button !== undefined ?
                    button
                    : null}
            </Box>
            <Skeleton isLoaded={!props.loading} width="2rem" ml={"1rem"}>
                <Text fontSize='3xl' px='4' ml="-1rem" width={"fit-content"}>{info}</Text>
            </Skeleton>
            <Text fontSize='sm' color='#738296' px='4' pb='4'>{text}</Text>
        </Box>
    )
}