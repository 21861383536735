import { addCard, deviceList, cancelCardAssocation } from "@/api"
import { IconButton, useDisclosure, useToast } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { CreditCard } from "react-feather"
import Popup from "../../components/Popup"
import UserSession from "@/Classes/UserSession"
import { errorMessage } from "@/utils/errorMessage"

export default function AssociateCard({ userId, returnBool, iconColor, buttonMode }: { userId: string, returnBool: (bool: boolean, uuid: string) => void, iconColor: string, buttonMode: "ghost" | "solid" | "outline" | "link" }) {
    const toast = useToast()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [devices, setDevices] = useState([]);
    const [selectedUser, setSelectedUser] = useState("");
    const [selectedDevice, setSelectedDevice] = useState("");
    const [token, setToken] = useState("")
    const [waiting, setWaiting] = useState(false);

    useEffect(() => {
        const session_token = UserSession?.getSession()?.session_token as string;
        setToken(session_token);
    }, []);


    useEffect(() => {
        if (token !== "") {
            deviceList(token).then((res) => {
                if (res.success) {
                    setDevices(res.data);
                } else {
                    toast({
                        title: "Erreur lors de la récupération des appareils",
                        status: 'error',
                        description: errorMessage(res.data.error_code),
                        duration: 3000,
                        isClosable: true,
                    })
                }

            }
            ).catch((err) => {
                console.log(err);
            }
            );
        }
    }, [token]);

    const handleCallback = (bool: boolean, uuid: string) => {
        returnBool(bool, uuid)
    }

    const handleCancelAssociation = async () => {
        if (selectedDevice !== "") {
            if (waiting) {
                const res = await cancelCardAssocation(token, selectedDevice)
                if (res.success) {
                    setWaiting(false)
                    onClose()
                    setSelectedDevice("")
                    setSelectedUser("")
                    toast({
                        title: "Association annulée avec succès",
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                    })
                    handleCallback(false, "")
                } else {
                    setWaiting(false)
                    toast({
                        title: "Erreur lors de l'annulation",
                        status: 'error',
                        description: errorMessage(res.data.error_code),
                        duration: 3000,
                        isClosable: true,
                    })
                    handleCallback(false, "")
                }
            } else {
                handleCallback(false, "")
            }
        } else {
            toast({
                title: "Aucun appareil n'est sélectionné",
                status: "error",
                duration: 3000,
                isClosable: true,
            })
            handleCallback(false, "")
        }
    };


    useEffect(() => {
        setSelectedUser(userId)
        setSelectedDevice(devices[0]?.device_id as string || "")
    }, [userId, devices])

    const handleAdd = async () => {
        if (selectedUser !== "" && selectedDevice !== "") {
            setWaiting(true)
            const res = await addCard(token, selectedDevice, selectedUser)
            if (res.success) {
                setWaiting(false)
                onClose()
                handleCallback(true, selectedUser)
                setSelectedDevice("")
                setSelectedUser("")
                toast({
                    title: "Carte ajoutée avec succès",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                })
            } else {
                setWaiting(false)
                toast({
                    title: "Erreur lors de l'ajout de la carte",
                    status: 'error',
                    description: errorMessage(res.data.error_code) + parseInt(res.data.error_code) == 29 ? " (la carte à bien été associée mais l'envoi du mail a échoué)" : "",
                    duration: 3000,
                    isClosable: true,
                })
                handleCallback(false, "")
            }
        } else {
            toast({
                title: "Veuillez remplir tous les champs",
                status: "error",
                duration: 3000,
                isClosable: true,
            })
            handleCallback(false, "")
        }
    };

    return (
        <>
            <IconButton aria-label="Ajouter une carte" colorScheme="green" title="Ajouter une carte" icon={<CreditCard color={iconColor} strokeWidth={2} />} variant={buttonMode} onClick={onOpen} maxWidth="75px" />


            {(selectedDevice !== "" && selectedUser !== "" && (
                <Popup closeLabel="Annuler" loading={waiting} validateLabel="Ajouter" validate={() => handleAdd()} close={() => { onClose(); handleCancelAssociation() }} isVisible={isOpen} title="Associer une carte">
                    <em style={{ color: "orange" }}>Une fois que vous avez cliqué sur "Ajouter", veuillez poser la carte sur le lecteur</em>
                </Popup>
            )) || (
                    <Popup closeLabel="Retour" loading={waiting} validateLabel="Ok" validate={onClose} close={onClose} isVisible={isOpen} title="Erreur">
                        <em style={{ color: "orange" }}>
                            Aucun appareil ou utilisateur n'est disponible pour l'association, veuillez réessayer plus tard ou contacter un administrateur.
                        </em>
                    </Popup>
                )}
        </>
    )
}