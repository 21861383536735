import { Box, BoxProps, Flex, Text } from "@chakra-ui/react";
import { PropsWithChildren } from "react";

type ItemProps = {
	title: string;
};

export default function Item(props: PropsWithChildren<ItemProps & BoxProps>) {
	const { children, title, ...other } = props;
	return (
		<>
			<Flex height="28px" alignItems="center" flexDirection="row" {...other}>
				<Box width="40%">
					<Text color="darkGrey.50">{title}</Text>
				</Box>
				<Box>
					<Text>{children}</Text>
				</Box>
			</Flex>
		</>
	);
}
