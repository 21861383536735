import UserSession from '@/Classes/UserSession';
import { Box, Text, Input, Flex, Avatar, Grid, GridItem, Button, FormControl, FormLabel, FormHelperText, useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { updateSelfUser } from '@/api';
import { set } from 'date-fns';
import { errorMessage } from '@/utils/errorMessage';
import MainContainer from '@/components/MainContainer';

export default function Profile() {
    const toast = useToast();
    const [session, setSession] = useState<UserSession | null>(null);
    const [sessionToJson, setSessionJson] = useState<any>({
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
    })
    const [profilePicture, setProfilePicture] = useState<any>('');
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [hasChanged, setHasChanged] = useState(false);
    const [loading, setLoading] = useState(false);
    const [avatarChanged, setAvatarChanged] = useState(false);
    const [mailValid, setMailValid] = useState(true);
    const [phoneValid, setPhoneValid] = useState(true);
    const [ableToSave, setAbleToSave] = useState(true);
    const avatarSlug = 'https://lce.inno.freepro.com/api/cdn/avatars/' //TODO: Change this to the real URL
    const [avatar, setAvatar] = useState("");

    useEffect(() => {
        if (UserSession?.getSession() != null) {
            setSessionJson(UserSession.getSession()?.getJson())
            setSession(UserSession.getSession());
            setAvatar(avatarSlug + UserSession.getSession()?.getAvatar());
        }
    }, [])

    useEffect(() => {
        setFirstName(sessionToJson?.firstname);
        setLastName(sessionToJson?.lastname);
        setEmail(sessionToJson?.email);
        setPhone(sessionToJson?.phone);
    }, [sessionToJson])


    const handleChanges = () => {
        if (firstName !== sessionToJson?.firstname || lastName !== sessionToJson?.lastname || email !== sessionToJson?.email || phone !== sessionToJson?.phone || avatarChanged) {
            setHasChanged(true);
        } else {
            setHasChanged(false);
        }
    }


    const handleInput = (content: string, type: string) => {
        switch (type) {
            case 'firstname':
                setFirstName(content);
                break;
            case 'lastname':
                setLastName(content);
                break;
            case 'email':
                setEmail(content);
                if (content.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) != null) {
                    setMailValid(true);
                    setAbleToSave(true);
                } else {
                    setMailValid(false);
                    setAbleToSave(false);
                }

                break;
            case 'phone':
                setPhone(content);
                if (content.match(/^((\+)33|0)[1-9](\d{2}){4}$/) != null) {
                    setPhoneValid(true);
                    setAbleToSave(true);
                } else {
                    setPhoneValid(false);
                    setAbleToSave(false);
                }
                break;
        }
    }

    const handleFileChange = (e: any) => {
        const file = e.target.files?.[0];
        if (file) {
            setProfilePicture(file);
            setAvatarChanged(true);
            setAvatar(URL.createObjectURL(file));
        }
    };


    useEffect(() => {
        handleChanges();
    }, [firstName, lastName, email, phone, avatarChanged]);

    const handleSave = async () => {
        try {
            const formData = new FormData();
            if (profilePicture !== '') {
                let fileSize = profilePicture.size / 1024 / 1024; // in MB
                console.log('fileSize :', fileSize);
                if (fileSize > 3) {
                    toast({
                        title: "Modification impossible",
                        description: "Votre image de profil est trop lourde.",
                        status: "error",
                        duration: 2000,
                        isClosable: true,
                    })
                    return;
                }
                formData.append('avatar', profilePicture);
            }
            formData.append('firstname', firstName);
            formData.append('lastname', lastName);
            formData.append('email', email);
            formData.append('phone', phone);


            const res = await updateSelfUser(
                sessionToJson?.session_token,
                formData
            );

            if (res.success) {
                toast({
                    title: "Profil mis à jour",
                    description: "Votre profil a bien été mis à jour.",
                    status: "success",
                    duration: 2000,
                    isClosable: true,
                })

                session.updateInfos({
                    firstname: res.data.firstname,
                    lastname: res.data.lastname,
                    email: res.data.email,
                    phone: res.data.phone,
                    avatar: res.data.avatar,
                })

                setAbleToSave(true);
                setHasChanged(false);
                setAvatarChanged(false);

                setSessionJson(session.getJson());
            } else {
                toast({
                    title: "Modification impossible",
                    description: errorMessage(res.data.error_code),
                    status: "error",
                    duration: 2000,
                    isClosable: true,
                })
            }

        } catch (error) {
            console.log('error :', error);

            toast({
                title: "Modification impossible",
                description: "Une erreur inconnue est survenue lors de la modification de votre profil.",
                status: "error",
                duration: 2000,
                isClosable: true,
            })
        }
    };

    return (
        <MainContainer>
            <Text fontSize="3xl" color="white" fontWeight="bold" mb="2rem">
                Votre profil
            </Text>

            <Flex alignItems="center" mb="2rem">
                <Input type="file" id="file" style={{ display: 'none' }} hidden accept='image/*' onChange={handleFileChange} />
                <Avatar size="xl" name={`${firstName} ${lastName}`} src={avatar} cursor='pointer' onClick={() => { document.getElementById('file')?.click() }} />
                <Box ml="1rem">
                    <Text fontSize="xl">{`${firstName} ${lastName}`}</Text>
                    <Text fontSize="md" color="gray.500" mt="0.5rem">
                        {email}
                    </Text>
                </Box>
            </Flex>

            <Grid
                templateColumns="repeat(auto-fit, minmax(250px, 1fr))"
                gap={"1rem"}
            >
                <FormControl>
                    <FormLabel>
                        <Text fontSize='md' color='#919CAC' className='entries' mb='0.4rem'>
                            <span>Prénom</span>
                            <span style={{ color: 'red' }}>*</span>
                        </Text>
                    </FormLabel>
                    <Input
                        value={firstName}
                        onChange={(e) => {
                            handleInput(e.target.value, 'firstname')
                        }
                        }

                        _placeholder={{ color: '#738296', fontSize: '16px' }}

                    />
                </FormControl>
                <FormControl>
                    <FormLabel>
                        <Text fontSize='md' color='#919CAC' className='entries' mb='0.4rem'>
                            <span>Nom</span>
                            <span style={{ color: 'red' }}>*</span>
                        </Text>
                    </FormLabel>
                    <Input
                        value={lastName}
                        onChange={(e) => {
                            handleInput(e.target.value, 'lastname')
                        }
                        }

                        _placeholder={{ color: '#738296', fontSize: '16px' }}

                    />
                </FormControl>
                <FormControl isInvalid={!mailValid}>
                    <FormLabel>
                        <Text fontSize='md' color='#919CAC' className='entries' mb='0.4rem'>
                            <span>Email</span>
                            <span style={{ color: 'red' }}>*</span>
                        </Text>
                    </FormLabel>
                    <Input
                        value={email}
                        onChange={(e) => {
                            handleInput(e.target.value, 'email')
                        }
                        }
                        _placeholder={{ color: '#738296', fontSize: '16px' }}
                    />
                    {!mailValid && <FormHelperText color='red'>Email invalide</FormHelperText>}
                </FormControl>
                <FormControl isInvalid={!phoneValid}>
                    <FormLabel>
                        <Text fontSize='md' color='#919CAC' className='entries' mb='0.4rem'>
                            <span>Téléphone</span>
                            <span style={{ color: 'red' }}>*</span>
                        </Text>
                    </FormLabel>
                    <Input
                        value={phone}
                        onChange={(e) => {
                            handleInput(e.target.value, 'phone')
                        }
                        }
                        _placeholder={{ color: '#738296', fontSize: '16px' }}
                    />
                    {!phoneValid && <FormHelperText color='red'>Numéro de téléphone invalide</FormHelperText>}
                </FormControl>
            </Grid>

            {/* <Box display='flex' mt='2rem'>
                <Box>
                    <Text fontSize='md' color='#919CAC' className='entries' mb='0.4rem'>
                        <span>Mot de passe</span>
                        <span style={{ color: 'red' }}>*</span>
                    </Text>
                    <Input
                        type='password'
                        value={sessionToJson?.password}
                        //onChange={(e) => setPassword(e.target.value)}
                        
                        _placeholder={{ color: '#738296', fontSize: '16px' }}
                    />
                </Box>
            </Box> */}

            {((hasChanged && ableToSave) && (
                <Button variant="solid" colorScheme="blue" mt="2rem" onClick={handleSave} loadingText="Sauvegarde en cours..." isLoading={loading}>
                    Sauvegarder
                </Button>
            )) || (
                    <Button variant="ghost" colorScheme="blue" mt="2rem" loadingText="Sauvegarder" isLoading={!hasChanged || !ableToSave} spinnerPlacement="end" spinner={<></>} >
                        Sauvegarderddd
                    </Button>
                )}

        </MainContainer>
    )
}
