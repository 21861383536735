import UserSession from "@/Classes/UserSession";
import {
	Avatar,
	Box,
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Stack,
	SystemStyleObject,
	Text,
	Tooltip,
} from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { LogOut, User, Users } from "react-feather";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type SidenavUserProps = {
	collapsed?: boolean;
};

export default function SidenavUserCard(props: SidenavUserProps) {
	const {
		collapsed
	} = props;

	const navigate = useNavigate()
	const { t } = useTranslation();
	const [avatar, setAvatar] = useState("")
	const [session, setSessionState] = useState<UserSession | null>(null)
	const avatarSlug = 'https://lce.inno.freepro.com/api/cdn/avatars/' //TODO: Change this to the real URL

	useEffect(() => {
		const sess = UserSession.getSession()
		if (sess !== null) {
			setSessionState(sess)
			setAvatar(sess.getAvatar())
		}
	}, [])

	useEffect(() => {
		const interval = setInterval(() => {
			if (session !== null) {
				setSessionState(UserSession.getSession())
				setAvatar(session.getAvatar())
			}
		}, 1000);
		return () => clearInterval(interval);
	}, [session]);


	const styles = useMemo(() =>
	({
		transition: "all .2s ease",
		cursor: "pointer",
		py: "4",
		px: collapsed ? "0" : "4",
		borderRadius: "xl",
		_hover: {
			bg: collapsed ? null : "background.hovered",
		},
	} as SystemStyleObject),
		[collapsed]
	);

	const signout = useCallback(() => { UserSession.clearSession(); window.location.href = '/login' }, []);

	return (
		<Stack direction="row" spacing="3" sx={styles}>
			{collapsed ? (
				<Menu>
					<MenuButton>
						<Avatar name={session?.getFirstName()} variant="square" size="md" />
					</MenuButton>
					<MenuList>
						<Box pb="3" px="4" onClick={() => { navigate('/profile'); }}>
							<Text>{session?.getFirstName()}</Text>
							<Text fontSize="sm" color="darkGrey.50">
								{session?.getEmail()}
							</Text>
						</Box>
						<MenuItem icon={<LogOut size="16" />} onClick={signout}>
							{t("Se déconnecter")}
						</MenuItem>
					</MenuList>
				</Menu>
			) : (
				<>
					<Avatar name={session?.getFirstName()} variant="square" size="md" onClick={() => navigate('/profile')} src={`${avatarSlug}${avatar}`} />
					<Box flex="1" onClick={() => navigate('/profile')} overflowX={'hidden'} whiteSpace={'nowrap'} textOverflow={'ellipsis'}>
						<Text>{session?.getFirstName()}</Text>
						<Text fontSize="sm" color="darkGrey.50">
							{session?.getEmail()}
						</Text>
					</Box>
					<Tooltip label={t("Se déconnecter")}>
						<IconButton
							variant="ghost"
							colorScheme="white"
							icon={<LogOut size="16" />}
							aria-label={t("Se déconnecter")}
							onClick={signout}
						/>
					</Tooltip>
				</>
			)}
		</Stack>
	);
}
