import { checkSession } from "../api"

class UserSession {

    session_token: string
    firstname: string
    lastname: string
    email: string
    credits: number
    role_name: string
    phone: string
    first_login: boolean = true
    have_card: boolean = false
    card_asked: boolean = false
    avatar: string = ''
    have_resiliation: boolean = false
    have_access_salle: boolean = false
    have_access_course: boolean = false

    constructor(session_token: string, firstname: string, lastname: string, email: string, credits: number = 0, role_name: string = 'Collaborateur', phone: string = '', first_login: boolean = true, have_card: boolean = false, card_asked: boolean = false, avatar: string = '', have_resiliation: boolean = false, have_access_salle: boolean = false, have_access_course: boolean = false) {
        this.session_token = session_token
        this.firstname = firstname
        this.lastname = lastname
        this.email = email
        this.credits = credits
        this.role_name = role_name
        this.phone = phone
        this.first_login = Boolean(first_login)
        this.have_card = Boolean(have_card)
        this.card_asked = Boolean(card_asked)
        this.avatar = avatar
        this.have_resiliation = have_resiliation
        this.have_access_salle = have_access_salle
        this.have_access_course = have_access_course
    }

    storeSession() {
        localStorage.setItem('session_token', this.session_token)
        localStorage.setItem('firstname', this.firstname)
        localStorage.setItem('lastname', this.lastname)
        localStorage.setItem('email', this.email)
        localStorage.setItem('credits', this.credits.toString())
        localStorage.setItem('role_name', this.role_name)
        localStorage.setItem('phone', this.phone)
        localStorage.setItem('first_login', this.first_login.toString())
        localStorage.setItem('have_card', this.have_card.toString())
        localStorage.setItem('card_asked', this.card_asked.toString())
        localStorage.setItem('avatar', this.avatar)
        localStorage.setItem('have_resiliation', this.have_resiliation.toString())
        localStorage.setItem('have_access_salle', this.have_access_salle.toString())
        localStorage.setItem('have_access_course', this.have_access_course.toString())
    }

    static getSession() {
        const session_token = localStorage.getItem('session_token')
        const firstname = localStorage.getItem('firstname')
        const lastname = localStorage.getItem('lastname')
        const email = localStorage.getItem('email')
        const credits = localStorage.getItem('credits')
        const role_name = localStorage.getItem('role_name')
        const phone = localStorage.getItem('phone')
        const first_login = localStorage.getItem('first_login')
        const have_card = localStorage.getItem('have_card')
        const card_asked = localStorage.getItem('card_asked')
        const avatar = localStorage.getItem('avatar')
        const have_resiliation = localStorage.getItem('have_resiliation')
        const have_access_salle = localStorage.getItem('have_access_salle')
        const have_access_course = localStorage.getItem('have_access_course')


        if (session_token && firstname && lastname && email) {
            return new UserSession(session_token, firstname, lastname, email, parseInt(credits || '0'), role_name || 'Collaborateur', phone || '', first_login === 'true' ? true : false, have_card === 'true' ? true : false, card_asked === 'true' ? true : false, avatar || '', have_resiliation === 'true' ? true : false, have_access_salle === 'true' ? true : false, have_access_course === 'true' ? true : false)
        }
        return null
    }

    static clearSession() {
        localStorage.removeItem('session_token')
        localStorage.removeItem('firstname')
        localStorage.removeItem('lastname')
        localStorage.removeItem('email')
        localStorage.removeItem('credits')
        localStorage.removeItem('role_name')
        localStorage.removeItem('phone')
        localStorage.removeItem('first_login')
        localStorage.removeItem('have_card')
        localStorage.removeItem('avatar')
        localStorage.removeItem('card_asked')
        localStorage.removeItem('have_resiliation')
        localStorage.removeItem('have_access_salle')
        localStorage.removeItem('have_access_course')
    }

    static isLoggedIn() {
        const session = this.getSession()
        if (session) {
            return true
        }
        return false
    }

    getName() {
        return `${this.firstname} ${this.lastname}`
    }

    getEmail() {
        return this.email
    }

    getFirstName() {
        return this.firstname
    }

    //TODO: Implement this
    getCredits() {
        return this.credits
    }

    getRole() {
        return this.role_name
    }

    static async checkSession(session_token: string) {
        const response = await checkSession(session_token)
        if (response.success) {
            return true
        }
        return false
    }

    getToken() {
        return this.session_token
    }

    getJson() {
        return {
            session_token: this.session_token,
            firstname: this.firstname,
            lastname: this.lastname,
            email: this.email,
            credits: this.credits,
            role_name: this.role_name,
            phone: this.phone,
            first_login: this.first_login,
            have_card: this.have_card,
            avatar: this.avatar,
            card_asked: this.card_asked,
            have_resiliation: this.have_resiliation,
            have_access_salle: this.have_access_salle,
            have_access_course: this.have_access_course
        }
    }

    static fromJson(json: any) {
        return new UserSession(json.session_token, json.firstname, json.lastname, json.email, json.credits, json.role_name, json.phone, json.first_login, json.have_card, json.card_asked, json.avatar, json.have_resiliation, json.have_access_salle, json.have_access_course)
    }

    firstLogin() {
        return Boolean(this.first_login)
    }

    setFirstLogin(first_login: boolean) {
        this.first_login = first_login
    }

    setSession(session: UserSession) {
        // clear session
        UserSession.clearSession()
        // set session
        new UserSession(session.session_token, session.firstname, session.lastname, session.email, session.credits, session.role_name, session.phone, session.first_login, session.have_card, session.card_asked, session.avatar, session.have_resiliation, session.have_access_salle, session.have_access_course).storeSession()
    }

    decrementCredits() {
        this.credits -= 1
        localStorage.setItem('credits', this.credits.toString())
    }

    incrementCredits() {
        this.credits += 1
        localStorage.setItem('credits', this.credits.toString())
    }

    setHaveCard(have_card: boolean) {
        this.have_card = have_card
        localStorage.setItem('have_card', this.have_card.toString())
    }

    getHaveCard() {
        return this.have_card
    }

    setAskedCard(card_asked: boolean) {
        this.card_asked = card_asked
        localStorage.setItem('card_asked', this.card_asked.toString())
    }

    getAskedCard() {
        return this.card_asked
    }

    updateInfos(obj: {
        firstname: string,
        lastname: string,
        email: string,
        phone: string,
        avatar: string
    }) {
        this.firstname = obj.firstname
        this.lastname = obj.lastname
        this.email = obj.email
        this.phone = obj.phone
        this.avatar = obj.avatar

        this.storeSession()
    }

    getAvatar() {
        return this.avatar
    }

    resiliate() {
        this.have_resiliation = true
        localStorage.setItem('have_resiliation', this.have_resiliation.toString())
    }
}

export default UserSession