
import { alertAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";

/**
 * Permet de surcharger le composant Alert
 */
const { definePartsStyle, defineMultiStyleConfig } =
	createMultiStyleConfigHelpers(alertAnatomy.keys);

export const Alert = defineMultiStyleConfig({
	baseStyle: definePartsStyle({
		container: defineStyle({
			borderRadius: "xl"
		})
	}),
	variants: {
		info: definePartsStyle({
			container: defineStyle({
				_dark: {
					bg: "blue.1000"
				}
			})
		})
	},
});
