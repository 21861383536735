import UserSession from "@/Classes/UserSession";
import { getSalles, getSports, removeSalle, removeSport } from "@/api";
import MainContainer from "@/components/MainContainer";
import { DataTable } from "@/components/datatable";
import { errorMessage } from "@/utils/errorMessage";
import { Box, Grid, Input, InputGroup, InputLeftElement, Stack, Text, useDisclosure, useToast } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { ChevronLeft, ChevronRight, Search as SearchIcon } from "react-feather";
import ModifySport from "./ModifySport";
import AddSport from "./AddSport";
import DeleteSalle from "./DeleteSalle";
import ModifySalle from "./ModifySalle";
import AddSalle from "./AddSalle";
import DeleteSport from "./DeleteSport";
import { set } from "date-fns";

type Sport = {
    Id: string
    Nom: string
    Description: string
    Actions: string
}

type Salle = {
    Id: string
    Nom: string
    Actions: string
}

export default function Gestion() {
    const toast = useToast()
    const [pageIndexSport, setPageIndexSport] = useState(0)
    const [pageIndexSalle, setPageIndexSalle] = useState(0)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [session, setSession] = useState<UserSession | null>(null)
    const [sports, setSports] = useState<Sport[]>([])
    const [salles, setSalles] = useState<Salle[]>([])

    const [searchSport, setSearchSport] = useState<string>("")
    const [searchSalle, setSearchSalle] = useState<string>("")

    useEffect(() => {
        setSession(UserSession.getSession() as UserSession | null)
    }, [])

    const fetchSports = async () => {
        if (session !== null) {
            const token = session?.getToken()
            const response = await getSports(token)
            setSports(response?.data?.map((sport: any, index: number) => {
                return {
                    Id: sport.sport_id as string,
                    Nom: sport.sport_name as string,
                    Description: sport.sport_desc as string,

                }
            }))
        }
    }

    const fetchSalles = async () => {
        if (session !== null) {
            const token = session?.getToken()
            const response = await getSalles(token)
            setSalles(response?.data?.map((salle: any, index: number) => {
                return {
                    Id: salle.salle_id as string,
                    Nom: salle.salle_name as string,
                }
            }))
        }
    }

    useEffect(() => {
        if (session !== null) {
            if (session?.getRole() !== "collaborateur") {
                fetchSports()
                fetchSalles()
            }
        }

    }, [session])

    const addSportCallback = (sport: any) => {
        console.log(sport)
        setSports((sports) => [...sports, sport])
    }

    const updateSportCallback = (sport: any) => {
        setSports((sports) => {
            sports.find(u => u.Id === sport.Id).Nom = sport.Nom;
            sports.find(u => u.Id === sport.Id).Description = sport.Description;
            return [...sports];
        })
    }

    const addSalleCallback = (salle: any) => {
        setSalles([...salles, salle])
    }

    const updateSalleCallback = (salle: any) => {
        setSalles((salles) => {
            salles.find(u => u.Id === salle.Id).Nom = salle.Nom;
            return [...salles];
        })
    }

    const deleteSport = async (sport_id: string) => {
        const response = await removeSport(UserSession.getSession()?.getToken() as string, sport_id)
        if (response?.success) {
            onClose()
            setSports(sports.filter(sport => sport.Id !== sport_id))
            toast({
                title: "Sport supprimé",
                description: "Le sport a bien été supprimé",
                status: "success",
                duration: 3000,
                isClosable: true,
            })

        } else {
            toast({
                title: "Erreur",
                description: errorMessage(response.data.error_code),
                status: "error",
                duration: 3000,
                isClosable: true,
            })
        }
    }

    const deleteSalle = async (salle_id: string) => {
        const response = await removeSalle(UserSession.getSession()?.getToken() as string, salle_id)
        if (response?.success) {
            onClose()
            setSalles(salles.filter(salle => salle.Id !== salle_id))
            toast({
                title: "Salle supprimé",
                description: "La salle a bien été supprimé",
                status: "success",
                duration: 3000,
                isClosable: true,
            })

        } else {
            toast({
                title: "Erreur",
                description: errorMessage(response.data.error_code),
                status: "error",
                duration: 3000,
                isClosable: true,
            })
        }
    }

    const columnHelperSport = createColumnHelper<Sport>()

    const columnsSport = [
        columnHelperSport.accessor('Nom', {
            cell: info => info.getValue(),
            footer: info => info.column.id,
        }),
        columnHelperSport.accessor('Actions', {
            cell: info => <Grid
                templateColumns="repeat(2, 25%)"
                gap={4}
            >
                <ModifySport description={info.row.original.Description} name={info.row.original.Nom} id={info.row.original.Id} updatedSport={updateSportCallback} />
                <DeleteSport validate={() => { deleteSport(info.row.original.Id) }} />
            </Grid>,
        })
    ]

    const [datasTabSport, setDatasTabSport] = useState<Sport[][]>([]);

    const updateTableSportBySearch = () => {
        const updatedDatasTab: Sport[][] = [];
        let tab: Sport[] = [];
        console.log(sports)
        const filteredSports = sports.filter(sport => sport.Nom.toLowerCase().includes(searchSport.toLowerCase()) || sport.Description.toLowerCase().includes(searchSport.toLowerCase()));

        filteredSports.forEach((d, index) => {
            if ((index + 1) % 9 === 0) {
                updatedDatasTab.push(tab);
                tab = [];
            }
            tab.push(d);
        }
        );

        if (tab.length > 0) {
            updatedDatasTab.push(tab);
        }

        return updatedDatasTab;
    }

    useEffect(() => {
        setDatasTabSport(updateTableSportBySearch());
    }, [searchSport, sports])

    const handlePageLeftSport = () => {
        if (pageIndexSport > 0)
            setPageIndexSport(pageIndexSport - 1)
    }

    const handlePageRightSport = () => {
        if (pageIndexSport < datasTabSport.length - 1)
            setPageIndexSport(pageIndexSport + 1)
    }

    const columnHelperSalle = createColumnHelper<Salle>()

    const columnsSalle = [
        columnHelperSalle.accessor('Nom', {
            cell: info => info.getValue(),
            footer: info => info.column.id,
        }),
        columnHelperSalle.accessor('Actions', {
            cell: info => <Grid
                templateColumns="repeat(2, 25%)"
                gap={4}
            >
                <ModifySalle name={info.row.original.Nom} id={info.row.original.Id} updatedSalle={updateSalleCallback} />
                <DeleteSalle validate={() => { deleteSalle(info.row.original.Id) }} />
            </Grid>,
        })
    ]

    const [datasTabSalle, setDatasTabSalle] = useState<Salle[][]>([]);

    const updateTableSalleBySearch = () => {
        const updatedDatasTab: Salle[][] = [];
        let tab: Salle[] = [];
        console.log(salles)
        const filteredSalles = salles.filter(salle => salle.Nom.toLowerCase().includes(searchSalle.toLowerCase()));

        filteredSalles.forEach((d, index) => {
            if ((index + 1) % 9 === 0) {
                updatedDatasTab.push(tab);
                tab = [];
            }
            tab.push(d);
        }
        );

        if (tab.length > 0) {
            updatedDatasTab.push(tab);
        }

        return updatedDatasTab;
    }

    useEffect(() => {
        setDatasTabSalle(updateTableSalleBySearch());
    }, [searchSalle, salles])

    const handlePageLeftSalle = () => {
        if (pageIndexSalle > 0)
            setPageIndexSalle(pageIndexSalle - 1)
    }

    const handlePageRightSalle = () => {
        if (pageIndexSalle < datasTabSalle.length - 1)
            setPageIndexSalle(pageIndexSalle + 1)
    }

    return (
        <MainContainer>

            <Text fontSize="3xl" color="white" fontWeight="bold" mb="2rem">
                Gestion des données
            </Text>

            <Box display="flex" flexWrap={"wrap"} mb='2rem' gap={"2rem"}>
                <Box border='2px solid #1A1E23' borderRadius='10px' pb='3' flexGrow={1}>
                    <Box display='flex' justifyContent='space-between' flexWrap={["wrap", "nowrap"]} alignItems='center' mb='2rem' gap={"2rem"} px='3' pt='3'>
                        <Text fontSize="xl" pl='3' pt='3' color="white" fontWeight="bold">
                            Liste des sports
                        </Text>
                        <AddSport validate={addSportCallback} />
                    </Box>

                    <Box>
                        <Stack direction='row' spacing={4} alignItems='center' justifyContent='space-between' px='3' pt='3' flexWrap={'wrap'}>
                            <InputGroup width='100%' alignItems='center'>
                                <InputLeftElement backgroundColor='transparent'>
                                    <SearchIcon size={16} />
                                </InputLeftElement>
                                <Input placeholder="Rechercher ..." onChange={(e) => {
                                    setSearchSport(e.target.value);
                                }} />
                            </InputGroup>

                            <Box display='flex' ml='auto' alignItems='center' justifyContent='flex-end'>
                                <Text fontSize='md' mr='4'>{pageIndexSport + 1} sur {datasTabSport.length}</Text>
                                <Box w='5.5rem' h='10' bg='#1A1E23' border='1px solid #252B32' borderRadius='3px' display='flex' flexDirection='row' cursor='pointer'>
                                    <Box w='10' borderLeftRadius='3px' borderRight='1px solid #252B32' display='flex' justifyContent='center' alignItems='center' onClick={handlePageLeftSport} _hover={{ backgroundColor: '#393D42' }}>
                                        <ChevronLeft size='24' />
                                    </Box>
                                    <Box w='10' borderRightRadius='3px' display='flex' justifyContent='center' alignItems='center' onClick={handlePageRightSport} _hover={{ backgroundColor: '#393D42' }}>
                                        <ChevronRight />
                                    </Box>
                                </Box>
                            </Box>

                        </Stack>
                        <Box w='100%' px='3' pt='3'>
                            {datasTabSport.length > 0 ?
                                <DataTable data={datasTabSport[pageIndexSport]} columns={columnsSport} loading={false} sx={{}} />
                                : null}
                        </Box>
                    </Box>
                </Box>
                <Box border='2px solid #1A1E23' borderRadius='10px' pb='3' flexGrow={1}>
                    <Box display='flex' justifyContent='space-between' flexWrap={["wrap", "nowrap"]} alignItems='center' mb='2rem' gap={"2rem"} px='3' pt='3'>
                        <Text fontSize="xl" pl='3' pt='3' color="white" fontWeight="bold">
                            Liste des salles
                        </Text>
                        <AddSalle validate={addSalleCallback} />
                    </Box>
                    <Box>
                        <Stack direction='row' spacing={4} alignItems='center' justifyContent='space-between' px='3' pt='3' flexWrap={'wrap'}>
                            <InputGroup width='100%' alignItems='center'>
                                <InputLeftElement backgroundColor='transparent'>
                                    <SearchIcon size={16} />
                                </InputLeftElement>
                                <Input placeholder="Rechercher ..." onChange={(e) => {
                                    setSearchSalle(e.target.value);
                                }} />
                            </InputGroup>

                            <Box display='flex' ml='auto' alignItems='center' justifyContent='flex-end'>
                                <Text fontSize='md' mr='4'>{pageIndexSalle + 1} sur {datasTabSalle.length}</Text>
                                <Box w='5.5rem' h='10' bg='#1A1E23' border='1px solid #252B32' borderRadius='3px' display='flex' flexDirection='row' cursor='pointer'>
                                    <Box w='10' borderLeftRadius='3px' borderRight='1px solid #252B32' display='flex' justifyContent='center' alignItems='center' onClick={handlePageLeftSalle} _hover={{ backgroundColor: '#393D42' }}>
                                        <ChevronLeft size='24' />
                                    </Box>
                                    <Box w='10' borderRightRadius='3px' display='flex' justifyContent='center' alignItems='center' onClick={handlePageRightSalle} _hover={{ backgroundColor: '#393D42' }}>
                                        <ChevronRight />
                                    </Box>
                                </Box>
                            </Box>

                        </Stack>
                        <Box w='100%' px='3' pt='3'>
                            {datasTabSalle.length > 0 ?
                                <DataTable data={datasTabSalle[pageIndexSalle]} columns={columnsSalle} loading={false} sx={{}} />
                                : null}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </MainContainer >
    );
}