import MainContainer from "@/components/MainContainer";
import { Box, Text } from "@chakra-ui/react";


export default function Cgu() {
    return (
        <MainContainer>
            <Box maxW={'1000px'} mx={'auto'} px={4} py={4}>
                <Text fontSize="3xl" textAlign={'center'} fontWeight="bold" mb="2" mt={2}>
                    Charte d'utilisation
                </Text>

                <hr />

                <Text fontSize="xl" fontWeight="bold" mb="2" mt={2}>
                    1 - Préambule
                </Text>
                <Text>
                    Free Pro propose à ses collaborateurs quelle que soit la nature de son contrat de travail (CDI, CDD, contrat d’apprentissage etc.) et quel que soit son lieu de travail, d’adhérer au centre d’entrainement de son siège social Smartsea situé 3, rue Paul Brutus 13015.
                    <br />
                    Tout collaborateur souhaitant adhérer au centre d’entrainement devra signer cette présente charte. Elle entend rappeler les règles élémentaires à respecter pour accéder à la salle de sport, utiliser les machines, participer aux cours et utiliser les vestiaires.
                </Text>
                <br />
                <Text fontSize="xl" fontWeight="bold" mb="2" mt={2}>
                    2 - Adhésion
                </Text>
                <Text>
                    Le salarié a deux possibilités d’adhésion :
                </Text>
                <ul style={{ listStyleType: "disc", marginLeft: "2rem" }}>
                    <li>Un abonnement annuel à la salle de sport. L’abonnement choisi se fait pour un engagement d’un
                        3 an en envoyant un mail à <a style={{ color: "#EF8090" }} href="mailto:LCE@freepro.com">LCE@freepro.com</a>.</li>
                    <li>Un carnet de 10 cours permettant d’accéder au cours de pilâtes, indoor bike, cross training en envoyant un mail à <a style={{ color: "#EF8090" }} href="mailto:LCE@freepro.com">LCE@freepro.com</a></li>
                </ul>
                <br />
                <Box ml={"2rem"}>
                    <Text fontSize="l" fontWeight="bold" mb="2" mt={2}>
                        2.1 - Modalités d'inscription
                    </Text>
                    <ul style={{ listStyleType: "disc", marginLeft: "2rem" }}>
                        <li>Pour s’inscrire au centre d’entrainement, le collaborateur devra renseigner une fiche d’identité annexée à la présente charte.</li>
                        <li>Le salarié devra fournir un certificat médical d’absence de contre-indication (CACI) à la pratique sportive au moment de son inscription.</li>
                        <li>Une carte d’adhésion sera alors distribuée au salarié, elle est nominative et incessible. Elle devra être présentée au coach sportif chaque fois que le collaborateur se rend au centre d’entrainement.</li>
                        <li>Le collaborateur ayant choisi une formule « COURS », devra s’inscrire aux deux cours de son choix dispensés la semaine suivante chaque vendredi sur le OneDrive créé à cet effet. Toute inscription à un cours doit être tenue pour le respect des coachs.
                        </li>
                    </ul>
                    <Text fontSize="l" fontWeight="bold" mb="2" mt={2}>
                        2.2 - Modalités de résiliation de l’abonnement à la salle de sport
                    </Text>
                    <ul style={{ listStyleType: "disc", marginLeft: "2rem" }}>
                        <li>Toute résiliation devra faire l’objet d’un courriel 2 mois avant la date de fin d’engagement sur l’adresse  <a style={{ color: "#EF8090" }} href="mailto:LCE@freepro.com">LCE@freepro.com</a>. Si aucune résiliation n’a lieu 2 mois avant la date de fin d’engagement, il est renouvelé par tacite reconduction.</li>
                        <li>En cas de rupture du contrat de travail, l’abonnement est résilié d’office à la date de sortie ou dès le premier jour de dispense du préavis.</li>
                        <li>En cas d’inaptitude médicale, le salarié devra envoyer un certificat médical attestant de son incapacité à faire du sport sur l’adresse <a style={{ color: "#EF8090" }} href="mailto:LCE@freepro.com">LCE@freepro.com</a>. L’abonnement sera alors rompu le mois suivant la fourniture de l’attestation.</li>
                    </ul>
                    <Text fontSize="l" fontWeight="bold" mb="2" mt={2}>
                        2.3 - Coût de l’adhésion
                    </Text>
                    <ul style={{ listStyleType: "disc", marginLeft: "2rem" }}>
                        <li><b>Salle de sport :</b> abonnement de 180€/an payable en une fois.</li>
                        <li><b>Cours :</b> une carte de 10 cours pour 40€ payable en une fois.</li>
                    </ul>
                </Box>
                <Text fontSize="xl" fontWeight="bold" mb="2" mt={2}>
                    3 - Horaires d’ouverture et rôle des coachs
                </Text>
                <Text>
                    Le centre d’entrainement est ouvert du lundi au vendredi 49 semaines par an (fermeture les 15 premiers jours d’août et la semaine entre Noël et le jour de l’an) de 11h30 à 14h30 et de 17h30 à 20h30. En dehors de ces horaires d’ouverture, l’accès à la salle de sport est interdit.
                    Les cours sont organisés de la manière suivante :
                </Text>
                <table style={{ width: "100%", borderCollapse: "collapse", border: "1px solid gray" }} id="cgutable">
                    <thead>
                        <tr>
                            <th> </th>
                            <th>HORAIRES MIDI</th>
                            <th>HORAIRES SOIR</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Lundi</td>
                            <td>12h30 - 13h15</td>
                            <td>18h30 - 19h15</td>
                        </tr>
                        <tr>
                            <td>Mardi</td>
                            <td>12h30 - 13h15</td>
                            <td>18h30 - 19h15</td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <hr />
                <br />
                <Text>
                    Chaque coach sportif a pour mission :
                </Text>
                <ul style={{ listStyleType: "disc", marginLeft: "2rem" }}>
                    <li>Ouvrir et fermer la salle aux horaires prévus.</li>
                    <li>Enregistrer le nom et le prénom des personnes se présentant à la salle de sport.</li>
                    <li>Assurer un entrainement dans de bonnes conditions de sécurité, progressif et adapté pour chaque participant.</li>
                    <li>Garantir une ambiance propice à l'entrainement et au respect de chaque personne.</li>
                    <li>Assurer la bonne tenue, le rangement des espaces de travail.</li>
                    <li>Individualiser au maximum les entrainements des participants.</li>
                </ul>

                <Text fontSize="xl" fontWeight="bold" mb="2" mt={2}>
                    4 - Conditions d’accès
                </Text>

                <ul style={{ listStyleType: "disc", marginLeft: "2rem" }}>
                    <li>La présentation de la carte d’abonné est obligatoire pour accéder au centre d’entrainement.</li>
                    <li>L’accès est interdit à toute personne non salariée de l’entreprise et non abonnée.</li>
                </ul>


                <Text fontSize="xl" fontWeight="bold" mb="2" mt={2}>
                    5 - Tenue et comportement
                </Text>
                <ul style={{ listStyleType: "disc", marginLeft: "2rem" }}>
                    <li>L’accès au centre d’entrainement n’est autorisé qu’aux personnes en tenue décente, appropriée à la pratique sportive en salle ou à l’utilisation des appareils. Ce critère est soumis à l’appréciation du coach sportif.</li>
                    <li>Les chaussures de sport doivent être adaptées aux activités, à semelles antidérapantes et propres (pointes et crampons exclus).</li>
                    <li>Les cordons, ceintures, etc., et tout vêtement ou objet susceptibles d’être entrainés par le fonctionnement des appareils est prohibé.</li>
                    <li>Tout usager troublant par son comportement le bon déroulement des séances et la tranquillité des autres usagers pourra se faire exclure de l’installation avec résiliation de son abonnement.</li>
                    <li>Le coach sportif est tenu de faire respecter les consignes de sécurité et le bon fonctionnement de l’équipement. Tout comportement irrespectueux (agression verbale ou physique) à son encontre entrainera la résiliation du contrat d’abonnement du salarié.</li>
                </ul>

                <Text fontSize="xl" fontWeight="bold" mb="2" mt={2}>
                    6 - Vestiaires et objets personnels
                </Text>
                <ul style={{ listStyleType: "disc", marginLeft: "2rem" }}>
                    <li>Les opérations d’habillage-déshabillage doivent être effectuées dans les vestiaires.</li>
                    <li>Des casiers qui ne font pas l’objet d’une surveillance particulière, sont mis à la disposition du salarié pendant la pratique de l’activité. Ils doivent être fermés par ses soins et vidés obligatoirement à son départ de la salle.</li>
                    <li>L’utilisation des casiers étant sous la seule responsabilité de l’adhérent, celui-ci renonce à tout recours à l’encontre de Free Pro pour tout vol ou tout dommage qu’il pourrait subir de ce fait. L’adhérent reconnait avoir été informé des risques encourus par le dépôt d’objets de valeur dans les vestiaires communs.</li>
                    <li>Chaque soir, à la fermeture du centre d’entrainement, tous les casiers seront ouverts et vidés par le coach sportif, sans garantie pour les effets personnels des salariés.</li>
                    <li>Tout objet ou vêtement non réclamé sous huitaine sera évacué du centre.</li>
                    <li>Aucun recours ne pourra être exercé contre Free pro pour des objets égarés ou volés dans l’établissement.</li>
                </ul>
                <Text fontSize="xl" fontWeight="bold" mb="2" mt={2}>
                    7 - Hygiène et propreté
                </Text>
                <ul style={{ listStyleType: "disc", marginLeft: "2rem" }}>
                    <li>Il est interdit de s’entraîner torse nu ou pieds nus dans le centre d’entrainement.</li>
                    <li>L’usage d’une serviette lors de l’entrainement en salle est exigé pour éviter le contact des appareils avec la peau.</li>
                    <li>Tout appareil doit être essuyé ou nettoyé par son utilisateur de façon à permettre l’usage normal par celui qui lui succède.</li>
                </ul>
                <Text fontSize="xl" fontWeight="bold" mb="2" mt={2}>
                    8 - Utilisation du matériel
                </Text>
                <ul style={{ listStyleType: "disc", marginLeft: "2rem" }}>
                    <li>La mise en œuvre des appareils se fait sous la responsabilité exclusive de l’utilisateur. Celui-ci doit connaître le mode d’emploi des appareils qu’il envisage d’utiliser et s’y conformer scrupuleusement. Dans le cas contraire, avant l’utilisation de l’appareil, il doit se rapprocher d’un coach sportif pour prendre connaissance de son mode d’emploi et des recommandations à respecter lors de son utilisation.</li>
                    <li>Les appareils doivent être utilisés avec soin et ne pas être surchargés, ni déplacés.</li>
                    <li>Les poids, sangles ou haltères doivent être soigneusement rangés après utilisation.</li>
                    <li>Les barres des appareils libres doivent être déchargées après utilisation et rangées ainsi que les disques sur les râteliers.</li>
                    <li>Tous les appareils doivent être remis en position d’arrêt.</li>
                    <li>L’utilisation de bâtons ainsi que la gymnastique au sol sont interdites dans la salle de sport.</li>
                    <li>L’utilisation des appareils doit se faire en alternance avec les autres usagers et en période d’affluence, l’utilisation des appareils de cardio-training pourra être limitée à 20 minutes.</li>
                    <li>Il convient de mentionner au coach sportif tout problème lié à un appareil d’entraînement.</li>
                    <li>L’emprunt de matériel est strictement interdit.</li>
                </ul>
                <Text fontSize="xl" fontWeight="bold" mb="2" mt={2}>
                    9 - Cours collectifs
                </Text>
                <ul style={{ listStyleType: "disc", marginLeft: "2rem" }}>
                    <li>Free Pro se réserve le droit de modifier à tout moment les horaires après information préalable des adhérents.</li>
                    <li>Les cours collectifs peuvent accueillir jusqu’à maximum 10 personnes.</li>
                    <li>L’accueil dans la salle est limité à la capacité maximale autorisée. L’accès sera refusé si le nombre de participants a atteint le quota réglementaire.</li>
                </ul>
                <Text fontSize="xl" fontWeight="bold" mb="2" mt={2}>
                    10 - Responsabilité de l’utilisateur
                </Text>
                <ul style={{ listStyleType: "disc", marginLeft: "2rem" }}>
                    <li>Les salissures, détériorations et dégradations sont à la charge de leur auteur.</li>
                    <li>Les personnes ayant causé des détériorations aux matériels ou installations seront contraintes d’acquitter le montant de leur remise en état.</li>
                    <li>Toutes les activités pratiquées en salle ou l’utilisation des appareils de musculation ou de cardio- training peuvent comporter des risques.</li>
                    <li>L’accès à la salle de musculation par une personne seule est interdit.</li>
                    <li>Free Pro décline toute responsabilité en cas d’accident corporel ou matériel consécutif à une utilisation non conforme des installations et du matériel, au non-respect des règles de sécurité, à des plans d’entraînement ou à une surestimation par l’utilisateur de sa condition physique.</li>
                    <li>Free Pro ne pourra pas être tenue pour responsable en cas de perte ou de vol d’effets personnels.</li>
                </ul>
                <Text fontSize="xl" fontWeight="bold" mb="2" mt={2}>
                    11 - Traitements des données à caractère personnel
                </Text>
                <Text>
                    Les données à caractère personnel collectées dans le cadre de votre adhésion à la salle de sport seront utilisées par Free Pro dans le but de :
                </Text>
                <ul style={{ listStyleType: "disc", marginLeft: "2rem" }}>
                    <li>Réserver les cours</li>
                    <li>Permettre le rechargement des crédits</li>
                    <li>Authentifier les utilisateurs sur le site web</li>
                    <li>Mesurer les audiences de participation</li>
                    <li>Autoriser l’accès à salle de sport</li>
                    <li>Pointer la présence aux cours</li>
                    <li>Comptabiliser des souscriptions sur les fiches de paies des collaborateurs.</li>
                </ul>
                <Text>
                    Nous nous engageons à respecter la confidentialité et la sécurité de vos données à caractère
                    personnel.
                    <br />
                    Nous conservons vos données uniquement pendant la durée d’adhésion à la salle de sport et jusqu’à 1 mois après la fin du contrat. A la fin de la période d’adhésion, toutes les données à caractère personnel collectées seront supprimées ou anonymisées de manière sécurisée.
                    <br />
                    Les données collectées sont votre nom, prénom, votre adresse e-mail professionnelle, votre société d’appartenance. Ces informations ne seront utilisées que par :
                </Text>
                <ul style={{ listStyleType: "disc", marginLeft: "2rem" }}>
                    <li>Les RH Free Pro pour la paie, l’inscription et la mesure de l’audience,</li>
                    <li>Les professeurs pour le pointage aux cours et la mesure de l’audience.</li>
                </ul>
                <Text>Les bases légales de ces traitements sont :</Text>
                <ul style={{ listStyleType: "disc", marginLeft: "2rem" }}>
                    <li>L'intérêt légitime de Free Pro pour l’authentification sur le site web, l’autorisation d’accès, le pointage, les mesures d’audiences</li>
                    <li>L’exécution d’un contrat pour l’adhésion à la salle, la réservation de cours, le rechargement de crédit et la comptabilisation</li>
                </ul>
                <br /><br />
                <hr /><br />
                <Text>
                    Vous pouvez accéder aux données vous concernant, les rectifier, demander leur effacement ou exercer
                    votre droit à la limitation du traitement de vos données via courriel à <a style={{ color: "#EF8090" }} href="mailto:rgpd@freepro.com">rgpd@freepro.com</a>.
                    <br /><br />
                    Le Délégué à la Protection des Données personnelles peut à tout moment être contacté pour des
                    questions relatives à la protection de vos données personnelles à l’adresse : <a style={{ color: "#EF8090" }} href="mailto:dpo@iliad.fr">dpo@iliad.fr</a>.
                </Text>
                <br /><br />
                <Text fontSize="xl" textAlign={'center'} color={"red.500"} textDecoration={'underline'} fontWeight="bold" mb="2" mt={2}>
                    TOUT MANQUEMENT A L’UNE DE CES REGLES POURRA ENTRAINER UNE EXCLUSION
                    DEFINITIVE OU TEMPORAIRE DU CENTRE D’ENTRAINEMENT.
                </Text>
            </Box>
        </MainContainer >
    )
}