/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react'
import {
    Box,
    Text,
    Image,
} from "@chakra-ui/react";
import LogoAnimation from '@/components/LogoAnimation';



export default function BackSoon() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const image_slug = "https://lce.inno.freepro.com/api/cdn/images"


    const handleResize = () => {
        setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return (
        <Box flex='1' display='flex' flexDirection='row'>
            <Box flex='1' h='100dvh' display='flex' alignItems='center' justifyContent='center' backgroundColor={"#000"}>
                <Box display='flex' justifyContent='center' flexDirection='column' p={window.innerWidth > 500 ? '0' : '4'} w='25rem'>
                    <LogoAnimation />
                    <br /><br />
                    <Text fontSize='l' color='#fff'>Tenez vous prêt ! Le Centre D'Entrainement revient bientôt ! 💪🏼</Text>
                </Box>
            </Box>
            {windowWidth > 500 ?
                <Box flex='1' display='flex' alignItems='center' justifyContent='center' backgroundColor={"#000"}>
                    <Image width='90%' height='90%' borderRadius='30px' src={`${image_slug}/bg_home.png`} objectFit={'cover'} />
                </Box>
                : null}
        </Box>
    )
}