import MainContainer from "@/components/MainContainer";
import StatsWidget from "@/components/StatsWidget";
import { Box, Grid, Text, Skeleton, Stack, Input } from "@chakra-ui/react";
import { faker } from "@faker-js/faker";
import { mongoGet, getSports } from "@/api";
import UserSession from "@/Classes/UserSession";
import { useEffect, useState } from "react";

type ChartData = {
    labels: string[],
    datasets: {
        label: string,
        data: number[],
        borderColor: string | string[],
        backgroundColor: string | string[],
        fill?: boolean
        tension?: number
    }[]
}

export default function Stats() {

    const [session, setSession] = useState<UserSession | null>(null)
    const [token, setToken] = useState<string>("")
    const [reservationData, setReservationData] = useState<ChartData | null>(null)
    const [inscriptionsData, setInscriptionsData] = useState<ChartData | null>(null)
    const [presenceData, setPresenceData] = useState<ChartData | null>(null)
    const [frequencyData, setFrequencyData] = useState<ChartData | null>(null)
    const [presencePeriodData, setPresencePeriodData] = useState<ChartData | null>(null)
    const [reservationPeriodData, setReservationPeriodData] = useState<ChartData | null>(null)
    const [effectifData, setEffectifData] = useState<ChartData | null>(null)
    const [rechargementData, setRechargementData] = useState<ChartData | null>(null)
    const [loading, setLoading] = useState<boolean>(true)
    const [dateStart, setDateStart] = useState<number>(1672531200)
    const [dateEnd, setDateEnd] = useState<number>(Math.floor(Date.now() / 1000))


    const getDateAxis = () => {
        const labels = [];
        const dates = [];
        const start = new Date(dateStart * 1000);
        const end = new Date(dateEnd * 1000);

        while (start <= end) {

            labels.push(start.toLocaleString('fr', { month: 'long' }).toLowerCase());
            dates.push(Math.floor(start.getTime() / 1000));

            if (start.getMonth() === end.getMonth() && start.getFullYear() === end.getFullYear()) {
                labels.push(new Date(end.getTime()).toLocaleString('fr', { day: 'numeric', month: 'long' }).toLowerCase());
                dates.push(Math.floor(end.getTime() / 1000));
            }

            start.setMonth(start.getMonth() + 1);
        }

        return { labels, dates };
    }



    const getReservations = async () => {
        const sports = await getSports(token)
        const labels = sports?.data?.map((sport: any) => sport.sport_name)

        const res = await mongoGet(token, "reservations", dateStart, dateEnd)

        return [{
            labels,
            datasets: [
                {
                    label: 'Reservations',
                    data: labels.map((label: any) => res.data.filter((reservation: any) => reservation.sport === label).length),
                    borderColor: [
                        "#e0e000",
                        "#24a0e3",
                        "#fc4783",
                        "#24e3a7",
                        "#a181cc",
                        "#eb8e59",
                    ],
                    backgroundColor: [
                        "#e0e00075",
                        "#24a0e375",
                        "#fc478375",
                        "#24e3a775",
                        "#a181cc75",
                        "#eb8e5975",
                    ],
                },
            ],
        },
        {
            labels: ["Le soir", "Le midi"],
            datasets:
                sports.data.map((sport: any) => {
                    return {
                        label: sport.sport_name,
                        data: [res.data.filter((reservation: any) => reservation.sport === sport.sport_name && reservation.period === "soir").length, res.data.filter((reservation: any) => reservation.sport === sport.sport_name && reservation.period === "midi").length],
                        borderColor: [
                            "#a181cc",
                            "#e0e000",
                        ],
                        backgroundColor: [
                            "#a181cc75",
                            "#e0e00075",
                        ]
                    }
                })
            ,
        }] as ChartData[]

    }
    const getPresences = async () => {

        const labels = ["Salle", "Cours"]
        const weekLabels = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi"]

        const res = await mongoGet(token, "badge", dateStart, dateEnd)

        let number_of_weeks = Math.floor((dateEnd - dateStart) / 604800) + 1 > 0 ? Math.floor((dateEnd - dateStart) / 604800) + 1 : 1

        return [{
            labels,
            datasets: [
                {
                    label: 'Présences',
                    data: labels.map((label: any) => res.data.filter((presence: any) => presence.type.toLowerCase() === label.toLowerCase()).length),
                    borderColor: [
                        "#e0e000",
                        "#24a0e3",
                    ],
                    backgroundColor: [
                        "#e0e00075",
                        "#24a0e375",
                    ],
                },
            ],
        },
        {
            labels: ["Le soir", "Le midi"],
            datasets: [
                {
                    label: "Effectif",
                    data: [res.data.filter((presence: any) => presence.type === presence.type && presence.period === "soir").length, res.data.filter((presence: any) => presence.type === presence.type && presence.period === "midi").length],
                    borderColor: [
                        "#a181cc",
                        "#e0e000",
                    ],
                    backgroundColor: [
                        "#a181cc75",
                        "#e0e00075",
                    ]
                }
            ]
        },
        {
            labels: weekLabels,
            datasets: [
                {
                    label: "Midi",
                    data: weekLabels.map((label: any) => res.data.filter((presence: any) => presence.period === "midi" && new Date(presence._timestamp * 1000).toLocaleString('fr', { weekday: 'long' }).toLowerCase() === label.toLowerCase()).length / number_of_weeks),
                    borderColor: "#e0e000",
                    backgroundColor: "#e0e00075",
                },
                {
                    label: "Soir",
                    data: weekLabels.map((label: any) => res.data.filter((presence: any) => presence.period === "soir" && new Date(presence._timestamp * 1000).toLocaleString('fr', { weekday: 'long' }).toLowerCase() === label.toLowerCase()).length / number_of_weeks),
                    borderColor: "#24a0e3",
                    backgroundColor: "#24a0e375",
                },
            ]
        }
        ] as ChartData[]

    }

    const getEffectifs = async () => {
        const res = await mongoGet(token, "inscriptions", 0, dateEnd)
        const resilisations = await mongoGet(token, "resiliations", 0, dateEnd)

        const { labels, dates } = getDateAxis();

        const getEffectifByAccess = (access_type: string) => {
            const effectifs = [];
            let sum = 0;
            for (let i = 0; i < labels.length; i++) {
                if (access_type === "both") {
                    sum += res.data.filter((inscription: any) => inscription.access_type.length === 2 && inscription._timestamp >= dates[i] && inscription._timestamp < dates[i + 1]).length;
                } else {
                    sum += res.data.filter((inscription: any) => inscription.access_type.length === 1 && inscription.access_type[0] === access_type && inscription._timestamp >= dates[i] && inscription._timestamp < dates[i + 1]).length;
                }
                effectifs.push(sum);
            }
            return effectifs;
        }

        const getInscriptionsByAccess = (access_type: string) => {
            if (access_type === "both") {
                return labels.map((label: any, i: number) => res.data.filter((inscription: any) => inscription._timestamp >= dates[i] && inscription._timestamp < dates[i + 1] && inscription.access_type.length === 2).length)
            } else {
                return labels.map((label: any, i: number) => res.data.filter((inscription: any) => inscription._timestamp >= dates[i] && inscription._timestamp < dates[i + 1] && inscription.access_type.length === 1 && inscription.access_type[0] === access_type).length)
            }
        }

        console.log(resilisations)
        console.log(labels.map((label: any, i: number) => resilisations.data.filter((resilisation: any) => resilisation._timestamp >= dates[i] && resilisation._timestamp < dates[i + 1]).length))

        return [
            {
                labels: labels.slice(0, -1),
                datasets: [
                    {
                        fill: true,
                        tension: 0.2,
                        label: 'Accès Salle',
                        data: getEffectifByAccess("access_salle"),
                        borderColor: "#24a0e3",
                        backgroundColor: "#24a0e375",
                    },
                    {
                        fill: true,
                        tension: 0.2,
                        label: 'Accès Cours',
                        data: getEffectifByAccess("access_cours"),
                        borderColor: "#fc4783",
                        backgroundColor: "#fc478375",
                    },
                    {
                        fill: true,
                        tension: 0.2,
                        label: 'Accès Salle + Cours',
                        data: getEffectifByAccess("both"),
                        borderColor: "#24e3a7",
                        backgroundColor: "#24e3a775",
                    },
                ],
            },
            {
                labels: labels.slice(0, -1),
                datasets: [
                    {
                        label: 'Inscriptions Salle',
                        data: getInscriptionsByAccess("access_salle"),
                        borderColor: "#e0e000",
                        backgroundColor: "#a0a00075",
                        borderWidth: 2,
                    },
                    {
                        label: 'Inscriptions Cours',
                        data: getInscriptionsByAccess("access_cours"),
                        borderColor: "#24a0e3",
                        backgroundColor: "#24a0e375",
                        borderWidth: 2,
                    },
                    {
                        label: 'Inscriptions Salle + Cours',
                        data: getInscriptionsByAccess("both"),
                        borderColor: "#24e3a7",
                        backgroundColor: "#24e3a775",
                        borderWidth: 2,
                    },
                    {
                        label: 'Résiliations',
                        data: labels.map((label: any, i: number) => -resilisations.data.filter((resilisation: any) => resilisation._timestamp >= dates[i] && resilisation._timestamp < dates[i + 1]).length),
                        borderColor: "#fc4783",
                        backgroundColor: "#fc478375",
                        borderWidth: 2,
                    }
                ],

            }
        ] as ChartData[]
    }

    const getRechargement = async () => {
        const res = await mongoGet(token, "rechargements", dateStart, dateEnd)
        const { labels, dates } = getDateAxis();
        return {
            labels: labels.slice(0, -1),
            datasets: [
                {
                    fill: true,
                    tension: 0.2,
                    label: 'Rechargements',
                    data: labels.map((label: any, i: number) => res.data.filter((rechargement: any) => rechargement._timestamp >= dates[i] && rechargement._timestamp < dates[i + 1]).length || 0),
                    borderColor: "#e0e000",
                    backgroundColor: "#a0a00075",
                    borderWidth: 2,
                },
            ],
        }
    }



    useEffect(() => {
        setSession(UserSession.getSession())
        setToken(UserSession.getSession().getToken() || "")
    }, [])

    useEffect(() => {
        if (token !== "") {
            setTimeout(() => {
                Promise.all([getReservations(), getEffectifs(), getRechargement(), getPresences()]).then((res) => {
                    setReservationData(res[0][0])
                    setReservationPeriodData(res[0][1])
                    setEffectifData(res[1][0])
                    setInscriptionsData(res[1][1])
                    setRechargementData(res[2])
                    setPresenceData(res[3][0])
                    setPresencePeriodData(res[3][1])
                    setFrequencyData(res[3][2])
                    setLoading(false)

                })
            }, 100)
        }
    }, [token])

    useEffect(() => {
        if (token === "") return
        Promise.all([getReservations(), getEffectifs(), getRechargement(), getPresences()]).then((res) => {
            setReservationData(res[0][0])
            setReservationPeriodData(res[0][1])
            setEffectifData(res[1][0])
            setInscriptionsData(res[1][1])
            setRechargementData(res[2])
            setPresenceData(res[3][0])
            setPresencePeriodData(res[3][1])
            setFrequencyData(res[3][2])
        })
    }, [token, dateStart, dateEnd])

    return (
        <MainContainer>
            <Text fontSize="3xl" color="white" fontWeight="bold" mb="2rem">
                Statistiques du centre
            </Text>
            <Stack direction="row" align="center" justify="space-between" mb="2rem" flexWrap="wrap" width={"100%"}>
                <Text fontSize="lg" color="white" fontWeight="medium" mb="1rem">
                    Données du mois de {new Date().toLocaleString('fr', { month: 'long' })}
                </Text>

                <Stack direction="row" align="center" justify="center" maxW="400px" width={"100%"}>
                    <Text mx="1rem" color="white" fontWeight="medium" whiteSpace={"nowrap"}>
                        Du
                    </Text>
                    <Input type="date" value={new Date(dateStart * 1000).toISOString().split("T")[0]} onChange={(e) => setDateStart(new Date(e.target.value).getTime() / 1000)} />
                    <Text mx="1rem" color="white" fontWeight="medium" whiteSpace={"nowrap"}>
                        au
                    </Text>
                    <Input type="date" value={new Date(dateEnd * 1000).toISOString().split("T")[0]} onChange={(e) => setDateEnd(new Date(e.target.value).getTime() / 1000)} />
                </Stack>

            </Stack>



            <Grid
                templateColumns="repeat(auto-fit, minmax(275px, 1fr))"
                gap={6}
            >
                <StatsWidget type={"bar"} title="Suivi des inscriptions" data={inscriptionsData} loading={loading} stack={true} tooltip="Suivi des inscriptions par mois. Représente le nombre et la répartition des inscriptions par type pour le mois donné." />
                <StatsWidget type={"line"} title="Suivi des effectifs" data={effectifData} loading={loading} tooltip="Suivi de la croissance cumulée des effectifs par mois sur la période sélectionnée. La dernière valeur représente la somme des effectifs sur la période sélectionnée." />
                <StatsWidget type={"bar"} title="Suivi des rechargements" data={rechargementData} loading={loading} stack={true} tooltip="Suivi des rechargements par mois. Représente le nombre de rechargements par mois sur la période sélectionnée. (Non cumulée)" />
                <StatsWidget type={"pie"} title="Types d'accés" data={presenceData} loading={loading} tooltip="Répartition des accès par type. basé sur les personnes ayant badgé à la salle ou à un cours." />
                <StatsWidget type={"pie"} title="Périodes de fréquentation" data={presencePeriodData} loading={loading} tooltip="Répartition des accès par période. Représente le nombre d'accès par période sur la période sélectionnée." />
                <StatsWidget type={"bar"} title="Fréquentation moyenne" data={frequencyData} loading={loading} tooltip="Moyenne des fréquentations sur la période sélectionnée. Représenté par jour de la semaine, un jour est la moyenne de la présence sur ce jour pour la période sélectionnée. Basé sur les personnes ayant badgé à la salle ou à un cours." />
                <StatsWidget type={"pie"} title="Répartition des réservations" data={reservationData} loading={loading} tooltip="Répartition des réservations par sport. Représente le nombre de réservations par sport sur la période sélectionnée." />
                <StatsWidget type={"pie"} title="Périodes des cours réservés" data={reservationPeriodData} loading={loading} tooltip="Répartition des réservations par période. Représente le nombre de réservations par période sur la période sélectionnée." />
            </Grid>
        </MainContainer >
    );
}