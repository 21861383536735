import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";
import { transparentize } from '../utils';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys)
const outlineColor = transparentize("blue.500", .16);

/**
 * Permet de surcharger le style du composant "Input"
 */
const outline = definePartsStyle({
  field: {
    borderRadius: "xl",
    border: "1px",
    bg: "background.input",
    borderColor: "border.input.default",
    _hover: {
      borderColor: "border.input.hovered",
    },
    _focus: {
      borderColor: "border.input.focused",
      outline: "3px solid",
      outlineColor,
    },
    _placeholder: {
      opacity: 1,
      color: "text.placeholder.default"
    },
    boxShadow: "initial!important",
    _disabled: {
      opacity: 1,
      bg: "background.disabled",
      color: "text.disabled"
    },

  },
});

const sizes = {
  md: definePartsStyle({
    field: defineStyle({
      height: "40px"
    })
  })
}

export const Input = defineMultiStyleConfig({
  sizes,
  variants: {
    outline
  },
  defaultProps: {
    variant: "outline",
    size: "md"
  }
})