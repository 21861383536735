import { Stack, SystemStyleObject, Text, Tooltip, Badge } from "@chakra-ui/react";
import { ReactElement, useMemo, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { countDemands } from "@/api";
import UserSession from "@/Classes/UserSession";

export type SidenavLinkIconCallback = ({
	isActive,
}: {
	isActive: boolean;
}) => ReactElement;

export type SidenavLinkProps = {
	to: string;
	label: string;
	icon: SidenavLinkIconCallback;
	isCollapsed?: boolean;
};

export default function SidenavLink(props: SidenavLinkProps) {
	const { to, icon, label, isCollapsed } = props;
	const [count, setCount] = useState(0);
	const [token] = useState(UserSession.getSession().getToken() as string | null);

	const collapsedStyle: SystemStyleObject = useMemo(
		() => ({
			px: isCollapsed ? "0" : "4",
			w: isCollapsed ? "42px" : null,
			spacing: isCollapsed ? "0" : "6",
			justifyContent: isCollapsed ? "center" : null,
		}),
		[isCollapsed]
	);

	useEffect(() => {
		let interval: NodeJS.Timeout;

		if (to === "assign-cards") {
			if (token !== null) {
				countDemands(token as string).then((res) => {
					setCount(res.data.demands);
				});

				interval = setInterval(() => {
					countDemands(token as string).then((res) => {
						setCount(res.data.demands);
					});
				}, 2000);

			}
		}

		return () => {
			clearInterval(interval);
		}

	}, [to, token]);







	const element = useMemo(
		() => (
			<NavLink to={to}>
				{(({ isActive }: { isActive: boolean }) => (
					<Stack
						direction="row"
						alignItems="center"
						h="42px"
						borderRadius="lg"
						bg={isActive ? "lightGrey.50" : null}
						transition="all .2s ease"
						overflow="hidden"
						_dark={{
							bg: isActive ? "darkGrey.800" : null,
							_hover: {
								bg: "darkGrey.800",
							},
						}}
						_hover={{
							bg: "lightGrey.50",
						}}
						sx={collapsedStyle}
						position={"relative"}
					>
						{icon({ isActive })}

						{!isCollapsed && (
							<Text color={isActive ? null : "darkGrey.50"} fontSize="md">
								{label}
							</Text>
						)}

						{((to === "assign-cards") && (count > 0) && (
							<Badge color={"black"} bg="#e0e000" lineHeight={1.1} variant="solid" ml="1" fontSize="0.8em" px="2" py="1" borderRadius="full" position="absolute" top="0" right="0">
								{count}
							</Badge>
						))}
					</Stack>

				))}
			</NavLink>
		),
		[icon, isCollapsed, label, to, collapsedStyle, count]
	);

	if (isCollapsed) {
		return (
			<Tooltip label={label} placement="right">
				{element}
			</Tooltip>
		);
	}

	return element;
}
