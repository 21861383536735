import Popup from "@/components/Popup"
import { IconButton, useDisclosure } from "@chakra-ui/react"
import { Trash } from "react-feather"

interface DeleteProps {
    validate: () => void
}

export default function DeleteSalle({ validate }: DeleteProps) {

    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <>
            <IconButton aria-label="Supprimer" title="Supprimer la salle" icon={<Trash stroke="red" />} variant='outline' colorScheme="red" onClick={onOpen} maxWidth="80px" />
            <Popup closeLabel="Annuler" validateLabel="Supprimer" close={onClose} validate={validate} isVisible={isOpen} title="Supprimer">
                <p>Êtes-vous sûr de vouloir supprimer cette salle ?</p>
            </Popup>
        </>
    )
}