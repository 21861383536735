import UserSession from "@/Classes/UserSession"
import { addSalle } from "@/api"
import { errorMessage } from "@/utils/errorMessage"
import { Button, useDisclosure, useToast, Text, Input } from "@chakra-ui/react"
import { useState } from "react"
import { Plus } from "react-feather"
import Popup from "@/components/Popup"


export default function AddSalle({ validate }: { validate: (salle: any) => void }) {
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [handleSalleName, setHandleSalleName] = useState<string>("")

  const updateInput = (e) => {
    switch (e.target.name) {
      case 'salle_name':
        setHandleSalleName(e.target.value)
        break
    }
  }

  const addSalleAction = async () => {
    if (UserSession.getSession() != null) {
      const response = await addSalle(UserSession.getSession()?.getToken() as string, handleSalleName)
      if (response.success) {
        toast({
          title: "Utilisateur ajouté",
          description: "La salle a été ajouté avec succès",
          status: "success",
          duration: 9000,
          isClosable: true,
        })
        onClose()
        validate({
          Id: response.data.salle_id,
          Nom: handleSalleName,
        })
      } else {
        toast({
          title: "Erreur lors de l'ajout'",
          description: errorMessage(response.data.error_code),
          status: "error",
          duration: 9000,
          isClosable: true,
        })
      }
    }
  }

  return (
    <>
      <Button leftIcon={<Plus />} variant='primary' color="white" onClick={(UserSession.getSession() != null && UserSession.getSession()?.getRole() as string !== "collaborateur") ? onOpen : null}>Ajouter une nouvelle salle</Button>
      <Popup validateLabel="Ajouter une nouvelle salle" closeLabel="Annuler" close={onClose} validate={() => { addSalleAction(); onClose() }} isVisible={isOpen} title="Ajouter une salle">
        <Text fontSize='xl' color='#919CAC' className='entries' mb='0.4rem' pb='2'>
          <span>Nom</span>
          <span style={{ color: 'red' }}>*</span>
        </Text>
        <Input name='salle_name' onChange={updateInput} />

      </Popup>
    </>
  )
}