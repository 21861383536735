import { ComponentProps, FunctionComponent, useContext, useEffect, useRef } from "react";
import Confirm, { ConfirmDialogProps } from "./Confirm";
import DialogContext from "./dialogContext";
import Form, { FormDialogProps } from "./Form";
import Loading, { LoadingDialogProps } from "./Loading";
import { BaseDialogProps } from "./types";

let dialogKey = 0;

type UseDialogReturns = {
  open(): void;
  close(): void;
  loading: {
    start(): void;
    stop(): void;
  };
};

export namespace Dialog {
  export function useConfirm(props: ConfirmDialogProps) {
    return useDialog(Confirm, props);
  }

  export function useForm<F = any>(props: FormDialogProps<F>) {
    return useDialog(Form<F>, props);
  }

  export function useLoading(props: LoadingDialogProps) {
    return useDialog(Loading, props);
  }
}

export function useDialog<C extends FunctionComponent<P>, P extends BaseDialogProps>(
  component: C,
  props: ComponentProps<C>
): UseDialogReturns {
  // Création de la clé unique
  const key = useRef(`@chakra-dialog-${dialogKey++}`);
  const dialogContext = useContext(DialogContext);

  // Surchage des props avec la méthode "close" pour que la dialog se ferme et invoque la méthode "onCancel" fournie
  const dialogProps = {
    ...props,
    onCancel() {
      close();

      if (props.onCancel) {
        props.onCancel();
      }
    }
  };

  // Ouverture de la dialog
  function open() {
    dialogContext.update(key.current, { isOpen: true });
  }

  // Fermeture de la dialog
  function close() {

    // Si un chargement est en cours on l'arrête
    if (props.isLoading) {
      props.isLoading = false;
    }

    dialogContext.update(key.current, { isOpen: false });
  }

  // Démarrage du chargement et rendu de la dialog
  function start() {
    props.isLoading = true;
    dialogContext.update(key.current, { props: dialogProps });
  }

  // Arrêt du chargement et rendu de la dialog
  function stop() {
    props.isLoading = false;
    dialogContext.update(key.current, { props: dialogProps });
  }

  // Ajoute la dialog dans la liste du context
  useEffect(() => {
    dialogContext.add(key.current, component, dialogProps);
  }, []);

  // Lance un nouveau rendu de la dialog pour afficher l'état de chargement
  useEffect(() => {
    dialogContext.update(key.current, { props: dialogProps });
  }, [props.isLoading]);

  return {
    open,
    close,

    // Contrôleurs pour l'état de chargement
    loading: {
      start,
      stop,
    },
  };
}