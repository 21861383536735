import { forwardRef, SVGProps } from "react";

export default forwardRef<
    SVGSVGElement,
    { color?: string; size?: number } & SVGProps<SVGSVGElement>
>(({ color = "currentColor", size = 24, ...rest }, ref) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={size}
            height={size}
            fill="none"
            stroke={color}
            strokeWidth="1.75"
        >
            <g >
                <line x1=".88" y1="7.02" x2="23.06" y2="7.02" />
                <path d="m23.06,12.27V2.92c0-1.09-.84-2.05-2.05-2.05H2.92C1.72.88.88,1.84.88,2.92v12.06c0,1.09.84,2.05,2.05,2.05h5.08" />
                <path d="m20.84,15.83c0,2.76-2.37,5-5.13,5s-5-2.11-5.13-4.61v-.39c0-2.76,2.37-5,5.13-5h.79c2.5.39,4.34,2.5,4.34,5Z" />
                <line x1="12.15" y1="12.27" x2="19.26" y2="19.38" />
            </g>
        </svg>
    );
});
