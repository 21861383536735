import UserSession from "@/Classes/UserSession"
import Popup from "@/components/Popup"
import { errorMessage } from "@/utils/errorMessage"
import { IconButton, Input, useDisclosure, useToast, Text, Textarea } from "@chakra-ui/react"
import { useState } from "react"
import { Edit2 } from "react-feather"
import { updateSport } from "@/api"

interface ModifyProps {
    id: string
    name: string
    description: string
    updatedSport: (sport: any) => void
}

export default function ModifySport({ id, name, description, updatedSport }: ModifyProps) {
    const toast = useToast()
    const { isOpen, onOpen, onClose } = useDisclosure()

    const [handleSportName, setHandleSportName] = useState<string>(name)
    const [handleSportDesc, setHandleSportDesc] = useState<string>(description)

    const updateInput = (e) => {
        switch (e.target.name) {
            case 'sport_name':
                setHandleSportName(e.target.value)
                break
            case 'sport_desc':
                setHandleSportDesc(e.target.value)
                break
        }
    }

    const update = async () => {
        if (UserSession.getSession() != null) {
            const response = await updateSport(UserSession.getSession()?.getToken() as string, id, handleSportName, handleSportDesc)
            if (response.success) {
                toast({
                    title: "Utilisateur modifié",
                    description: "Le sport a été modifié avec succès",
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                })
                onClose()
                updatedSport({
                    Id: id,
                    Nom: handleSportName,
                    Description: handleSportDesc,
                })
            } else {
                toast({
                    title: "Erreur lors de la modification",
                    description: errorMessage(response.data.error_code),
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                })
            }
        }
    }

    return (
        <>
            <IconButton aria-label="Modifier" title="Modifier le sport" icon={<Edit2 color="white" />} variant='outline' colorScheme="blue" onClick={onOpen} maxWidth="75px" />
            <Popup validateLabel="Modifier" closeLabel="Annuler" close={onClose} validate={() => { update(); onClose() }} isVisible={isOpen} title="Modifier le sport">
                <Text fontSize='xl' color='#919CAC' className='entries' mb='0.4rem' pb='2'>
                    <span>Nom</span>
                </Text>
                <Input name='sport_name' onChange={updateInput} defaultValue={name} />
                <Text fontSize='xl' color='#919CAC' className='entries' mb='0.4rem' pb='2' pt='3'>
                    <span>Description</span>
                </Text>
                <Textarea minHeight="100px" name='sport_desc' onChange={updateInput} defaultValue={description} />

            </Popup>
        </>
    )
}