import axios from 'axios'
import UserSession from '@/Classes/UserSession'
import { saveAs } from 'file-saver';


const ip = window.location.hostname
const urlAPI = `https://${ip}/api/`
// const urlAPI = `http://${ip}:3001/`
const instance = axios.create({
    baseURL: urlAPI
})

export const checkResponse = (response: any) => {
    if (response.status === 200) {
        if (response.data.success) {
            return response.data
        } else {
            if (response.data.message.includes('Token invalid') || response.data.message.includes('Token expired') || response.data.message.includes('You are banned')) {
                UserSession.clearSession()
                window.location.reload()
            } else {
                return response.data
            }
        }
    } else {
        return response.data
    }
}

export const fetchRoute = async (method: string, route: string, payload: any, session_token: string) => {
    try {
        switch (method) {
            case 'GET':
                const response = await instance.get(route, { headers: { Authorization: `Bearer ${session_token}` } })
                return checkResponse(response)
            case 'POST':
                const responsePost = await instance.post(route, payload, { headers: { Authorization: `Bearer ${session_token}` } })
                return checkResponse(responsePost)
            default:
                return { success: false, message: 'Method not found' }
        }
    } catch (e) {
        return { success: false, message: e.message }
    }
}


export const login = async (email: string, password: string) => {

    const preflight = await fetchRoute('POST', 'login', { username: email, password: password }, '')

    if (preflight.success) {
        const access_token = preflight.data.access_token
        const response = await fetchRoute('POST', 'access', { username: email, password: password }, access_token)
        return response
    } else {
        return preflight
    }
}

export const createAccount = async (session_token: string, email: string | null, firstname: string | null, lastname: string | null, phone: string | null, role: string, forfait: any[][], irm: string) =>
    await fetchRoute('POST', 'user/create', { email: email, firstname: firstname, lastname: lastname, phone: phone, role: role, forfait: forfait, irm: irm }, session_token)

export const createCoach = async (session_token: string, email: string | null, firstname: string | null, lastname: string | null, phone: string | null, irm: string) =>
    await fetchRoute('POST', 'coach/create', { email: email, firstname: firstname, lastname: lastname, phone: phone, irm: irm }, session_token)

export const userList = async (session_token: string) =>
    await fetchRoute('GET', 'user/all', {}, session_token);

export const getSelfBalance = async (session_token: string) =>
    await fetchRoute('GET', 'user/balance', {}, session_token);

export const checkSession = async (session_token: string) =>
    await fetchRoute('GET', `session/${session_token}`, {}, session_token);

export const getCourses = async (session_token: string) =>
    await fetchRoute('GET', 'cours/all', {}, session_token);

export const userCourses = async (session_token: string) =>
    await fetchRoute('GET', 'user/cours', {}, session_token);

export const reserveCourse = async (session_token: string, course_id: number) =>
    await fetchRoute('GET', `user/reservation/${course_id}`, {}, session_token);

export const cancelReserveCourse = async (session_token: string, course_id: number) =>
    await fetchRoute('POST', 'courses/reserve/cancel', { course_id: course_id }, session_token);

export const cancelCourse = async (session_token: string, course_id: number) =>
    await fetchRoute('GET', `cours/${course_id}/remove`, {}, session_token);

export const updateCourse = async (session_token: string, course_id: number, course_name: string, course_date_start: string, course_duree: number, cours_max_participants: number, cours_salle_id: number, cours_sport_id: number, cours_teacher_uuid: string) =>
    await fetchRoute('POST', `cours/${course_id}/update`, {
        name: course_name,
        date_start: course_date_start,
        duree: course_duree,
        max_participants: cours_max_participants,
        salle_id: cours_salle_id,
        sport_id: cours_sport_id,
        teacher_id: cours_teacher_uuid
    }, session_token);

export const recredit = async (session_token: string, rechargement_type_id: number) =>
    await fetchRoute('POST', 'user/recredit', { rechargement_type_id: rechargement_type_id }, session_token);

export const updateUser = async (session_token: string, uuid: string, email: string, firstname: string, lastname: string, phone: string, irm: string) =>
    await fetchRoute('POST', `user/update`, { uuid: uuid, email: email, firstname: firstname, lastname: lastname, phone: phone, irm: irm }, session_token);

export const updateSelfUser = async (session_token: string, formdata: any) =>
    await fetchRoute('POST', `user/self/update`, formdata, session_token);

export const getRechargementTypes = async (session_token: string) =>
    await fetchRoute('GET', 'rechargement_types/all', {}, session_token);

export const getAllReservations = async (session_token: string) =>
    await fetchRoute('GET', 'reservations/all', {}, session_token);

export const getOwnReservations = async (session_token: string) =>
    await fetchRoute('GET', 'user/reservations', {}, session_token);

export const updatePassword = async (session_token: string, new_password: string) =>
    await fetchRoute('GET', `update/password/${new_password}`, {}, session_token);

export const addCourse = async (session_token: string, course_name: string, course_date_start: string, course_teacher_id: string, course_salle_id: number, course_sport_id: number, course_duree: number, course_max_participants: number | 10) =>
    await fetchRoute('POST', 'cours/add', { cours_name: course_name, cours_date_start: course_date_start, cours_teacher_uuid: course_teacher_id, cours_salle_id: course_salle_id, cours_sport_id: course_sport_id, cours_duree: course_duree, cours_max_participants: course_max_participants }, session_token);

export const getTeachers = async (session_token: string) =>
    await fetchRoute('GET', 'profs/all', {}, session_token);

export const getSports = async (session_token: string) =>
    await fetchRoute('GET', 'sports/all', {}, session_token);

export const addSport = async (session_token: string, sport_name: string, sport_desc: string) =>
    await fetchRoute('POST', `sports/add`, { sport_name: sport_name, sport_desc: sport_desc }, session_token);

export const updateSport = async (session_token: string, sport_id: string, sport_name: string, sport_desc: string) =>
    await fetchRoute('POST', `sports/update/${sport_id}`, { sport_name: sport_name, sport_desc: sport_desc }, session_token);

export const removeSport = async (token: string, sport_id: string) =>
    await fetchRoute('GET', `sports/remove/${sport_id}`, {}, token);

export const getSalles = async (session_token: string) =>
    await fetchRoute('GET', 'salles/all', {}, session_token);

export const addSalle = async (session_token: string, salle_name: string) =>
    await fetchRoute('POST', `salles/add`, { salle_name: salle_name }, session_token);

export const updateSalle = async (session_token: string, salle_id: string, salle_name: string) =>
    await fetchRoute('POST', `salles/update/${salle_id}`, { salle_name: salle_name }, session_token);

export const removeSalle = async (token: string, salle_id: string) =>
    await fetchRoute('GET', `salles/remove/${salle_id}`, {}, token);

export const addCard = async (token: string, reader_id: string, user_uuid: string) =>
    await fetchRoute('POST', 'card/add', { lecteur_id: reader_id, user_uuid: user_uuid }, token);

export const deviceList = async (token: string) =>
    await fetchRoute('GET', 'devices/available', {}, token);

export const exportDataRh = async (token: string, date_start: string, date_end: string) => {
    const response = await instance.post('export', { date_start: date_start, date_end: date_end }, { headers: { Authorization: `Bearer ${token}` }, responseType: 'blob' });

    if (response.status === 200) {
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));

        return {
            data: response.data,
            dl_url: downloadUrl
        }
    } else {
        return response.data
    }
}

export const userCards = async (token: string) =>
    await fetchRoute('GET', 'card/all', {}, token);

export const cancelReservation = async (token: string, reservationId: number) =>
    await fetchRoute('GET', `user/reservation/cancel/${reservationId}`, {}, token);

export const refillCredits = async (token: string) =>
    await fetchRoute('GET', 'user/recharge', {}, token);

export const removeUser = async (token: string, user_uuid: string) =>
    await fetchRoute('GET', `user/remove/${user_uuid}`, {}, token);

export const forgottenPassword = async (email: string) =>
    await fetchRoute('GET', `reset/password/${email}`, {}, '');

export const endDateMembership = async (token: string) =>
    await fetchRoute('GET', 'user/abonnement/end', {}, token);

export const refillOfTheMonth = async (token: string) =>
    await fetchRoute('GET', 'abonnement/refills', {}, token);

export const coursesTaken = async (token: string) =>
    await fetchRoute('GET', 'user/cours/done', {}, token);

export const futureRenewals = async (token: string) =>
    await fetchRoute('GET', 'abonnement/renewals', {}, token);

export const requestPass = async (token: string, type: string) => {
    if (type === "apple") {
        const response = await instance.get(`request_pass/${type}`, { headers: { Authorization: `Bearer ${token}` }, responseType: 'blob' });
        saveAs(response.data, `pass.pkpass`);
    }
    else if (type === "google") {
        const response = await instance.get(`request_pass/${type}`, { headers: { Authorization: `Bearer ${token}` } });
        return { success: true, url: response.data.data.url }
    }
    else {
        return { success: false }
    }

    return { success: true }
}


export const getUserCards = async (token: string) =>
    await fetchRoute('GET', 'user/cards', {}, token);

export const askForCard = async (token: string) =>
    await fetchRoute('GET', 'user/card/ask', {}, token);

export const countDemands = async (token: string) =>
    await fetchRoute('GET', 'cards/asks/count', {}, token);

export const countPhysicalCards = async (token: string) =>
    await fetchRoute('GET', 'cards/physical/count', {}, token);

export const countSalleUsers = async (token: string) =>
    await fetchRoute('GET', 'subscribers/count/salle', {}, token);

export const countCoursUsers = async (token: string) =>
    await fetchRoute('GET', 'subscribers/count/cours', {}, token);

export const countPremiumUsers = async (token: string) =>
    await fetchRoute('GET', 'subscribers/count/premium', {}, token);

export const cancelCardAssocation = async (token: string, device: string) =>
    await fetchRoute('GET', `card/cancel/${device}`, {}, token);

export const cancelCardAsk = async (token: string, ask_id: number) =>
    await fetchRoute('POST', `card/asks/cancel`, { ask_id: ask_id }, token);

export const resiliateOwnSubscription = async (token: string) =>
    await fetchRoute('GET', `user/abonnement/stop`, {}, token);

export const banUser = async (token: string, user_uuid: string) =>
    await fetchRoute('GET', `user/ban/${user_uuid}`, {}, token);

export const unbanUser = async (token: string, user_uuid: string) =>
    await fetchRoute('GET', `user/unban/${user_uuid}`, {}, token);

export const disableCard = async (token: string, user_uuid: string) =>
    await fetchRoute('GET', `card/disable/${user_uuid}`, {}, token);

export const subscribeToGym = async (token: string) =>
    await fetchRoute('GET', `/user/abonnement/1`, {}, token);

export const mongoGet = async (token: string, collection: string, time_start: number, time_end: number) =>
    await fetchRoute('GET', `mongo/get/${collection}/${time_start}:${time_end}`, {}, token);
