import Select, { SelectProps } from "@/components/Select";
import { Text } from "@chakra-ui/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

type AppearanceSelectProps<T> = {} & SelectProps<T>;

export default function AppearanceSelect<T>(props: AppearanceSelectProps<T>) {
	const { control, name, value, isRequired, defaultValue, ...other } = props;
	const { t } = useTranslation();

	const options = useMemo(
		() =>
			// Ajouter "light" quand le thème sera prêt
			["dark", "light"].map((mode) => ({
				label: t(mode),
				value: mode,
			})),
		[]
	);

	return (
		<Select
			placeholder={t("Select appearance")}
			name={name}
			defaultValue={value}
			control={control}
			isRequired={isRequired}
			noOptionsMessage={() => <Text>No appearance is currently available</Text>}
			options={options}
			{...other}
		/>
	);
}
