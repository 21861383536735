import { Box, IconButton, IconProps, Text, Skeleton, Stack, Icon, Tooltip as Ttip } from "@chakra-ui/react";
import { ReactElement } from "react";
import { Line, Pie, Bar } from 'react-chartjs-2';
import { faker } from "@faker-js/faker";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    BarElement,
    Filler,

} from 'chart.js';
import { Info } from "react-feather";

type WidgetProps = {
    children?: ReactElement
    type: "line" | "bar" | "pie" | "doughnut" | "polarArea" | "bubble" | "scatter"
    title: string
    data: { labels: string[], datasets: { label: string, data: number[], borderColor: string | string[], backgroundColor: string | string[] }[] }
    loading?: boolean
    tooltip?: string
    stack?: boolean | false
}

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    BarElement,
    Filler
);

export default function StatsWidget(props: WidgetProps) {

    return (
        <Box border='2px solid #1A1E23' w='xs' borderRadius="10px" minW={200} width={"100%"} p={"0.5rem"}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" marginBlock={"0.5rem"} paddingInline={"0.5rem"}>
                <Text fontSize='lg' fontWeight='bold' lineHeight={1}>{props.title}</Text>
                <Ttip hasArrow label={props.tooltip} fontSize="sm" placement="top" bg={"gray.700"} style={{ borderRadius: "10px" }}>
                    <Icon as={Info} _hover={{ color: "#fff", cursor: "pointer" }} color="#6A87FF" />
                </Ttip>
            </Stack>
            <Skeleton isLoaded={!props.loading}>
                {(!props.loading && (
                    <Box h={200} marginBlock={"0.5rem"}>

                        {props.type === "line" ?
                            <Line data={props.data} options={{
                                responsive: true,
                                maintainAspectRatio: false,

                                plugins: {
                                    legend: {
                                        display: true,
                                        position: "bottom",
                                        labels: {
                                            color: '#fff',
                                            font: {
                                                size: 10,
                                            }
                                        }
                                    },
                                    title: {
                                        display: false,
                                    },


                                },
                                scales: {
                                    x: {
                                        display: true,

                                    },
                                    y: {
                                        display: true,
                                        stacked: true,
                                        ticks: {
                                            precision: 0,
                                        }
                                    },

                                },
                            }} />
                            : null}

                        {props.type === "bar" ?
                            <Bar data={props.data} options={{
                                responsive: true,
                                maintainAspectRatio: false,
                                plugins: {
                                    legend: {
                                        display: true,
                                        position: "bottom",
                                        labels: {
                                            color: '#fff',
                                            font: {
                                                size: 10,
                                            }
                                        }
                                    },
                                    title: {
                                        display: false,
                                    },
                                },
                                scales: {
                                    x: {
                                        display: true,
                                        stacked: props.stack ? true : false,
                                    },
                                    y: {
                                        display: true,
                                        beginAtZero: true,
                                        stacked: props.stack ? true : false,
                                        ticks: {
                                            precision: 0,
                                        }
                                    },
                                },
                            }} />
                            : null}

                        {props.type === "pie" ?
                            <Pie data={props.data} options={{
                                responsive: true,
                                maintainAspectRatio: false,
                                plugins: {
                                    legend: {
                                        display: true,
                                        position: "right"
                                    },
                                    title: {
                                        display: false,
                                    },
                                },
                                scales: {
                                    x: {
                                        display: false,
                                    },
                                    y: {
                                        display: false,
                                    },
                                },
                            }} />
                            : null}
                    </Box>
                )) || (
                        <Box h={200}>
                        </Box>
                    )}
            </Skeleton>
        </Box>
    )
}