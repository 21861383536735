import {
	IconButton,
	Skeleton,
	Stack,
	SystemStyleObject,
	Table,
	TableContainer,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
} from "@chakra-ui/react";
import {
	ColumnDef,
	flexRender,
	getCoreRowModel,
	getSortedRowModel,
	SortingState,
	useReactTable,
} from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { ArrowDown, ArrowUp } from "react-feather";

export type DataTableProps<Data extends object> = {
	data: Data[];
	columns: ColumnDef<Data, any>[];
	loading?: boolean;
	sx?: SystemStyleObject;
};

export function DataTable<Data extends object>(props: DataTableProps<Data>) {
	const { data, columns, loading, sx = {} } = props;
	const [sorting, setSorting] = useState<SortingState>([]);

	const table = useReactTable({
		columns,
		data,
		getCoreRowModel: getCoreRowModel(),
		onSortingChange: setSorting,
		getSortedRowModel: getSortedRowModel(),
		state: {
			sorting
		},

	});

	const headerGroups = table.getHeaderGroups();
	const rowModel = table.getRowModel();


	return (
		<TableContainer
			width="100%"
			borderRadius="md"
			display="flex"
			flexDirection="column"
			sx={sx}
		>
			<Table flex="1">
				<Thead position="sticky" top={0} zIndex="docked">
					{headerGroups?.map((headerGroup) => (
						<Tr key={headerGroup.id}>
							{headerGroup?.headers.map((header) => {
								const meta: any = header?.column?.columnDef?.meta;
								const isSorted = header?.column?.getIsSorted();

								return (
									<Th
										key={header.id}
										onClick={header?.column?.getToggleSortingHandler()}
										isNumeric={meta?.isNumeric}
										borderColor="border.default"
										position="relative"
										cursor="pointer"
										textTransform="initial"
										fontSize="sm"
										fontWeight="400"
										color="darkGrey.50"
										px="4"
										sx={{
											"&:hover": {
												button: {
													opacity: 1,
												},
											},
										}}
									>
										{flexRender(
											header?.column?.columnDef?.header,
											header?.getContext()
										)}

										<IconButton
											position="absolute"
											top="0"
											bottom="0"
											right="0"
											display="flex"
											alignItems="center"
											transition="all .2s ease"
											opacity={isSorted ? 1 : 0}
											aria-label={
												isSorted === "desc"
													? "sorted descending"
													: "sorted ascending"
											}
											variant="link"
											icon={isSorted === "desc" ? <ArrowDown /> : <ArrowUp />}
										/>
									</Th>
								);
							})}
						</Tr>
					))}
				</Thead>
				<Tbody>
					{rowModel.rows.map((row, index) => {
						const odd = index % 2 === 0;
						const cells = row.getVisibleCells();

						return (
							<Tr
								key={row.id}
								bg={odd ? null : "background.table.stripped"}
								borderRadius="xl"
							>
								{cells.map((cell, index) => {
									const meta: any = cell.column.columnDef.meta;
									return (
										<Td
											key={cell.id}
											px="4"
											isNumeric={meta?.isNumeric}
											border="0"
										>
											{flexRender(cell.column.columnDef.cell, cell.getContext())}
										</Td>
									);
								})}
							</Tr>
						);
					})}
				</Tbody>
			</Table>
			{loading && (
				<Stack mt="2">
					<Skeleton height="40px" />
					<Skeleton height="40px" />
					<Skeleton height="40px" />
				</Stack>
			)}
		</TableContainer>
	);
}
