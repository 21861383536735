import {
	Box,
	BoxProps,
	IconButton,
	Input,
	InputGroup,
	InputLeftElement,
	InputRightElement,
} from "@chakra-ui/react";
import { useCallback, useRef, useState } from "react";
import { Search as SearchIcon, X } from "react-feather";

export type SearchProps = {
	onQuery(query: string): void;
	placeholder: string;
} & BoxProps;

export default function Search(props: SearchProps) {
	const { onQuery, placeholder, ...other } = props;
	const [innerValue, setInnerValue] = useState<string>("");
	const ref = useRef<HTMLInputElement>();

	const clear = useCallback(() => {
		setInnerValue("");
		ref.current.focus();

		setTimeout(() => {
			if (onQuery) onQuery("");
		});
	}, [onQuery, ref]);

	return (
		<Box>
			<InputGroup>
				<InputLeftElement>
					<SearchIcon size={18} />
				</InputLeftElement>
				<Input
					variant="outline"
					ref={ref}
					value={innerValue}
					onChange={(e) => {
						setInnerValue(e.target.value);

						setTimeout(() => {
							if (onQuery) onQuery(e.target.value);
						});
					}}
					placeholder={placeholder}
				/>
				{innerValue?.length > 0 && (
					<InputRightElement>
						<IconButton
							variant="ghost"
							colorScheme="gray"
							aria-label="Clear"
							icon={<X />}
							onClick={clear}
						/>
					</InputRightElement>
				)}
			</InputGroup>
		</Box>
	);
}
