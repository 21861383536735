import { useColorModeValue } from "@chakra-ui/react";

export type BrandLogoProps = {
	size?: number;

};

export default function BrandLogo(props: BrandLogoProps) {
	const { size = 110 } = props;
	const fill = useColorModeValue("dark", "white");

	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 887.48 377.5" fill="#fff" width={size}>
			<g>
				<path
					d="M876.08,108.39c-15.53,2.71-19.58,6.76-22.29,22.29-.25,1.44-2.34,1.44-2.59,0-2.71-15.53-6.76-19.58-22.29-22.29-1.44-.25-1.44-2.34,0-2.59,15.53-2.71,19.58-6.76,22.29-22.29,.25-1.44,2.34-1.44,2.59,0,2.71,15.53,6.76,19.58,22.29,22.29,1.44,.25,1.44,2.34,0,2.59Z" />
				<g >
					<path
						d="M869.43,69.04c-9.49-25.61-51.86-28.18-80.21-27.65-4.39,.07-8.47,.25-12.05,.39-.7,.03-1.37,.11-2.07,.17-51.4,3.94-102.28,16.34-149.71,36.86-3.44,1.48-6.85,2.99-10.26,4.57-9.35,4.32-18.55,8.96-27.58,13.91-1.41,.77-2.81,1.55-4.18,2.32-2.78,1.62-5.45,3.2-8.01,4.81-22.91,14.26-38.58,28.6-47.33,41.88h11.91c9.21-11.31,21.19-21.29,34.22-30.08,3.69-2.49,7.45-4.88,11.24-7.17,9.49-5.73,19.25-10.93,28.78-15.63,1.72-.84,3.41-1.69,5.09-2.49,4.53-2.18,8.96-4.29,13.25-6.25,43.99-18.97,95.39-35.77,145.46-39.84,4.53-.39,9.03-.63,13.53-.77,12.19-.42,24.28,0,36.12,1.37,15.64,2.53,35.31,7.24,41.49,23.72,2.64,10.65-1.23,21.15-8.33,30.92-4.08,5.59-9.21,10.93-14.79,15.92-8.43,7.55-17.88,14.23-26.25,19.67-4.11,2.67-7.94,5.02-11.24,7.03-.14,.07-.28,.14-.42,.25-22.56,12.65-46.31,23.29-70.83,31.83-4.43,1.58-8.89,3.06-13.35,4.46-26.49,8.4-53.76,14.4-81.2,17.95v5.8c28.11-4.6,55.79-11.77,82.64-21.29,4.39-1.58,8.75-3.2,13.11-4.88,24-9.28,47.29-20.45,69.53-33.38,.25-.11,.46-.25,.7-.39,3.23-2,6.99-4.36,11.03-7.03,8.33-5.48,17.85-12.3,26.35-19.96,5.55-5.02,10.68-10.37,14.76-15.95,7.31-9.91,11.31-20.52,8.61-31.06Z" />
				</g>
				<g>
					<g>
						<path
							d="M791.5,44.06c-.7-.91-1.48-1.79-2.28-2.67C766.63,16.02,733.74,0,697.17,0c-61.56,0-112.71,45.36-121.81,104.42-.56,3.87-.98,7.8-1.19,11.8-.14,2.32-.21,4.64-.21,6.99,0,7.91,.74,15.6,2.18,23.08h10.79c-1.58-7.45-2.39-15.18-2.39-23.08,0-4.78,.32-9.52,.88-14.16,.53-4.01,1.23-7.94,2.14-11.77,11.74-49.68,56.43-86.71,109.62-86.71,30.22,0,57.69,11.98,77.93,31.37,.98,.95,1.93,1.9,2.88,2.88,19.68,20.27,31.83,47.96,31.83,78.39,0,6.71-.6,13.31-1.72,19.71-.04,.17-.07,.32-.11,.49-9.52,52.49-55.58,92.44-110.81,92.44-19.75,0-38.33-5.1-54.46-14.09v11.98c16.44,8.12,34.92,12.68,54.46,12.68,63.63,0,116.16-48.45,122.55-110.43,.04-.1,.04-.25,.04-.35,.42-4.07,.63-8.22,.63-12.44,0-30.11-10.86-57.73-28.88-79.16Z" />
						<path
							d="M615.77,93.85c-.42,0-.84-.08-1.25-.24-1.76-.69-2.63-2.68-1.94-4.45,7.23-18.44,20.61-34.18,37.69-44.32,4.34-2.57,8.92-4.79,13.61-6.59,4.79-1.83,9.76-3.26,14.78-4.23,14.65-2.85,30.13-1.97,44.33,2.56,1.81,.58,2.8,2.51,2.23,4.31-.57,1.81-2.51,2.8-4.31,2.23-13.11-4.18-27.41-4.99-40.94-2.36-4.64,.9-9.23,2.22-13.64,3.9-4.33,1.66-8.56,3.71-12.56,6.08-15.77,9.36-28.13,23.9-34.8,40.92-.53,1.35-1.83,2.18-3.2,2.18Z" />
					</g>
				</g>
				<g>
					<g>
						<path
							d="M656.8,118c.53,5.23,7.54,7.35,11.41,4.35,1.83-1.42,3.29-5.22,6.45-8.3,.4-.39,.71-.69,1.13-1.04,3.7-3.03,8.18-4.67,13.16-4.19,1.07,.1,1.9,.28,2.88,.4-.12,.94-.92,2.26-1.27,3.21l-1.02,2.38c-.12,.3-.2,.55-.35,.86-.85,1.78-4.37,10.7-5.22,12.93-.42,1.1-.86,2.13-1.27,3.21-1.23,3.23-2.36,6.65-4.13,9.57-2.05,3.38-4.46,6.38-7.65,8.77-4.74,3.55-10.02,4.7-15.75,3.07-1.94-.55-3.01-1.54-5.34-1.58-2.5-.04-4.35,1.17-5.6,2.69l-1,1.67c-1.46,3.51,.25,7.91,3.75,9.28,1.36,.53,2.65,1.07,4.07,1.48,5.88,1.69,11.44,1.79,17.57,.45,2.76-.6,5.28-1.55,7.72-2.81,2.23-1.14,4.28-2.48,6.19-4.06,.49-.4,.91-.72,1.38-1.16,.27-.24,.38-.37,.64-.62,1.25-1.2,2.56-2.5,3.58-3.91,.34-.47,1.38-1.72,1.46-2.23,1.27-.1,3.72,.93,4.97,1.4,.57,.22,1.08,.35,1.62,.56l6.17,2.59c.98,.5,1.91,.9,2.88,1.42l7.07,4.02c.54,.32,.82,.57,1.29,.96,3.13,2.58,8.42,12.76,10.96,16.88,5.4,10.43,15.56,6.14,14.89-.47-.23-2.28-1.36-3.51-2.31-5.29-1.34-2.51-4.4-7.52-6.13-10.24-2.34-3.69-4.65-7.68-7.81-10.73-2.23-2.16-5.5-3.62-8.16-5.22-1.87-1.13-3.81-2.11-5.77-3.08-.87-.43-5.08-2.73-5.97-2.84,.09-.69,3.43-8.41,3.87-9.47,1.66-4.02,3.6-8.62,5.45-12.54l1.37-3.13c1.77,.64,7.32,.74,9.06,.57,4.55-.46,8.11-1.32,11.8-3.21,2.02-1.04,3.93-2.23,5.66-3.66,1.66-1.38,3.33-2.78,4.69-4.46,.9-1.11,1.96-2.17,2.71-3.38,1.88-3.06,5.48-6.78,2.81-10.54-1.33-1.88-3.88-3.38-6.47-3.12-1.59,.16-2.94,.51-4.08,1.43-1.91,1.56-2.93,4.16-4.49,6.07-4.68,5.76-10.71,8.16-18.08,7.11-1.6-.23-3.39-.69-4.73-1.19-2.86-1.07-5.51-2.48-8.34-3.61l-6.4-2.44c-1.52-.5-3.17-.83-4.78-1.13-6.55-1.23-13.83-.17-19.68,2.7-2.81,1.38-5.17,3.16-7.37,5.06-2.19,1.9-3.96,4.1-5.71,6.42-.87,1.15-1.61,2.42-2.36,3.66-.7,1.16-1.59,2.63-1.41,4.44h0Z" />
						<path
							d="M699.07,81.59c1.05,10.44,13.58,15.86,22.06,8.91,3.13-2.57,5.26-7.05,4.85-11.16-.4-3.94-1.92-7.01-4.85-9.4-2.5-2.04-5.89-3.43-9.29-3.08-3.15,.32-3.66,.48-6.37,1.94-1.14,.61-2.74,2.18-3.52,3.13-2.04,2.47-3.22,6.19-2.87,9.67h0Z" />
					</g>
				</g>
			</g>
			<g>
				<polygon fill="#e0e000"
					points="801.35 377.5 115.65 377.5 115.65 300.14 887.48 300.14 801.35 377.5" />
				<g>
					<g>
						<path d="M116.13,100.15h10.15v36.92h23.01v9.23h-33.16v-46.14Z" />
						<path
							d="M156.95,100.15h34.81v9.03h-24.72v9.36h21.75v9.03h-21.75v9.69h25.05v9.03h-35.14v-46.14Z" />
					</g>
					<g>
						<path
							d="M0,224.24v-.3c0-30.04,22.64-54.64,55.09-54.64,19.92,0,31.85,6.64,41.66,16.3l-14.79,17.06c-8.15-7.4-16.45-11.92-27.01-11.92-17.81,0-30.64,14.79-30.64,32.9v.3c0,18.11,12.53,33.21,30.64,33.21,12.07,0,19.47-4.83,27.77-12.38l14.79,14.94c-10.86,11.62-22.94,18.86-43.32,18.86-31.09,0-54.19-24-54.19-54.33Z" />
						<path
							d="M116.13,171.11h79.69v20.68h-56.6v21.43h49.81v20.68h-49.81v22.19h57.36v20.68H116.13v-105.65Z" />
						<path
							d="M217.31,171.11h21.43l49.51,65.05v-65.05h22.94v105.65h-19.77l-51.16-67.17v67.17h-22.94v-105.65Z" />
						<path
							d="M361.21,192.54h-32.15v-21.43h87.54v21.43h-32.14v84.22h-23.25v-84.22Z" />
						<path
							d="M434.47,171.11h48.3c13.43,0,23.85,3.77,30.79,10.71,5.89,5.89,9.06,14.19,9.06,24.15v.3c0,17.06-9.21,27.77-22.64,32.75l25.81,37.73h-27.17l-22.64-33.81h-18.26v33.81h-23.25v-105.65Zm46.79,51.32c11.32,0,17.81-6.04,17.81-14.94v-.3c0-9.96-6.94-15.09-18.26-15.09h-23.09v30.34h23.55Z" />
						<path
							d="M543.65,171.11h79.69v20.68h-56.6v21.43h49.81v20.68h-49.81v22.19h57.36v20.68h-80.45v-105.65Z" />
					</g>
					<g fill="#000">
						<path
							d="M130.77,314.25h19.3c15.56,0,26.3,10.67,26.3,24.6v.14c0,13.93-10.75,24.75-26.3,24.75h-19.3v-49.49Zm10.89,9.83v29.84h8.41c8.91,0,14.92-6.01,14.92-14.78v-.14c0-8.77-6.01-14.92-14.92-14.92h-8.41Z" />
						<path
							d="M182.34,332.56c4.88-.5,7.28-2.97,6.93-7h-4.38v-11.31h11.45v9.54c0,9.33-4.67,13.08-13.08,13.22l-.92-4.45Z" />
						<path
							d="M206.32,314.25h37.33v9.69h-26.51v10.04h23.33v9.69h-23.33v10.39h26.87v9.69h-37.69v-49.49Z" />
						<path
							d="M253.72,314.25h10.04l23.19,30.47v-30.47h10.75v49.49h-9.26l-23.97-31.46v31.46h-10.75v-49.49Z" />
						<path d="M321.13,324.29h-15.06v-10.04h41.01v10.04h-15.06v39.45h-10.89v-39.45Z" />
						<path
							d="M355.44,314.25h22.62c6.29,0,11.17,1.77,14.42,5.02,2.76,2.76,4.24,6.65,4.24,11.31v.14c0,7.99-4.31,13.01-10.61,15.34l12.09,17.68h-12.73l-10.6-15.84h-8.55v15.84h-10.89v-49.49Zm21.92,24.04c5.3,0,8.34-2.83,8.34-7v-.14c0-4.67-3.25-7.07-8.55-7.07h-10.82v14.21h11.03Z" />
						<path
							d="M423.56,313.9h10.04l21.21,49.85h-11.38l-4.52-11.1h-20.93l-4.52,11.1h-11.1l21.21-49.85Zm11.45,29.13l-6.57-16.05-6.57,16.05h13.15Z" />
						<path d="M462.97,314.25h10.89v49.49h-10.89v-49.49Z" />
						<path
							d="M486.24,314.25h10.04l23.19,30.47v-30.47h10.75v49.49h-9.26l-23.97-31.46v31.46h-10.75v-49.49Z" />
						<path
							d="M542.13,314.25h37.33v9.69h-26.51v10.04h23.33v9.69h-23.33v10.39h26.87v9.69h-37.69v-49.49Z" />
						<path
							d="M589.53,314.25h11.74l13.01,20.93,13.01-20.93h11.74v49.49h-10.82v-32.31l-13.93,21.14h-.28l-13.79-20.93v32.1h-10.68v-49.49Z" />
						<path
							d="M650.93,314.25h37.33v9.69h-26.51v10.04h23.33v9.69h-23.33v10.39h26.87v9.69h-37.69v-49.49Z" />
						<path
							d="M698.33,314.25h10.04l23.19,30.47v-30.47h10.75v49.49h-9.26l-23.97-31.46v31.46h-10.75v-49.49Z" />
						<path d="M765.74,324.29h-15.06v-10.04h41.01v10.04h-15.06v39.45h-10.89v-39.45Z" />
					</g>
				</g>
			</g>
		</svg>
	);
}
