import { AspectRatio } from "@chakra-ui/react"

export default function LogoAnimation() {
    return (
        <AspectRatio maxW='100%' ratio={21 / 9}>
            <video
                title='logo animation'
                src='https://lce.inno.freepro.com/api/cdn/videos/logo_animation.mp4'
                autoPlay
                muted
                playsInline
            />
        </AspectRatio>
    )
}