import Popup from "@/components/Popup"
import { IconButton, useDisclosure } from "@chakra-ui/react"
import { Trash } from "react-feather"

interface DeleteProps {
    validate: () => void
}


export default function DeleteUser({ validate }: DeleteProps) {

    const { isOpen, onOpen, onClose } = useDisclosure()

    //const initialRef = React.useRef(null)
    //const finalRef = React.useRef(null)

    return (
        <>
            <IconButton aria-label="Supprimer" title="supprimer l'utilisateur" colorScheme="red" icon={<Trash stroke="red" />} variant='outline' onClick={onOpen} maxWidth="80px" />
            <Popup closeLabel="Annuler" validateLabel="Supprimer" close={onClose} validate={() => validate()} isVisible={isOpen} title="Supprimer">
                <p>Êtes-vous sûr de vouloir supprimer ce collaborateur ?</p>
            </Popup>
        </>
    )
}