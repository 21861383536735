import { disableCard } from "@/api"
import { IconButton, useDisclosure, useToast } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import Popup from "../../components/Popup"
import UserSession from "@/Classes/UserSession"
import { errorMessage } from "@/utils/errorMessage"
import CancelCard from "@/components/icons/CancelCard"

export default function DisableCard({ userId, returnBool, iconColor, buttonMode }: { userId: string, returnBool: (bool: boolean, uuid: string) => void, iconColor: string, buttonMode: "ghost" | "solid" | "outline" | "link" }) {
    const toast = useToast()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [devices, setDevices] = useState([]);
    const [selectedUser, setSelectedUser] = useState("");
    const [token, setToken] = useState("")
    const [waiting, setWaiting] = useState(false);

    useEffect(() => {
        const session_token = UserSession?.getSession()?.session_token as string;
        setToken(session_token);
    }, []);

    const handleCallback = (bool: boolean, uuid: string) => {
        returnBool(bool, uuid)
    }


    useEffect(() => {
        setSelectedUser(userId)
    }, [userId])

    const handleRemove = async () => {
        setWaiting(true)
        const response = await disableCard(token, userId)
        console.log(response)
        if (response.success) {
            toast({
                title: "Carte dissociée",
                description: "La carte a bien été dissociée de l'utilisateur",
                status: "success",
                duration: 9000,
                isClosable: true,
            })
            handleCallback(true, userId)
            setWaiting(false)
            onClose()
        } else {
            toast({
                title: "Dissociation impossible",
                description: errorMessage(response?.data?.error_code) || "Une erreur est survenue : " + response?.message,
                status: "error",
                duration: 9000,
                isClosable: true,
            })
            handleCallback(false, userId)
            setWaiting(false)
            onClose()
        }

    }

    return (
        <>
            <IconButton colorScheme="orange" aria-label="Délier une carte" title="Délier une carte" icon={<CancelCard size={30} color={iconColor} />} variant={buttonMode} onClick={onOpen} maxWidth="75px" />


            <Popup closeLabel="Annuler" loading={waiting} validateLabel="Dissocier" validate={handleRemove} close={onClose} isVisible={isOpen} title="Dissocier une carte">
                <em>Vous êtes sur le point de délier la carte de l'utilisateur numéro : <strong>{selectedUser}</strong>.</em>
                <br /> <br />
                <em style={{ color: "orange" }}>Attention, cette action est irréversible.</em>
            </Popup>
        </>
    )
}