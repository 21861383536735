export function errorMessage(code: number, lang: string = 'fr') {

    const json = {
        "1": {
            "en": "Unautorized connection",
            "fr": "Connexion non autorisée"
        },
        "2": {
            "en": "Token Invalid",
            "fr": "Jeton invalide"
        },
        "3": {
            "en": "Token Expired",
            "fr": "Jeton expiré"
        },
        "4": {
            "en": "Unautorized request",
            "fr": "Requête non autorisée"
        },
        "5": {
            "en": "Coach not found",
            "fr": "Entraîneur introuvable"
        },
        "6": {
            "en": "Sport not found",
            "fr": "Sport introuvable"
        },
        "7": {
            "en": "Salle not found",
            "fr": "Salle introuvable"
        },
        "8": {
            "en": "Email already used",
            "fr": "Email déjà utilisé"
        },
        "9": {
            "en": "No user active found",
            "fr": "Aucun utilisateur actif trouvé"
        },
        "10": {
            "en": "No rechargement found",
            "fr": "Aucun rechargement trouvé"
        },
        "11": {
            "en": "No subscription found",
            "fr": "Aucun abonnement trouvé"
        },
        "12": {
            "en": "No cards found",
            "fr": "Aucune carte trouvée"
        },
        "13": {
            "en": "User has already a reservation",
            "fr": "L'utilisateur a déjà une réservation"
        },
        "14": {
            "en": "No cours found",
            "fr": "Aucun cours trouvé"
        },
        "15": {
            "en": "No place available",
            "fr": "Aucune place disponible"
        },
        "16": {
            "en": "Cours not available",
            "fr": "Cours non disponible"
        },
        "17": {
            "en": "Max number reservation",
            "fr": "Nombre maximal de réservations atteint"
        },
        "18": {
            "en": "Not enough solde",
            "fr": "Solde insuffisant"
        },
        "19": {
            "en": "No reservation active found",
            "fr": "Aucune réservation active trouvée"
        },
        "20": {
            "en": "User already have a subscription active",
            "fr": "L'utilisateur a déjà une souscription active"
        },
        "21": {
            "en": "No reservation found",
            "fr": "Aucune réservation trouvée"
        },
        "22": {
            "en": "User has already badged",
            "fr": "L'utilisateur a déjà badgé"
        },
        "23": {
            "en": "User already has card",
            "fr": "L'utilisateur a déjà une carte"
        },
        "24": {
            "en": "Card already own",
            "fr": "La carte appartient déjà à quelqu'un"
        },
        "25": {
            "en": "User not found",
            "fr": "Utilisateur introuvable"
        },
        "26": {
            "en": "No device found with such id",
            "fr": "Aucun appareil trouvé avec cet identifiant"
        },
        "27": {
            "en": "Device already used",
            "fr": "Appareil déjà utilisé"
        },
        "28": {
            "en": "Card not added, operation cancelled or card already exists",
            "fr": "Carte non ajoutée, opération annulée ou carte déjà existante"
        },
        "29": {
            "en": "Can't send mail",
            "fr": "Impossible d'envoyer un courrier"
        },
        "30": {
            "en": "No demand for this device",
            "fr": "Aucune demande pour cet appareil"
        },
        "31": {
            "en": "No device found or No demand for this device",
            "fr": "Aucun appareil trouvé ou aucune demande pour cet appareil"
        },
        "32": {
            "en": "Card already disable",
            "fr": "Carte déjà désactivée"
        },
        "33": {
            "en": "Email not found",
            "fr": "Email introuvable"
        },
        "34": {
            "en": "No refills",
            "fr": "Aucun rechargement"
        },
        "35": {
            "en": "Avatar not found",
            "fr": "Avatar introuvable"
        },
        "36": {
            "en": "User has already a pass of this type",
            "fr": "L'utilisateur possède déjà un pass de ce type"
        },
        "37": {
            "en": "Pass type not found",
            "fr": "Type de pass introuvable"
        },
        "38": {
            "en": "Card already asked",
            "fr": "Carte déjà demandée"
        },
        "39": {
            "en": "File not found",
            "fr": "Fichier introuvable"
        },
        "40": {
            "en": "Resiliation already asked",
            "fr": "Résiliation déjà demandée"
        },
        "41": {
            "en": "You are banned",
            "fr": "Vous êtes banni"
        },
        "42": {
            "en": "File is too big",
            "fr": "Le fichier est trop lourd"
        },
        "43": {
            "en": "File format not allowed",
            "fr": "Format de fichier non autorisé"
        },
    }


    try {
        return json[code][lang];
    } catch (e) {
        return false
    }
}