import { menuAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(menuAnatomy.keys)

const baseStyle = definePartsStyle({
  button: {

  },
  list: {
    borderRadius: "xl",
    p: 2,
    _dark: {
      bg: "darkGrey.800",
      borderColor: "darkGrey.600"
    }
  },
  item: {
    fontSize: "md",
    height: "40px",
    borderRadius: "lg",
    _dark: {
      bg: "darkGrey.800",
      _hover: {
        bg: "darkGrey.700"
      },
      "&:disabled": {
        bg: "darkGrey.800!important",
        color: "darkGrey.300!important"
      }
    },
  },
  groupTitle: {

  },
  command: {

  },
  divider: {

  },
})

export const Menu = defineMultiStyleConfig({ baseStyle })