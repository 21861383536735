import { Box, Button, FormControl, FormLabel, Icon, Input, Select, useDisclosure, useToast } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import { Plus } from "react-feather"
import PopupForm from "../../components/PopupForm"
import UserSession from "@/Classes/UserSession"
import { createAccount, getRechargementTypes, createCoach } from "@/api"
import { errorMessage } from "@/utils/errorMessage"


export default function AddUser({ onAddUser, userType, title }: { onAddUser: (user: any) => void, userType: "collaborateur" | "coach", title: string }) {
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [sessionToken, setSessionToken] = useState<string | null>(null)
  const [handleFirstname, setHandleFirstname] = useState<string>('')
  const [handleLastname, setHandleLastname] = useState<string>('')
  const [handleEmail, setHandleEmail] = useState<string>('')
  const [handlePhone, setHandlePhone] = useState<string>('')
  const [handleIRM, setHandleIRM] = useState<string>('')
  const [handleForfait, setHandleForfait] = useState<string>('')
  const [rechargementTypes, setRechargementTypes] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false)

  const updateInput = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
    switch (e.target.name) {
      case 'firstname':
        setHandleFirstname(e.target.value)
        break
      case 'lastname':
        setHandleLastname(e.target.value)
        break
      case 'email':
        setHandleEmail(e.target.value)
        break
      case 'phone':
        setHandlePhone(e.target.value)
        break
      case 'irm':
        setHandleIRM(e.target.value)
        break
      case 'forfait':
        console.log("coucou", e.target.value)
        setHandleForfait(e.target.value)
        break
    }
  }

  useEffect(() => {
    setSessionToken(UserSession.getSession()?.getToken() as string)
  }, [])

  useEffect(() => {
    if (sessionToken != null && userType === "collaborateur") {
      const fetchRechargementTypes = async () => {
        const response = await getRechargementTypes(sessionToken as string)
        setRechargementTypes(response.data)
      }
      fetchRechargementTypes()
    }
  }, [sessionToken])

  const returnToParent = (uuid: string) => {
    // return data of user added to parent component
    if (userType === "collaborateur") {
      const solde = (handleForfait === "standard") ? 0 : (Object.values(rechargementTypes).find((rechargementType: any) => rechargementType.rechargement_id === parseInt(handleForfait)) as any).rechargement_credits as number;
      const access = handleForfait === "standard" ? ["Salle"] : handleForfait.includes("+salle") ? ["Salle", "Cours"] : ["Cours"];


      console.log(solde, rechargementTypes, handleForfait, access)
      onAddUser({
        Uuid: uuid,
        Prénom: handleFirstname,
        Nom: handleLastname,
        Email: handleEmail,
        Crédits: solde,
        Solde: solde,
        Réservations: 0,
        Carte: [],
        Phone: handlePhone,
        Irm: handleIRM,
        Status: "available",
        Accès: access
      })
    } else {
      onAddUser({
        Uuid: uuid,
        Prénom: handleFirstname,
        Nom: handleLastname,
        Email: handleEmail,
        Phone: handlePhone,
        Irm: handleIRM,
      })
    }
  }

  const validate = async () => {
    setLoading(true)
    if (handleEmail.match(/^[a-z0-9._-]+@[a-z0-9._-]{2,}\.[a-z]{2,4}$/) === null) {
      toast({
        title: "Impossible d'ajouter le collaborateur",
        description: `${handleEmail} n'est pas une adresse email valide`,
        status: "error",
        duration: 3000,
        isClosable: true,
      })
      setLoading(false)
      return
    }
    if (handleFirstname === "" || handleLastname === "" || handleEmail === "" || handleIRM === "") {
      toast({
        title: "Impossible d'ajouter le collaborateur",
        description: "Veuillez remplir tous les champs",
        status: "error",
        duration: 3000,
        isClosable: true,
      })
      setLoading(false)
      return
    }




    if (userType === "coach") {
      const response = await createCoach(sessionToken as string, handleEmail, handleFirstname, handleLastname, handlePhone, handleIRM)
      setLoading(false)
      if (response.success) {
        onClose()
        toast({
          title: "Coach ajouté",
          description: "Le coach a bien été ajouté",
          status: "success",
          duration: 3000,
          isClosable: true,
        })
        returnToParent(response.data.uuid)
      } else {
        toast({
          title: "Impossible d'ajouter le coach",
          description: errorMessage(response.data.error_code),
          status: "error",
          duration: 3000,
          isClosable: true,
        })
      }
    } else if (userType === "collaborateur") {
      let forfait: any[];

      if (handleForfait === "standard") {
        forfait = [["access_salle", 1]]
      } else if (handleForfait.includes("+salle")) {
        forfait = [["access_salle", 1], ["access_cours", parseInt(handleForfait.split("+")[0])]]
      } else {
        forfait = [["access_cours", parseInt(handleForfait)]]
      }

      const response = await createAccount(sessionToken as string, handleEmail, handleFirstname, handleLastname, handlePhone, "collaborateur", forfait, handleIRM)
      setLoading(false)
      if (response.success) {
        onClose()
        toast({
          title: "Collaborateur ajouté",
          description: "Le collaborateur a bien été ajouté",
          status: "success",
          duration: 3000,
          isClosable: true,
        })
        returnToParent(response.data.uuid)
      } else {
        // TODO: proposer de renvoyer le mail, ou de l'envoyer manuellement
        toast({
          title: "Erreur",
          description: errorMessage(response.data.error_code) + parseInt(response.data.error_code) == 29 ? " (l'utilisateur à bien été créé mais l'envoi du mail a échoué)" : "",
          status: "error",
          duration: 3000,
          isClosable: true,
        })
      }
    }
  }



  const initialRef = React.useRef(null)
  // const finalRef = React.useRef(null)



  return (
    <>
      <Button variant='primary' onClick={onOpen} leftIcon={<Plus />}>
        {title}
      </Button>
      <PopupForm closeLabel="Annuler" validateLabel="Ajouter" close={onClose} validate={() => validate()} isVisible={isOpen} title={title} loading={loading}>

        <FormControl>
          <FormLabel>Prénom <span style={{ color: 'red' }}>*</span></FormLabel>
          <Input ref={initialRef} placeholder='Prénom' onChange={updateInput} name="firstname" />
        </FormControl>

        <FormControl mt={4}>
          <FormLabel>Nom <span style={{ color: 'red' }}>*</span></FormLabel>
          <Input placeholder='Nom' onChange={updateInput} name="lastname" />
        </FormControl>

        <FormControl mt={4}>
          <FormLabel>Email <span style={{ color: 'red' }}>*</span></FormLabel>
          <Input placeholder='Email' onChange={updateInput} name="email" />
        </FormControl>

        <FormControl mt={4}>
          <FormLabel>Téléphone</FormLabel>
          <Input placeholder='Téléphone' onChange={updateInput} name="phone" />
        </FormControl>

        <FormControl mt={4}>
          <FormLabel>IRM <span style={{ color: 'red' }}>*</span></FormLabel>
          <Input placeholder='IRM' onChange={updateInput} name="irm" />
        </FormControl>

        {userType === "collaborateur" &&
          (
            <FormControl mt={4}>
              <FormLabel>Forfait <span style={{ color: 'red' }}>*</span></FormLabel>
              <Select id='owner' placeholder="--Choisir le forfait--" onChange={updateInput} name="forfait">
                <option value="standard">Accès salle</option>
                {rechargementTypes?.map((rechargementType: any, index: number) => (
                  <option key={index} value={rechargementType.rechargement_id}>Cours ({rechargementType.rechargement_credits} crédits)</option>
                ))}
                {rechargementTypes?.map((rechargementType: any, index: number) => (
                  <option key={index} value={rechargementType.rechargement_id + "+salle"}>Salle + Cours ({rechargementType.rechargement_credits} crédits)</option>
                ))}
              </Select>
            </FormControl>
          )}
      </PopupForm>
    </>
  )
}