import UserSession from "@/Classes/UserSession"
import Popup from "@/components/Popup"
import { errorMessage } from "@/utils/errorMessage"
import { IconButton, Input, useDisclosure, useToast, Text } from "@chakra-ui/react"
import { useState } from "react"
import { Edit2 } from "react-feather"
import { updateSalle } from "@/api"

interface ModifyProps {
    id: string
    name: string
    updatedSalle: (salle: any) => void
}

export default function ModifySport({ id, name, updatedSalle }: ModifyProps) {
    const toast = useToast()
    const { isOpen, onOpen, onClose } = useDisclosure()

    const [handleSalleName, setHandleSalleName] = useState<string>(name)


    const updateInput = (e) => {
        switch (e.target.name) {
            case 'salle_name':
                setHandleSalleName(e.target.value)
                break
        }
    }

    const update = async () => {
        if (UserSession.getSession() != null) {
            const response = await updateSalle(UserSession.getSession()?.getToken() as string, id, handleSalleName)
            if (response.success) {
                toast({
                    title: "Utilisateur modifié",
                    description: "La salle a été modifié avec succès",
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                })
                onClose()
                updatedSalle({
                    Id: id,
                    Nom: handleSalleName,
                })
            } else {
                toast({
                    title: "Erreur lors de la modification",
                    description: errorMessage(response.data.error_code),
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                })
            }
        }
    }

    return (
        <>
            <IconButton aria-label="Modifier" title="Modifier la salle" icon={<Edit2 color="white" />} variant='outline' colorScheme="blue" onClick={onOpen} maxWidth="80px" />
            <Popup validateLabel="Modifier" closeLabel="Annuler" close={onClose} validate={() => { update(); onClose() }} isVisible={isOpen} title="Modifier la salle">
                <Text fontSize='xl' color='#919CAC' className='entries' mb='0.4rem' pb='2'>
                    <span>Nom</span>
                </Text>
                <Input name='salle_name' onChange={updateInput} defaultValue={name} />

            </Popup>
        </>
    )
}