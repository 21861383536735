import React, { useState } from 'react'
import { forgottenPassword } from '@/api';
import BrandLogo from "@/components/Brand";

import {
    Box,
    Text,
    Image,
    Button,
    Input,
    useToast
} from "@chakra-ui/react";
import { errorMessage } from '@/utils/errorMessage';



export default function ForgottenPassword() {
    const toast = useToast()
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)
    const [isInvalid, setIsInvalid] = useState(false)
    const [isSent, setIsSent] = useState(false)
    const image_slug = "https://lce.inno.freepro.com/api/cdn/images"


    let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    const handleEmailChange = (event: any) => {
        setEmail(event.target.value)
    }

    const askForPasswordReset = async () => {
        setLoading(true)
        if (re.test(email)) {
            try {
                const res = await forgottenPassword(email)
                if (res.success) {
                    setIsSent(true)

                    setTimeout(() => {
                        window.location.href = '/login'
                    }, 3000)
                } else {
                    setIsInvalid(true)

                    toast({
                        title: "Erreur",
                        description: errorMessage(res.data.error_code),
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                    })
                }
            } catch (error) {
                console.log(error)
            }
        } else {

            setIsInvalid(true)
        }
        setLoading(false)
    }


    return (
        <Box flex='1' display='flex' flexDirection='row'>
            <Box flex='1' h='100vh' display='flex' alignItems='center' justifyContent='center'>
                <Box display='flex' justifyContent='center' flexDirection='column' p={window.innerWidth > 500 ? '0' : '4'} w={!isSent ? '25rem' : '30rem'}>
                    <BrandLogo />
                    <br /><br />
                    {!isSent ?
                        <>
                            <Text fontSize='2xl' fontWeight='bold'>Mot de passe oublié</Text>
                            <Text fontSize='md' color='#738296'>Réinitialiser le mot de passe par mail</Text>
                            <br />
                            <Text fontSize='md' color='#919CAC' className='entries'>
                                <span>Email</span>
                                <span style={{ color: 'red' }}>*</span>
                            </Text>
                            <Input
                                type="text"
                                value={email}
                                placeholder='Entrez votre email'
                                onChange={(e) => handleEmailChange(e)}
                                h='10'
                                mt='1'
                            />
                            <Box display='flex' mt='1'>
                                {isInvalid ?
                                    <Text fontSize='sm' color='red.500'>Email invalide</Text>
                                    : null}
                            </Box>
                            <Button w='25rem' isLoading={loading} variant='primary' mt='6' onClick={() => askForPasswordReset()}>Envoyer</Button>
                        </>
                        :
                        <Text fontSize='2xl' fontWeight='bold'>
                            Un email de réinitialisation du mot de passe a été envoyé à l'adresse : {email}
                            <br />
                            Vous allez être redirigé vers la page de connexion dans quelques secondes
                        </Text>
                    }
                </Box>
            </Box>
            {window.innerWidth > 500 ?
                <Box flex='1' display='flex' alignItems='center' justifyContent='center'>
                    <Image width='90%' height='90%' borderRadius='30px' objectFit={"cover"} src={`${image_slug}/bg_home.png`} />
                </Box>
                : null}
        </Box>
    )
}