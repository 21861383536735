import { Box, Collapse, Spacer, Text, useDisclosure } from "@chakra-ui/react";
import { PropsWithChildren } from "react";
import { ChevronUp } from "react-feather";

type CollapserProps = {
	title: string;
};

export default function Collapser(props: PropsWithChildren<CollapserProps>) {
	const { title, children } = props;
	const { isOpen, onToggle } = useDisclosure();

	return (
		<Box>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					width: "100%",
					pl: 4,
					pr: 3,
					py: 3,
					borderRadius: isOpen ? "8px 8px 0 0" : "lg",
					transition: "all .2s ease",
					bg: "gray.700",
				}}
				onClick={onToggle}
				role="button"
				tabIndex={0}
			>
				<Text sx={{ userSelect: "none" }}>{title}</Text>
				<Spacer />
				<Box
					sx={{
						color: "gray.500",
						transition: "all .2s ease",
						transform: `rotate(${isOpen ? "0deg" : "180deg"})`,
					}}
				>
					<ChevronUp size={20} />
				</Box>
			</Box>

			<Collapse in={isOpen} animateOpacity>
				<Box
					sx={{
						p: 4,
						border: "1px",
						borderColor: "gray.700",
						borderBottomRadius: "lg",
					}}
				>
					{children}
				</Box>
			</Collapse>
		</Box>
	);
}
