import { FunctionComponent, ReactElement } from "react";

export type PromiseOrVoid = Promise<void> | void;

export type BaseDialogProps = {
  title?: string | ReactElement;
  description?: string | ReactElement;
  isLoading?: boolean;
  onCancel?(): PromiseOrVoid;
  isOpen?: boolean;
};

export type DialogConfig<P extends BaseDialogProps> = {
  component: FunctionComponent<P>;
  props: P;
  isOpen: boolean;
};

export type DialogContextProps = {
  add<P extends BaseDialogProps>(
    key: string,
    component: FunctionComponent<P>,
    props: P,
  ): void;
  update(key: string, config: Partial<DialogConfig<BaseDialogProps>>): void;
  get(key: string): DialogConfig<BaseDialogProps>;
  configs: Record<string, DialogConfig<BaseDialogProps>>;
};