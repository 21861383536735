/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { login } from '../api'
import sha256 from 'crypto-js/sha256'
import UserSession from '../Classes/UserSession'
import BrandLogo from "@/components/Brand";

import {
    Box,
    Text,
    Image,
    Button,
    Input,
    InputGroup,
    InputRightElement,
    AspectRatio
} from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom';
import LogoAnimation from '@/components/LogoAnimation';



export default function Login() {
    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const [isInvalid, setIsInvalid] = useState(false)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const [show, setShow] = useState(false)
    // const image_slug = "http://localhost:3001/cdn/images"
    const image_slug = "https://lce.inno.freepro.com/api/cdn/images"


    const handleResize = () => {
        setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    const tryConnexion = async () => {
        if (!re.test(email)) {
            setIsInvalid(true)
            return
        }

        try {
            setLoading(true)
            const hashedPassword = sha256(password).toString()
            const response = await login(email.toLowerCase().trim(), hashedPassword)

            if (response.success) {
                setLoading(false)
                setIsInvalid(false)

                new UserSession(response.data.session_token, response.data.firstname, response.data.lastname, response.data.email, response.data.balance, response.data.role_name, response.data.phone, Boolean(response.data.first_login), Boolean(response.data.have_card), Boolean(response.data.have_pending_ask), response.data.avatar, response.data.have_resiliation, response.data.have_access_salle, response.data.have_access_cours).storeSession()
                window.location.href = '/'
            } else {
                setIsInvalid(true)
                setLoading(false)
            }
        } catch (e) {
            console.log(e)
            setIsInvalid(true)
            setLoading(false)
        }
    }



    useEffect(() => {
        const queryParam = new URLSearchParams(window.location.search)
        if (queryParam.get('username') && queryParam.get('password')) {
            setEmail(queryParam.get('username') as string)
            setPassword(queryParam.get('password') as string)
            if (queryParam.get('redirect') === 'true') {
                tryConnexion()
            }
        }
    }, [email, password])


    const handleEmailChange = (event: any) => {
        setEmail(event.target.value)
    }

    const handlePasswordChange = (event: any) => {
        setPassword(event.target.value)
    }

    return (
        <Box flex='1' display='flex' flexDirection='row' onKeyUp={(e) => {
            if (e.key === 'Enter') {
                tryConnexion()
            }
        }}>
            <Box flex='1' h='100dvh' display='flex' alignItems='center' justifyContent='center' backgroundColor={"#000"}>
                <Box display='flex' justifyContent='center' flexDirection='column' p={window.innerWidth > 500 ? '0' : '4'} w='25rem'>
                    {/* <BrandLogo /> */}
                    <LogoAnimation />
                    <br /><br />
                    <Text fontSize='md' color='#738296'>Connexion à votre compte d'accès au centre d'entrainement</Text>
                    <br />
                    <Text fontSize='md' color='#919CAC' className='entries'>
                        <span>Email </span>
                        <span style={{ color: 'red' }}>*</span>
                    </Text>

                    <InputGroup size='md'>
                        <Input
                            type="email"
                            defaultValue={email}
                            placeholder='Entrez votre email'
                            onChange={(e) => handleEmailChange(e)}
                        />
                    </InputGroup>
                    <Text fontSize='md' color='#919CAC' className='entries' id='password' mt='4'>
                        <span>Mot de passe </span>
                        <span style={{ color: 'red' }}>*</span>
                    </Text>
                    <InputGroup size='md'>
                        <Input
                            placeholder="Entrez votre mot de passe"
                            defaultValue={password}
                            onChange={(e) => handlePasswordChange(e)}
                            pr='4.5rem'
                            type={show ? 'text' : 'password'}
                        />
                        <InputRightElement width='fit-content' marginRight='0.5rem'>
                            <Button h='1.75rem' size='sm' onClick={() => setShow(!show)}>
                                {show ? 'Cacher' : 'Voir'}
                            </Button>
                        </InputRightElement>
                    </InputGroup>
                    <Box display='flex' mt='1'>
                        {(isInvalid &&
                            <Text fontSize='sm' color='red.500'>Email ou mot de passe incorrect</Text>
                        ) || null}
                        <Box flex='1' display='flex' justifyContent='flex-end'>
                            <Text cursor='pointer' _hover={{ textDecoration: 'underline' }} fontSize='sm' color='grey' onClick={() => navigate('/forgot-password')}>Mot de passe oublié ?</Text>
                        </Box>
                    </Box>
                    <Button isLoading={loading} variant='solid' backgroundColor='#e0e000' _hover={{ backgroundColor: "yellow.200" }} mt='6' onClick={() => tryConnexion()} >Connexion</Button>
                </Box>
            </Box>
            {windowWidth > 500 ?
                <Box flex='1' display='flex' alignItems='center' justifyContent='center' backgroundColor={"#000"}>
                    <Image width='90%' height='90%' borderRadius='30px' src={`${image_slug}/bg_home.png`} objectFit={'cover'} />
                </Box>
                : null}
        </Box>
    )
}