import {
  extendTheme, withDefaultColorScheme,
  type ThemeConfig, type ThemeOverride
} from "@chakra-ui/react";
import borderRadius from "./borderRadius";
import colors from "./colors";
import components from "./components";
import semanticTokens from "./semanticTokens";
import sizes from "./sizes";
import { spacing } from "./spacing";
import typography from "./typography";

/**
 * @note https://chakra-ui.com/docs/styled-system/theme
 */
const config: ThemeConfig = {
  initialColorMode: "dark",
  useSystemColorMode: false,
};

export const theme: ThemeOverride = extendTheme(
  /**
   * @note Couleur par défaut des composants liés au thème
   */
  withDefaultColorScheme({ colorScheme: "blue" }),
  {
    config,
    sizes,
    space: spacing,
    ...borderRadius,
    components,
    ...typography,
    colors,
    /**
     * @note Surchage de style utilisé pour les style globaux
     */
    styles: {
      global: {
        html: {
          fontSize: "14px",
          letterSpacing: "-0.14px",
          userSelect: "none"
        },
        ":not(.chakra-dont-set-collapse) > .chakra-collapse": {
          overflow: "initial !important",
        },
        body: {
          bg: {
            default: "white",
            _dark: "darkGrey.900"
          }
        }
      },
    },
    semanticTokens,
  }
);