import { useColor } from "@/theme";
import { Box, Text } from "@chakra-ui/react";

export type ConfigurationCompareProps = {
	config: string;
};

export default function ConfigurationCompare(props: ConfigurationCompareProps) {
	const { config } = props;
	const lines = config?.split("\n");
	const diffColors = {
		color: {
			add: useColor("teal.500"),
			remove: useColor("red.500"),
		},
		bg: {
			add: useColor("teal.500", 0.2),
			remove: useColor("red.500", 0.2),
		},
	};

	function getBackgroundColor(line: string) {
		if (!line) return null;

		if (line.charAt(0) === "+") {
			return diffColors.bg.add;
		} else if (line.charAt(0) === "-") {
			return diffColors.bg.remove;
		} else {
			return null;
		}
	}

	function getColor(line: string) {
		if (!line) return null;

		if (line.charAt(0) === "+") {
			return diffColors.color.add;
		} else if (line.charAt(0) === "-") {
			return diffColors.color.remove;
		} else {
			return null;
		}
	}

	return (
		<>
			{lines?.map((line, index) => (
				<Box
					key={index}
					sx={{
						px: 6,
						bg: getBackgroundColor(line),
						maxWidth: "100%",
					}}
				>
					<Text
						sx={{
							whiteSpace: "pre",
							fontFamily: "monospace",
						}}
					>
						<Text as="span" color={getColor(line)}>
							{line?.charAt(0)}
						</Text>
						{line?.substring(1, line?.length)}
					</Text>
				</Box>
			))}
		</>
	);
}
