import { transparentize } from "@/theme";
import { Option } from "@/types";
import {
	FormControl,
	FormErrorMessage,
	FormHelperText,
	FormLabel,
	SystemStyleObject,
} from "@chakra-ui/react";
import {
	ChakraStylesConfig,
	Props,
	Select as NativeSelect,
} from "chakra-react-select";
import { FocusEventHandler, ReactNode, useMemo } from "react";
import { Control, FieldPath, PathValue, useController } from "react-hook-form";

export type SelectProps<T> = {
	control: Control<T>;
	name: FieldPath<T>;
	defaultValue?: any;
	label?: string;
	helperText?: string;
	children?: ReactNode;
	onFocus?: FocusEventHandler<HTMLElement>;
	onChange?(value: Option<PathValue<T, FieldPath<T>>>): void;
	onBlur?: FocusEventHandler<HTMLElement>;
	sx?: SystemStyleObject;
} & Props;

function Select<T>(props: SelectProps<T>) {
	const {
		control,
		name,
		value,
		defaultValue,
		label,
		placeholder,
		options = [],
		helperText,
		children,
		onFocus,
		onChange,
		onBlur,
		isRequired,
		isReadOnly,
		isLoading,
		isDisabled,
		sx,
		...other
	} = props;

	const {
		field,
		fieldState: { error },
	} = useController({
		name,
		control,
		defaultValue,
		rules: {
			required: isRequired,
		},
	});

	const styles = useMemo(() => {
		const outlineColor = transparentize("blue.500", 0.16);

		return {
			placeholder: (provided) => ({
				...provided,
				color: "darkGrey.50",
			}),
			control: (provided) => ({
				...provided,
				"&[data-focus=true]": {
					borderColor: "primary!important",
					outline: "3px solid",
					outlineColor: outlineColor,
				},
			}),
			valueContainer: (provided) => ({
				...provided,
				px: 3,
			}),
			menuList: (provided) => ({
				...provided,
				p: 2,
				borderWidth: 1,
				borderColor: "lightGrey.100",
				borderRadius: "xl",
				boxShadow: "none",
				bg: "white",
				_dark: {
					borderColor: "darkGrey.600",
					bg: "darkGrey.800",
				},
			}),
			option: (provided, state) => ({
				...provided,
				borderRadius: "lg",
				color: state.isSelected ? "white" : "text",
				bg: state.isSelected ? "primary" : null,
				transition: "all .2s ease",
				"&:hover": {
					bg: state.isSelected ? "primary" : "lightGrey.100",
				},
				_dark: {
					color: "white",
					"&:hover": {
						bg: state.isSelected ? "primary" : "darkGrey.700",
					},
				},
			}),
		} as ChakraStylesConfig;
	}, []);

	return (
		<FormControl sx={sx} isRequired={isRequired} isReadOnly={isReadOnly}>
			{label && <FormLabel mb={2}>{label}</FormLabel>}

			<NativeSelect
				useBasicStyles
				styles={{
					menuPortal: (base) => ({
						...base,
						zIndex: 9999,
					}),
					menu: (base) => ({
						...base,
						zIndex: "999!important",
					}),
				}}
				chakraStyles={styles}
				menuPortalTarget={document.querySelector("body")}
				value={field.value}
				placeholder={placeholder}
				options={options}
				onChange={(opts: Option<PathValue<T, FieldPath<T>>>) => {
					field.onChange(opts);
					if (onChange) onChange(opts);
				}}
				isLoading={isLoading}
				onBlur={(e) => {
					field.onBlur();
					if (onBlur) onBlur(e);
				}}
				onFocus={onFocus}
				ref={field.ref}
				isDisabled={isDisabled || isLoading}
				{...other}
			/>

			{helperText && <FormHelperText>{helperText}</FormHelperText>}
			{error && <FormErrorMessage>{error?.message}</FormErrorMessage>}
		</FormControl>
	);
}

export default Select;
