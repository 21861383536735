import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys)

const baseStyle = definePartsStyle((props) => ({
  tab: {
    _selected: {
      color: "primary",
    },
  },
  tabpanel: {},
  tablist: {
    _dark: {
      borderColor: "darkGrey.800"
    }
  }
}));

export const Tabs = defineMultiStyleConfig({ baseStyle })